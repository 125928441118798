<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.my_applications') }}</h2>
    <div class="tabs-container">
      <Button :class="{active: tab === 'progress'}"
              :onclick="() => onFilterChanged('progress')" class="button">
        <span>{{ $t('account.applications.in_progress') }}</span>
      </Button>
      <Button :class="{active: tab === 'submitted'}"
              :onclick="() => onFilterChanged('submitted')" class="button">
        <span>{{ $t('account.applications.submitted') }}</span>
      </Button>
    </div>
    <div class="content-container">
      <template v-if="tab === 'progress'" class="progress">
        <header>
          <div class="col reference">
            <p class="title">{{ $t('account.applications.reference_number') }}</p>
          </div>
          <div class="col date">
            <p class="title">{{ $t('account.applications.last_updated_at') }}</p>
          </div>
          <div class="col status">
            <p class="title">{{ $t('account.applications.status') }}</p>
          </div>
          <div class="col action">
          </div>
        </header>
        <div v-for="application in applications" :key="application.reference" class="row">
          <div class="col reference">
            <p class="title">{{ $t('account.applications.reference_number') }}</p>
            <p class="value">{{ application.attributes.reference }}</p>
          </div>
          <div class="col date">
            <p class="title">{{ $t('account.applications.last_updated_at') }}</p>
            <p class="value">{{ application.attributes.updated_at | dateTime }}</p>
          </div>
          <div class="col status">
            <p class="title">{{ $t('account.applications.status') }}</p>
            <p class="value" style="text-transform: capitalize;">{{ application.attributes.status }}</p>
          </div>
          <div class="col action">
            <Button secondary :onclick="() => pushEdit(application.id)"><span>{{ $t('account.applications.edit') }}</span></Button>
          </div>
        </div>
      </template>
      <template v-else-if="tab === 'submitted'" class="submitted">
        <header>
          <div class="col reference">
            <p class="title">{{ $t('account.applications.reference_number') }}</p>
          </div>
          <div class="col date">
            <p class="title">{{ $t('account.applications.submitted_at') }}</p>
          </div>
          <div class="col status">
            <p class="title">{{ $t('account.applications.status') }}</p>
          </div>
          <div class="col action">
          </div>
        </header>
        <div v-for="application in applications" :key="application.reference" class="row">
          <div class="col reference">
            <p class="title">{{ $t('account.applications.reference_number') }}</p>
            <p class="value">{{ application.attributes.reference }}</p>
          </div>
          <div class="col date">
            <p class="title">{{ $t('account.applications.submitted_at') }}</p>
            <p class="value">{{ application.attributes.submitted_at | dateTime }}</p>
          </div>
          <div class="col status">
            <p class="title">{{ $t('account.applications.status') }}</p>
            <p class="value" style="text-transform: capitalize;">{{ application.attributes.status }}</p>
          </div>
          <div class="col action">
            <!-- TODO action -->
            <Button secondary :onclick="() => pushView(application.id)"><span>{{ $t('account.applications.view') }}</span></Button>
          </div>
        </div>
      </template>
      <div class="pagination-container">
        <div class="status">
          <p>{{
              $t('pagination_showing_entries', {
                from: (per_page * (page - 1)) + 1,
                to: (per_page * page),
                total: total_records
              })
            }}</p>
        </div>
        <div class="pagination">
          <Pagination :current="page" :ipp="per_page" :total="total_records" :go="onPageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';
import Pagination from '@/components/ui/Pagination.vue';
import moment from 'moment';

export default {
  name: 'MyApplications',
  components: { Pagination, Button },
  data() {
    return {
      tab: 'progress',
      applications: [],
      serverParams: {
        filters: [
          {
            filter_by: 'submitted_at',
            filter_operator: '=',
            filter_value: null
          }
        ]
      },
      total_records: 0,
      per_page: 15,
      page: 1,
    };
  },
  filters: {
    date(value) {
      return moment(value).format('DD-MM-YYYY');
    },
    dateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss A');
    }
  },
  methods: {
    pushEdit(id) {
      this.$router.push({ name: 'application', params: { application_id: id } });
    },
    pushView(id) {
      this.$router.push({ name: 'application-view', params: { application_id: id } });
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    removeParam(param) {
      this.$delete(this.serverParams, param);
    },
    onFilterChanged(tab) {
      this.tab = tab;

      const filters = [];

      if (this.tab === 'progress') {
        filters.push({
          filter_by: 'submitted_at',
          filter_operator: '=',
          filter_value: null
        });
      } else if (this.tab === 'submitted') {
        filters.push({
          filter_by: 'submitted_at',
          filter_operator: '!=',
          filter_value: null
        });
      }

      if (filters.length) {
        this.updateParams({
          filters
        });
      } else this.removeParam('filters');

      this.getApplications();
    },
    onPageChange(params) {
      this.updateParams({ page: params });
      this.getApplications();
    },
    getApplications() {
      this.is_loading = true;

      this.$axios.get(`users/${this.$store.getters.user.id}/applications`, { params: this.serverParams }).then(({ data }) => {
        this.applications = data.data;
        this.total_records = data.meta.total;
        this.page = data.meta.current_page;
        this.per_page = data.meta.per_page;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    }
  },
  mounted() {
    this.getApplications();
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.my_applications'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  .tabs-container {
    @apply mt-12 flex flex-row items-start;

    & > .button {
      @apply border-b-2 pb-2 border-transparent mr-16;

      &:last-of-type {
        @apply mr-0;
      }

      & > span {
        @apply text-base leading-normal text-grey-darker;
      }

      &:hover, &:focus {
        & > span {
          @apply text-green;
        }
      }

      &.active {
        @apply border-green;

        & > span {
          @apply text-black;
        }
      }
    }
  }

  .content-container {
    @apply pt-7 w-full;

    & > header {
      @apply hidden mb-7 px-6 mt-6;

      @screen md {
        @apply grid grid-cols-4 gap-x-8 gap-y-4;
      }

      @screen lg {
        @apply px-8;
      }

      & > .col {
        p.title {
          @apply text-base leading-normal text-green;

          @screen lg {
            @apply text-lg leading-normal;
          }
        }
      }
    }

    & > .row {
      @apply grid grid-cols-2 gap-x-8 gap-y-4 items-center shadow-lg mb-7 px-5 py-5 rounded-lg;

      @screen md {
        @apply grid-cols-4 py-8 px-6;
      }

      @screen lg {
        @apply py-10 px-8;
      }

      & > .col {
        p.title {
          @apply text-xs leading-normal text-green mb-1;

          @screen md {
            @apply hidden;
          }
        }

        p.value {
          @apply text-xs leading-normal text-black;

          @screen md {
            @apply text-base leading-normal;
          }

          @screen lg {
            @apply text-lg leading-normal;
          }
        }
      }
    }

    &.progress {
    }

    &.submitted {
    }

    & > .pagination-container {
      @apply flex flex-row items-center justify-between mt-10;

      .status {
        p {
          @apply text-xsm text-black;
        }
      }

      .pagination {

      }
    }
  }
}
</style>
