<template>
  <div class="page-container" v-if="!is_loading">
    <Header :subtitle="$t('web.pricing.subtitle')" :title="$t('web.pricing.title')"
            image="/images/pricing-illustration.svg"/>
    <section class="plans page-pad">
      <h2 class="section-title">{{ $t('web.home.plans_title') }}</h2>
      <div class="plans-container" v-if="service_packs.length && !is_loading">
        <div v-for="pack in service_packs" :key="pack.id"
             :class="{best: pack.attributes.is_featured}" class="plan-container">
          <h3>{{ pack.attributes.name }}</h3>
          <div class="features-container" v-if="pack.relationships.services.length">
            <div v-for="service in pack.relationships.services" :key="service.attributes.name"
                 :class="{disabled: service.disabled}" class="feature-container">
              <font-awesome-icon :icon="['fas','check']" class="check"/>
              <p>{{ service.attributes.name }}</p>
              <font-awesome-icon v-if="service.attributes.tooltip" v-tippy="{ placement : 'right',  arrow: true }" :content="service.attributes.tooltip" :icon="['fas','question-circle']" class="info"/>
            </div>
          </div>
          <div class="price-container">
            <p class="price">€{{ pack.attributes.price }}</p>
            <p class="excluding">{{ $t('web.home.excluding_gov_incorp_fees') }}</p>
          </div>
          <Button class="button" secondary :disabled="!$store.getters.isAuthed || is_adding_to_cart" :onclick="()=>addServicePackToCart(pack)" :class="{spinner: is_adding_to_cart}">
            <span>{{ $t('add_to_cart') }}</span>
          </Button>
        </div>
      </div>
    </section>
    <section class="services page-pad" v-if="services.length">
      <h2 class="section-title">{{ $t('web.pricing.other_services_title') }}</h2>
      <div class="services-container">
        <div v-for="service in services" :key="service.attributes.name" class="service-container">
          <div class="front">
            <font-awesome-icon :icon="['far', 'tachometer-alt-fastest']"/>
            <p class="name">{{ service.attributes.name }}</p>
            <p class="price">€{{ service.attributes.price }}</p>
          </div>
          <div class="back">
            <p class="description">{{ service.attributes.description }}</p>
            <Button class="button" outline secondary :disabled="!$store.getters.isAuthed || is_adding_to_cart" :onclick="()=>addServiceToCart(service)" :class="{spinner: is_adding_to_cart}">
              <span>{{ $t('add_to_cart') }}</span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import Button from '@/components/ui/Button.vue';
import Header from '../../components/web/Header.vue';

export default {
  name: 'Pricing',
  components: { Button, Header },
  data() {
    return {
      service_packs: [],
      services: [],
      is_loading: false,
      is_adding_to_cart: false
    };
  },
  methods: {
    getServicePacks() {
      this.is_loading = true;

      this.$axios.get('servicePacks/list').then(({ data }) => {
        this.service_packs = data.data;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
    getServices() {
      this.$axios.get('services/list').then(({ data }) => {
        this.services = data.data;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
    async addServicePackToCart(pack) {
      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }

      this.is_adding_to_cart = true;

      await this.$axios.post(`carts/${this.$store.getters.cart}/servicePacks/${pack.id}/attach`, { quantity: 1 }).then(async ({ data }) => {
        if (data.data.type === 'servicePack') {
          await this.$axios.delete(`carts/${this.$store.getters.cart}/servicePacks/${data.data.id}/detach`, { quantity: 1 }).then(({ response }) => {
            this.addServicePackToCart(pack);
            this.is_adding_to_cart = false;
          }).catch(async (e) => {
            this.is_adding_to_cart = false;
          });
        }

        this.is_adding_to_cart = false;
      }).catch(async (e) => {
        this.is_adding_to_cart = false;
      });
    },
    async addServiceToCart(service) {
      this.is_adding_to_cart = true;

      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }

      this.$axios.post(`carts/${this.$store.getters.cart}/services/${service.id}/attach`, { quantity: 1 }).then(({ data }) => {
        this.is_adding_to_cart = false;
      }).catch((e) => {
        this.is_adding_to_cart = false;
      });
    }
  },
  mounted() {
    this.getServicePacks();
    this.getServices();
  },
  metaInfo() {
    return {
      title: this.$t('nav.pricing'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  section.plans {
    @apply flex flex-col items-center bg-white py-16;

    @screen lg {
      @apply py-20;
    }

    .plans-container {
      @apply flex flex-col;

      @screen lg {
        @apply flex-row flex-wrap justify-center -mx-6
      }

      @screen 2xl {
        @apply -mx-8;
      }

      .plan-container {
        @apply mb-12 p-10 rounded border-2 border-pink;
        height: min-content;

        @screen lg {
          @apply mb-14 mx-6;

          width: calc(theme('width.1/2') - theme('spacing.12'));
        }

        @screen 2xl {
          @apply p-11 mx-8;

          width: calc(theme('width.1/3') - theme('spacing.16'));
        }

        &.best {
          @apply border-green;
        }

        h3 {
          @apply text-3xl leading-normal text-black font-bold mb-7;

          @screen 2xl {
            @apply text-4xl leading-normal mb-8;
          }
        }

        .features-container {
          @apply flex flex-col items-start;

          .feature-container {
            @apply flex flex-row items-center mb-4;

            svg.check {
              @apply text-green text-sm mr-4;
            }

            p {
              @apply text-black text-xs leading-normal;

              @screen md {
                @apply text-sml leading-normal;
              }
            }

            svg.info {
              @apply text-black text-sm ml-6;
            }

            &.disabled {
              svg.check, svg.info, p {
                @apply text-grey;
              }
            }
          }
        }

        .price-container {
          @apply flex flex-row items-baseline mb-6;

          p.price {
            @apply text-3xl text-black font-bold;
          }

          p.excluding {
            @apply text-xxs text-black font-normal italic ml-2;
          }
        }

        & > .button {
          @apply w-full;
        }
      }
    }
  }

  section.services {
    @apply flex flex-col items-center bg-grey-lightest py-16;

    @screen lg {
      @apply py-20;
    }

    .services-container {
      @apply w-full grid grid-cols-1 gap-y-12 justify-items-center;

      @screen md {
        @apply grid-cols-2 gap-11;
      }

      @screen lg {
        @apply grid-cols-3;
      }

      @screen 2xl {
        @apply gap-18;
      }

      .service-container {
        @apply border border-black rounded h-52 w-full max-w-xs;

        @screen md {
          @apply max-w-sm;
        }

        @screen 2xl {
          @apply max-w-none h-80;
        }

        .front {
          @apply flex flex-col items-center justify-center h-full;

          svg {
            @apply text-green text-5xl mb-4;

            @screen 2xl {
              @apply text-7xl mb-5;
            }
          }

          p.name {
            @apply text-black font-medium text-sm mb-4 text-center;

            @screen 2xl {
              @apply text-xl mb-7;
            }
          }

          p.price {
            @apply text-black font-bold text-sm text-center;

            @screen 2xl {
              @apply text-xl;
            }
          }
        }

        .back {
          @apply hidden flex-col items-start justify-between h-full px-5 py-6 bg-green;

          @screen 2xl {
            @apply px-8 py-9;
          }

          p.description {
            @apply text-white text-xs leading-normal;

            @screen 2xl {
              @apply text-sml leading-normal;
            }
          }
        }

        &:hover, &:focus {
          @apply border-green;

          .front {
            @apply hidden;
          }

          .back {
            @apply flex;
          }
        }
      }
    }
  }
}
</style>
