<template>
  <div class="navbar-wrapper">
    <nav class="navbar-container page-pad">
      <router-link :to="{name: 'home'}" class="logo-container">
        <img alt="logo" src="/images/legalwiz_3_white.png"/>
      </router-link>
      <section class="nav">
        <template v-if="this.$route.name === 'sign-up'">
          <p>{{ $t('auth.have_an_account') }}</p>
          <Button :to="{name: 'login'}" class="button">
            <span>{{ $t('nav.login') }}</span>
          </Button>
        </template>
        <template v-else>
          <p>{{ $t('auth.dont_have_an_account') }}</p>
          <Button :to="{name: 'sign-up-invite'}" class="button">
            <span>{{ $t('nav.sign_up') }}</span>
          </Button>
        </template>
      </section>
    </nav>
  </div>
</template>

<script>
import Button from '../ui/Button.vue';

export default {
  name: 'Navbar',
  components: { Button },
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  .navbar-container {
    @apply w-full flex flex-row items-center bg-green py-4;

    @screen md {
      @apply py-5;
    }

    a.logo-container {
      img {
        @apply h-6 w-auto;

        @screen md {
          @apply h-8;
        }

        @screen 2xl {
          @apply h-10;
        }
      }
    }

    section.nav {
      @apply flex flex-row items-center ml-auto;

      p {
        @apply text-white text-xs leading-normal mr-3 truncate ml-4;

        @screen md {
          @apply text-sml leading-normal mr-4;
        }
      }

      .button {
        & > span {
          @apply text-pink text-xs leading-normal;

          @screen lg {
            @apply text-sml leading-normal;
          }
        }

        &:hover, &:focus {
          & > span {
            @apply text-black;
          }
        }
      }
    }
  }
}
</style>
