<template>
  <div class="page-container">
    <section class="terms-conditions page-pad">
      <header>
        <h1 class="page-title">{{ $t('nav.terms_conditions') }}</h1>
        <p class="effective">Effective on January 5th 2021</p>
      </header>
      <article>
        <h2>Terms</h2>
        <p>By accessing the website at https://www.arkafort. com, you are agreeing to be bound by
          these terms of service, all applicable laws and regulations, and agree that you are
          responsible for compliance with any applicable local laws. If you do not agree with any
          of these terms, you are prohibited from using or accessing this site. The materials
          contained in this website are protected by applicable copyright and trademark law.</p>
        <h2>Use License</h2>
        <p>Permission is granted to temporarily download one copy of the materials
          (information or software) on Arkafort Ltd.'s website for personal, non- commercial
          transitory viewing only. This is the grant of a license, not a transfer of title, and
          under this license you may not: modify or copy the materials; use the materials for any
          commercial purpose, or for any public display (commercial or non- commercial); attempt
          to decompile or reverse engineer any software contained on Arkafort Ltd.'s website;
          remove any copyright or other proprietary notations from the materials; or transfer the
          materials to another person or "mirror" the materials on any other server.</p>
        <p>This license shall automatically terminate if you violate any of these restrictions
          and may be terminated by Arkafort Ltd. at any time. Upon terminating your viewing of
          these materials or upon the termination of this license, you must destroy any downloaded
          materials in your possession whether in electronic or printed format.</p>
        <h2>Disclaimer</h2>
        <p>The materials on Arkafort Ltd.'s website are provided on an 'as is' basis. Arkafort Ltd.
          makes no warranties, expressed or implied, and hereby disclaims and negates all other
          warranties including, without limitation, implied warranties or conditions of
          merchantability, fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights.</p>
        <p>Further, Arkafort Ltd. does not warrant or make any representations concerning the
          accuracy, likely results, or reliability of the use of the materials on its website or
          otherwise relating to such materials or on any sites linked to this site.</p>
        <h2>Limitations</h2>
        <p>In no event shall Arkafort Ltd. or its suppliers be liable for any damages (including,
          without limitation, damages for loss of data or profit, or due to business interruption)
          arising out of the use or inability to use the materials on Arkafort Ltd.'s website,
          even if Arkafort Ltd. or a Arkafort Ltd. authorized representative has been notified
          orally or in writing of the possibility of such damage. Because some jurisdictions do
          not allow limitations on implied warranties, or limitations of liability for
          consequential or incidental damages, these limitations may not apply to you. 5.
          Accuracy of materials.</p>
        <p>The materials appearing on Arkafort Ltd.'s website could include technical,
          typographical, or photographic errors. Arkafort Ltd. does not warrant that any of the
          materials on its website are accurate, complete or current. Arkafort Ltd. may make
          changes to the materials contained on its website at any time without notice.
          However Arkafort Ltd. does not make any commitment to update the materials.</p>
        <h2>Accuracy of materials</h2>
        <p>The materials appearing on Arkafort Ltd.'s website could include technical,
          typographical, or photographic errors. Arkafort Ltd. does not warrant that any of the
          materials on its website are accurate, complete or current. Arkafort Ltd. may make
          changes to the materials contained on its website at any time without notice.
          However Arkafort Ltd. does not make any commitment to update the materials.</p>
        <h2>Links</h2>
        <p>Arkafort Ltd. has not reviewed all of the sites linked to its website and is not
          responsible for the contents of any such linked site. The inclusion of any link does
          not imply endorsement by Arkafort Ltd. of the site. Use of any such linked website is
          at the user's own risk.</p>
        <h2>Modifications</h2>
        <p>Arkafort Ltd. may revise these terms of service for its website at any time without
          notice. By using this website you are agreeing to be bound by the then current version
          of these terms of service.</p>
        <h2>Governing Law</h2>
        <p>These terms and conditions are governed by and construed in accordance with the laws
          of Malta and you irrevocably submit to the exclusive jurisdiction of the courts in
          that State or location.</p>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
  metaInfo() {
    return {
      title: this.$t('nav.terms_conditions'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col items-start bg-white py-12;

  @screen 2xl {
    @apply py-16;
  }

  section.terms-conditions {
    header {
      @apply mb-12;

      @screen md {
        @apply mb-16;
      }

      h1.page-title {
        @apply text-black text-3xl font-bold;

        @screen lg {
          @apply text-4xl;
        }

        @screen 2xl {
          @apply text-5xl;
        }
      }

      p.effective {
        @apply mt-5 text-grey-darker text-sml font-normal;
      }
    }

    article {
      h2 {
        @apply text-black text-xl font-bold mb-4;
      }

      p {
        @apply text-grey-darker text-sml leading-loose font-normal mb-4;
      }

      p + h2 {
        @apply mt-10;
      }
    }
  }
}
</style>
