<template>
  <div class="inner-page-container">
    <header>
      <h2 class="page-title">{{ $t('account.nav.payment_methods') }}</h2>
      <div v-if="payment_methods.length > 0" class="actions-container">
        <Button :to="{ name: 'add_payment_method' }" class="button" secondary>
          <span>{{ $t('add') }}</span>
        </Button>
      </div>
    </header>
    <div v-if="payment_methods.length === 0" class="no-payment-methods-container">
      <p class="message">{{ $t('account.payment_methods.no_payment_methods_added') }}</p>
      <Button :to="{ name: 'add_payment_method' }" class="button" secondary>
        <span>{{ $t('add') }}</span>
      </Button>
    </div>
    <div v-else class="content-container">
      <div v-for="payment_method in payment_methods" :key="payment_method.card"
           class="payment-method-container">
        <header>
          <p class="note">{{ payment_method.note }}</p>
          <span class="delete" @click="toggleDelete">{{ $t('delete') }}</span>
        </header>
        <div class="details-area-container">
          <div class="icon-container">
            <font-awesome-icon :icon="['fab', 'cc-visa']"/>
          </div>
          <div class="details-container">
            <p>{{ payment_method.card }}</p>
            <p>Expires: {{ payment_method.expires }}</p>
            <p>{{ payment_method.name }}</p>
          </div>
        </div>
        <footer>
          <span class="delete" @click="toggleDelete">{{ $t('delete') }}</span>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  name: 'PaymentMethods',
  components: { Button },
  data() {
    return {
      payment_methods: [ // TODO dev data
        {
          note: 'This is your default payment method',
          card: 'Debit Mastercard (4467)',
          expires: '09/22',
          name: 'John Doe',
        },
        {
          note: 'Second Payment Method',
          card: 'Debit Visa (0015)',
          expires: '10/25',
          name: 'John Doe',
        },
      ],
      total_records: 150,
      per_page: 5,
      page: 1,
    };
  },
  methods: {
    toggleDelete() {
      // TODO add id reference

      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('account.payment_methods.delete_payment_method'),
          message: this.$t('account.payment_methods.prompt_delete'),
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'closable-modal loose',
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              // TODO
            }
          },
        },
      );
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.payment_methods'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  & > header {
    @apply flex flex-row flex-wrap items-center w-full;

    h2.page-title {
      @apply flex-none;
    }

    .actions-container {
      @apply flex flex-row items-center w-full mt-8;

      @screen md {
        @apply ml-auto w-auto flex-none mt-0;
      }

      & > .button {
        @apply px-8;

        @screen md {
          @apply ml-4;
        }

        @screen lg {
          @apply ml-5;
        }
      }
    }
  }

  .no-payment-methods-container {
    @apply flex flex-col items-start mt-9;

    p.message {
      @apply text-lg leading-normal font-bold text-black mb-8;
    }
  }

  .content-container {
    @apply pt-7 w-full;

    .payment-method-container {
      @apply pb-8 mb-8 border-b border-grey;

      &:last-of-type {
        @apply mb-0 border-b-0;
      }

      span.delete {
        @apply text-grey-dark font-normal text-sml leading-normal underline select-none
        cursor-pointer;
      }

      & > header {
        @apply flex flex-row items-center justify-between pb-5;

        p.note {
          @apply text-black font-bold text-sml leading-normal;
        }

        span.delete {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }
      }

      & > .details-area-container {
        @apply flex flex-row items-start;

        .icon-container {
          @apply flex-none mr-3;

          svg {
            @apply text-grey-darker text-3xl;
          }
        }

        .details-container {
          @apply flex flex-col;

          p {
            @apply text-black font-normal text-sml leading-normal;
          }
        }
      }

      & > footer {
        @apply block mt-6;

        @screen md {
          @apply hidden;
        }
      }
    }
  }
}
</style>
