<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_5.title') }}</p>
      <Form class="form-container" @submit="submit">
        <FormInputText v-model="numShareHolders" :has-error="$v.numShareHolders.$error"
                       :label="$t('application.step_5.number_of_share_holders')" :placeholder="$t('value')" class="form-input"
                       identifier="num_share_holders" @blur="saveData" type="number" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.numShareHolders.required">
              {{ $t('application.step_5.validation.num_share_holders_required') }}
            </p>
            <p v-else-if="!$v.numShareHolders.numeric">
              {{ $t('application.step_5.validation.num_share_holders_numeric') }}
            </p>
            <p v-else-if="!$v.numShareHolders.minValue">
              {{ $t('application.step_5.validation.num_share_holders_numeric') }}
            </p>
            <p v-else-if="!$v.numShareHolders.maxValue">
              {{ $t('application.step_5.validation.num_share_holders_numeric') }}
            </p>
          </template>
        </FormInputText>
      </Form>
    </div>
    <StepInfo class="step-info-container">
      <p>{{ $t('application.step_5.info_1') }}</p>
      <p>{{ $t('application.step_5.info_2') }}</p>
      <p>{{ $t('application.step_5.info_3') }}</p>
      <p>{{ $t('application.step_5.info_4') }}</p>
      <p>{{ $t('application.step_5.info_5') }}</p>
    </StepInfo>
  </div>
</template>

<script>
import StepInfo from '@/components/application/StepInfo.vue';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import {
  maxValue, minValue, numeric, required,
} from 'vuelidate/lib/validators';

export default {
  name: 'ApplicationStep5Part1',
  components: { FormInputText, Form, StepInfo },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      numShareHolders: null,
    };
  },
  validations: {
    numShareHolders: {
      required, numeric, minValue: minValue(1), maxValue: maxValue(50),
    },
  },
  methods: {
    submit() {
      return false;
    },

    populate() {
      if (this.currentData && this.currentData.numShareHolders) {
        this.numShareHolders = this.currentData.numShareHolders;
      }
    },

    saveData() {
      const currentData = {
        numShareHolders: this.numShareHolders,
      };

      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },

    getData() {
      return {
        numShareHolders: this.numShareHolders,
      };
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
  },
  async mounted() {
    await this.populate();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply flex flex-col items-start w-full max-w-xl;

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      @apply w-full;

      & > .form-input {
        @apply max-w-xs;
      }
    }
  }

  .step-info-container {
    @apply w-full mt-9 max-w-lg;

    @screen md {
      @apply mt-16;
    }
  }
}
</style>
