<template>
  <div class="step-info-container">
    <div class="icon-container">
      <font-awesome-icon :icon="['far', 'lightbulb-on']"/>
    </div>
    <main>
      <slot/>
    </main>
  </div>
</template>

<script>
export default {
  name: 'StepInfo',
};
</script>

<style lang="scss" scoped>
.step-info-container {
  @apply bg-white rounded-lg shadow-md;

  & > .icon-container {
    @apply bg-white w-20 h-20 flex flex-row items-center justify-center mx-auto;

    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom: 0;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    svg {
      @apply text-green text-3xl;
    }
  }

  & > main {
    @apply pb-12 pt-4 px-8;

    @screen md {
      @apply px-9;
    }

    p {
      @apply text-grey-darker text-sml leading-relaxed;
    }
  }
}
</style>
