<template>
  <router-link v-if="to" :class="classString" :disabled="disabled" :to="to" @click.native="onclick">
    <slot></slot>
  </router-link>
  <button v-else :class="classString" :disabled="disabled" :type="type" @click="onclick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    disabled: {
      type: [Boolean, Object],
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    onclick: {
      type: Function,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      default: null,
    },
  },

  computed: {
    classString() {
      const classes = ['button'];

      if (this.primary) classes.push('--primary');
      else if (this.secondary) classes.push('--secondary');
      else if (this.grey) classes.push('--grey');
      else if (this.negative) classes.push('--negative');

      if (this.outline) classes.push('--outline');

      if (this.loading) classes.push('spinner');

      return classes;
    },
  },
};
</script>

<style lang="scss">
.button {
  @apply flex flex-row justify-center;

  & > span {
    @apply text-sml font-medium text-black;
  }

  &:hover, &:focus {
    & > span {
      @apply text-pink;
    }
  }

  & > svg {
    @apply text-xs;

    &:first-child {
      @apply mr-1;
    }

    &:last-child {
      @apply ml-1;
    }
  }

  &:disabled {
    @apply cursor-not-allowed;
  }

  &.--primary, &.--secondary, &.--grey, &.--negative, &.--outline {
    @apply py-2 px-4 rounded-md border-2;

    & > span {
      @apply font-bold text-xs;
    }
  }

  &.--primary {
    @apply bg-green border-green;

    & > span {
      @apply text-black;
    }

    &:hover, &:focus {
      @apply bg-green border-green;

      & > span {
        @apply text-black;
      }
    }

    &:disabled {
      @apply bg-green border-green;

      & > span {
        @apply text-black;
      }
    }
  }

  &.--secondary {
    @apply bg-pink border-pink;

    & > span {
      @apply text-black;
    }

    &:hover, &:focus {
      @apply bg-pink-over border-pink;

      & > span {
        @apply text-black;
      }
    }

    &:disabled {
      @apply bg-pink border-pink;

      & > span {
        @apply text-black;
      }
    }
  }

  &.--grey {
    @apply bg-grey-light border-grey-light;

    & > span {
      @apply text-black;
    }

    &:hover, &:focus {
      @apply bg-grey border-grey;

      & > span {
        @apply text-black;
      }
    }

    &:disabled {
      @apply bg-grey-light border-grey-light;

      & > span {
        @apply text-black;
      }
    }
  }

  &.--negative {
    @apply bg-negative border-negative;

    & > span {
      @apply text-black;
    }

    &:hover, &:focus {
      @apply bg-negative border-negative;

      & > span {
        @apply text-black;
      }
    }

    &:disabled {
      @apply bg-negative border-negative;

      & > span {
        @apply text-black;
      }
    }
  }

  &.--outline {
    @apply bg-transparent;

    & > span {
      @apply text-black;
    }

    &:disabled {
      @apply bg-transparent;
    }

    &.--primary {
      @apply border-green;

      &:hover, &:focus {
        @apply bg-green border-green;

        & > span {
          @apply text-black;
        }
      }
    }

    &.--secondary {
      @apply border-pink;

      & > span {
        @apply text-black;
      }

      &:hover, &:focus {
        @apply bg-pink border-pink;

        & > span {
          @apply text-black;
        }
      }
    }

    &.--grey {
      @apply border-grey;

      & > span {
        @apply text-black;
      }

      &:hover, &:focus {
        @apply bg-grey-light border-grey-light;

        & > span {
          @apply text-black;
        }
      }
    }

    &.--negative {
      @apply border-negative;

      &:hover, &:focus {
        @apply bg-negative border-negative;

        & > span {
          @apply text-black;
        }
      }
    }

    &:disabled {
      @apply bg-transparent;
    }
  }

  &:active, &:focus {
    @apply outline-none;
  }
}
</style>
