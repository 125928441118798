<template>
  <div class="layout-container application">
    <Navbar inner/>
    <main>
      <slot/>
    </main>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue';

export default {
  name: 'Layout',
  components: { Navbar },

};
</script>

<style lang="scss">
.layout-container.application {
  .page-pad {
    @apply px-4;

    @screen md {
      @apply px-5;
    }

    @screen lg {
      @apply px-12;
    }

    @screen 2xl {
      @apply px-64;
    }
  }

  .page-container {
    @apply flex flex-col;
  }
}
</style>
