<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_8.title') }}</p>
      <Form class="form-container" @submit="submit">
        <section>
          <div class="input name">
            <FormInputText v-model="$v.secretary.$model.name" :has-error="$v.secretary.name.$error"
                           :label="`${$t('application.step_5.full_name')}`"
                           :placeholder="`${$t('application.step_5.full_name')}`"
                           identifier="name" @blur="saveData" :disabled="$route.path.includes('view')"
                           type="text">
              <template v-slot:errors>
                <p v-if="!$v.secretary.name.required">
                  {{ $t('application.step_5.validation.full_name_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input nationality">
            <FormInputText
              v-model="$v.secretary.$model.nationality"
              :has-error="$v.secretary.nationality.$error"
              :label="$t('application.step_5.nationality')"
              :placeholder="$t('application.step_5.nationality')" @blur="saveData" :disabled="$route.path.includes('view')"
            >
              <template v-slot:errors>
                <p v-if="!$v.secretary.nationality.required">
                  {{ $t('application.step_5.validation.nationality_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input dob">
            <FormInputText v-model="$v.secretary.$model.dob" :has-error="$v.secretary.dob.$error"
                           :label="`${$t('application.step_5.date_of_birth')}`"
                           :placeholder="`${$t('application.step_5.date_of_birth')}`" @blur="saveData"
                           identifier="dob"
                           type="date" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.secretary.dob.required">
                  {{ $t('application.step_5.validation.date_of_birth_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input email">
            <FormInputText v-model="$v.secretary.$model.email" :has-error="$v.secretary.email.$error"
                           :label="`${$t('application.step_5.email')}`"
                           :placeholder="`${$t('application.step_5.email')}`" @blur="saveData"
                           identifier="email"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.secretary.email.required">
                  {{ $t('application.step_5.validation.email_required') }}
                </p>
                <p v-else-if="!$v.secretary.email.email">
                  {{ $t('application.step_5.validation.email_invalid') }}
                </p>
              </template>
            </FormInputText>
          </div>
        </section>
        <section>
          <div class="input residence">
            <FormInputText v-model="$v.secretary.$model.address.residence"
                           :has-error="$v.secretary.address.residence.$error"
                           :label="$t('application.step_5.building_number_name')"
                           :placeholder="$t('application.step_5.building_number_name')"
                           identifier="residence" @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.secretary.address.residence.required">
                  {{ $t('application.step_5.validation.building_number_name_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input street">
            <FormInputText v-model="$v.secretary.$model.address.street" :has-error="$v.secretary.address.street.$error"
                           :label="`${$t('application.step_5.street_name')}`"
                           :placeholder="`${$t('application.step_5.street_name')}`"
                           identifier="street"  @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.secretary.address.street.required">
                  {{ $t('application.step_5.validation.street_name_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input locality">
            <FormInputText
              v-model="$v.secretary.$model.address.locality"
              :has-error="$v.secretary.address.locality.$error"
              :label="$t('application.step_5.locality_city')"
              :placeholder="$t('application.step_5.locality_city')" @blur="saveData" :disabled="$route.path.includes('view')"
            >
              <template v-slot:errors>
                <p v-if="!$v.secretary.address.locality.required">
                  {{ $t('application.step_5.validation.locality_city_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input post_code">
            <FormInputText v-model="$v.secretary.$model.address.post_code"
                           :has-error="$v.secretary.address.post_code.$error"
                           :label="$t('application.step_5.post_code')"
                           :placeholder="$t('application.step_5.post_code')"
                           identifier="post_code" @blur="saveData" :disabled="$route.path.includes('view')"
                           type="text">
              <template v-slot:errors>
                <p v-if="!$v.secretary.address.post_code.required">
                  {{ $t('application.step_5.validation.post_code_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input country">
            <FormInputText
              v-model="$v.secretary.$model.address.country"
              :allow-empty="false"
              :has-error="$v.secretary.address.country.$error"
              :label="$t('application.step_5.country')"
              :placeholder="$t('application.step_5.country')" @blur="saveData" :disabled="$route.path.includes('view')"
            >
              <template v-slot:errors>
                <p v-if="!$v.secretary.address.country.required">
                  {{ $t('application.step_5.validation.country_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
        </section>
        <div class="checkbox-wrapper">
          <div class="checkbox-container">
            <p-check class="checkbox p-icon p-curve" v-model="$v.agree_check.$model" color="primary"><font-awesome-icon slot="extra" :icon="['far', 'check']" class="icon" :disabled="$route.path.includes('view')"/></p-check>
            <p>{{ $t('auth.agree_to') }} submission</p>
          </div>
          <p class="checkbox-error" v-if="$v.agree_check.$error">Check to continue</p>
        </div>
      </Form>
    </div>
    <StepInfo class="step-info-container">
      <p>{{ $t('application.step_8.info_1') }}</p>
      <p>{{ $t('application.step_8.info_2') }}</p>
      <p>{{ $t('application.step_8.info_3') }}</p>
    </StepInfo>
  </div>
</template>

<script>
import StepInfo from '@/components/application/StepInfo.vue';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { email, required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'ApplicationStep7',
  components: {
    FormInputText,
    Form,
    StepInfo,
  },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      secretary: {
        name: null,
        nationality: null,
        dob: null,
        email: null,
        address: {
          residence: null,
          street: null,
          locality: null,
          post_code: null,
          country: null,
        },
      },
      agree_check: false
    };
  },
  validations: {
    secretary: {
      name: {
        required,
      },
      nationality: {
        required,
      },
      dob: {
        required,
      },
      email: {
        required,
        email,
      },
      address: {
        residence: {
          required,
        },
        street: {
          required,
        },
        locality: {
          required,
        },
        post_code: {
          required,
        },
        country: {
          required,
        },
      },
    },
    agree_check: {
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    submit() {
      // TODO
    },
    populate() {
      if (this.currentData && this.currentData.secretary) {
        this.secretary.name = this.currentData.secretary.name;
        this.secretary.nationality = this.currentData.secretary.nationality;
        this.secretary.dob = this.currentData.secretary.dob;
        this.secretary.email = this.currentData.secretary.email;
        this.secretary.address.residence = this.currentData.secretary.address.residence;
        this.secretary.address.street = this.currentData.secretary.address.street;
        this.secretary.address.locality = this.currentData.secretary.address.locality;
        this.secretary.address.post_code = this.currentData.secretary.address.post_code;
        this.secretary.address.country = this.currentData.secretary.address.country;
      }
    },
    saveData() {
      const tempData = {
        secretary: this.secretary,
      };

      const currentData = tempData;
      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },

    getData() {
      return {
        secretary: this.secretary,
      };
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
  },
  async mounted() {
    await this.populate();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply flex flex-col items-start w-full max-w-3xl mx-auto;

    @screen xl {
      @apply max-w-xl;
    }

    @screen 2xl {
      @apply max-w-3xl;
    }

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      @apply w-full grid grid-cols-1;

      @screen lg {
        @apply grid-cols-2 gap-x-18;
      }

      @screen xl {
        @apply gap-x-8;
      }

      @screen 2xl {
        @apply gap-x-18;
      }

      & > section {
        @apply w-full grid grid-cols-2 gap-x-8;

        @screen md {
          @apply gap-x-10;
        }

        @screen xl {
          @apply gap-x-4;
        }

        @screen 2xl {
          @apply gap-x-10;
        }

        & > .input {
          &.name, &.email, &.residence, &.street {
            @apply col-span-2;
          }
        }
      }
    }

    .checkbox-wrapper {
      @apply flex flex-col;

      .checkbox-container {
        @apply flex flex-row items-center -mb-4;

        p {
          @apply text-black text-sml leading-normal ml-3;
        }
      }

      .checkbox-error {
        @apply text-xs text-negative mt-4;
      }
    }
  }

  .step-info-container {
    @apply w-full mt-9 max-w-sm mx-auto;

    @screen md {
      @apply mt-16;
    }

    @screen 2xl {
      @apply max-w-md;
    }
  }
}
</style>
