<template>
  <footer class="footer-container page-pad">
    <section class="legal">
      <router-link :to="{name: 'home'}" class="logo-container">
        <img alt="logo" class="logo" src="/images/legalwiz_4_darkbg.png">
      </router-link>
      <p v-html="$t('copyright', {date: new Date().getFullYear()})"></p>
    </section>
    <section class="nav">
      <Button :to="{name: 'how-it-works'}" class="nav-element">
        <span>{{ $t('nav.how_it_works') }}</span>
      </Button>
      <Button :to="{name: 'pricing'}" class="nav-element">
        <span>{{ $t('nav.pricing') }}</span>
      </Button>
      <Button :to="{name: 'terms-conditions'}" class="nav-element">
        <span>{{ $t('nav.terms_conditions') }}</span>
      </Button>
      <Button :to="{name: 'cookies-policy'}" class="nav-element">
        <span>{{ $t('nav.cookies_policy') }}</span>
      </Button>
    </section>
    <section class="arkafort">
      <p>{{ $t('engineered_by') }}</p>
      <img alt="logo" class="logo" src="/images/arkafort-logo.svg">
    </section>
  </footer>
</template>

<script>
import Button from './ui/Button.vue';

export default {
  name: 'Footer',
  components: { Button },
};
</script>

<style lang="scss" scoped>
.footer-container {
  @apply bg-black py-5 flex flex-col;

  @screen md {
    @apply flex-row items-center justify-between py-6;
  }

  @screen 2xl {
    @apply py-8;
  }

  section.legal {
    @apply flex flex-row items-center justify-between mb-10;

    @screen md {
      @apply flex-col mb-0 flex-none;
    }

    a.logo-container {
      img.logo {
        @apply h-4 w-auto;

        @screen md {
          @apply h-5 mb-5;
        }
      }
    }

    p {
      @apply text-pink text-xs;
    }
  }

  section.nav {
    @apply grid w-max self-center;

    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: theme('spacing.5');
    grid-column-gap: theme('spacing.8');

    @screen md {
      @apply flex flex-row justify-center items-center flex-1;

      grid-row-gap: unset;
      grid-column-gap: unset;
    }

    .nav-element {
      & > span {
        @apply text-pink text-xs;

        @screen 2xl {
          @apply text-sml;
        }
      }

      @screen md {
        @apply mr-6;

        &:last-of-type {
          @apply mr-0;
        }
      }

      @screen lg {
        @apply mr-10;
      }

      @screen 2xl {
        @apply text-sml mr-16;
      }
    }
  }

  section.arkafort {
    @apply flex flex-row items-center justify-center mt-10;

    @screen md {
      @apply flex-col mt-0;

      flex: 0 0 12.5rem;
    }

    @screen lg {
      @apply flex-row;
    }

    p {
      @apply text-pink text-xs mr-2;

      @screen md {
        @apply mr-0 mb-4;
      }

      @screen lg {
        @apply mb-0 mr-2;
      }
    }

    img.logo {
      @apply h-3 w-auto;

      @screen md {
        @apply h-4;
      }
    }
  }
}
</style>
