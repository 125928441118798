<template>
  <form
    class="form-container"
    @submit.prevent="$emit('submit')"
  >
    <slot/>
  </form>
</template>

<script>
export default {
  name: 'Form',
};
</script>
