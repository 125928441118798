<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_1.title') }}</p>
      <Form class="form-container" @submit="submit">
        <div v-for="(v, i) in $v.names.$each.$iter" :key="v.key"
             class="name-row-container">
          <FormInputText v-model="v.name.$model" :has-error="v.name.$error" :identifier="`name-${i}`"
                         :label="`${$t('application.step_1.preference_x', {x: +i + 1})}`"
                         :placeholder="$t('application.step_1.enter_company_name')"
                         class="name" @blur="saveData"
                         type="text" :disabled="$route.path.includes('view')">
            <template v-slot:errors>
              <p v-if="!v.name.required">
                {{ $t('application.step_1.validation.name_required') }}
              </p>
            </template>
          </FormInputText>
          <FormInputSelect
            v-model="v.ltd.$model" :allow-empty="false" :has-error="v.ltd.$error"
            :label="$t('application.step_1.limited_or_ltd')" :options="ltdOptions"
            class="ltd" display-label="text" track-by="value" @input="saveData"
            :disabled="$route.path.includes('view')"
          >
            <template v-slot:errors>
              <p v-if="!v.ltd.required">
                {{ $t('application.step_1.validation.ltd_required') }}
              </p>
            </template>
          </FormInputSelect>
        </div>
      </Form>
    </div>
    <StepInfo class="step-info-container">
      <p>{{ $t('application.step_1.info_1') }}</p>
      <p>{{ $t('application.step_1.info_2') }}</p>
      <p>{{ $t('application.step_1.info_3') }}</p>
      <p>{{ $t('application.step_1.info_4') }}</p>
      <p>{{ $t('application.step_1.info_5') }}</p>
      <p>{{ $t('application.step_1.info_6') }}</p>
    </StepInfo>
  </div>
</template>

<script>
import StepInfo from '@/components/application/StepInfo.vue';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { required } from 'vuelidate/lib/validators';
import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import _ from 'lodash';

export default {
  name: 'ApplicationStep1',
  components: {
    FormInputSelect, FormInputText, Form, StepInfo,
  },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    const ltdOptions = [
      { text: 'Ltd.', value: 'ltd' },
      { text: 'Limited', value: 'limited' },
    ];

    return {
      names: [
        { key: 1, name: null, ltd: ltdOptions[0] },
        { key: 2, name: null, ltd: ltdOptions[0] },
        { key: 3, name: null, ltd: ltdOptions[0] },
      ],
      ltdOptions,
    };
  },
  validations: {
    names: {
      $each: {
        name: {
          required,
        },
        ltd: {
          required,
        },
      },
    },
  },
  methods: {
    submit() {
      // TODO
    },

    getData() {
      return {
        names: this.names.map((n) => ({ name: n.name, ltd: n.ltd.value })),
      };
    },

    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },

    populate() {
      if (this.currentData && this.currentData.names) {
        for (let i = 0; i < this.currentData.names.length; i++) {
          this.names[i].name = this.currentData.names[i].name;
          this.names[i].ltd = _.find(this.ltdOptions, { value: this.currentData.names[i].ltd });
        }
      }
    },

    saveData() {
      const currentData = { names: this.names.map((n) => ({ name: n.name, ltd: n.ltd.value })) };
      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },
  },

  async mounted() {
    await this.populate();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply w-full max-w-xl;

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      .name-row-container {
        @apply flex flex-row w-full gap-x-4;

        @screen md {
          @apply gap-x-12;
        }

        .name {
          @apply w-2/3;
        }

        .ltd {
          @apply w-1/3;
        }
      }
    }
  }

  .step-info-container {
    @apply w-full mt-9 max-w-lg;

    @screen md {
      @apply mt-16;
    }
  }
}
</style>
