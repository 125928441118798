<template>
  <Modal :title="title">
    <div class="confirm-modal">
      <div class="sure-container">
        <p>{{ message }}</p>
        <p v-if="subtext" class="subtext">{{ subtext }}</p>

        <div class="buttons">
          <Button v-show="cancelText"
            :onclick="() => close(false)"
            secondary
            type="button"
          >
            <span>{{ cancelText }}</span>
          </Button>
          <Button
            :onclick="() => close(true)"
            grey
            type="button"
          >
            <span>{{ confirmText }}</span>
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'confirm-modal',
  components: { Button, Modal },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Confirm',
    },
    message: {
      type: String,
      required: false,
      default: 'Are you sure?',
    },
    subtext: {
      type: String,
      required: false,
      default: null,
    },
    confirmText: {
      type: String,
      required: false,
      default: 'Yes',
    },
    cancelText: {
      type: String,
      required: false,
    },
  },
  methods: {
    close(state = false) {
      this.$modal.hide('confirm-modal', state);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal {
  .sure-container {
    @apply flex flex-col items-center;

    p {
      @apply font-normal text-black text-sml;

      &.subtext {
        @apply text-xs text-grey-dark italic text-center mt-2 leading-loose;
      }
    }

    .buttons {
      @apply flex flex-row justify-center mt-9;

      button {
        @apply mx-4 mt-0 px-8 py-4;
      }
    }
  }
}
</style>
