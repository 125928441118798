import Vue from 'vue';
import VueRouter from 'vue-router';
import Application from '@/views/application/Application.vue';
import Home from '../views/web/Home.vue';
import HowItWorks from '../views/web/HowItWorks.vue';
import Pricing from '../views/web/Pricing.vue';
import TermsConditions from '../views/web/TermsConditions.vue';
import CookiesPolicy from '../views/web/CookiesPolicy.vue';
import Login from '../views/auth/Login.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';
import Account from '../views/account/Account.vue';
import MyDetails from '../views/account/MyDetails.vue';
import MyPlan from '../views/account/MyPlan.vue';
import MyServices from '../views/account/MyServices.vue';
import MyApplications from '../views/account/MyApplications.vue';
import MyDocuments from '../views/account/MyDocuments.vue';
import PaymentMethods from '../views/account/PaymentMethods.vue';
import AddPaymentMethod from '../views/account/AddPaymentMethod.vue';
import PaymentsHistory from '../views/account/PaymentsHistory.vue';
import EmailNotifications from '../views/account/EmailNotifications.vue';
import AccountSettings from '../views/account/AccountSettings.vue';
import store from '../store';
import SignUp from '../views/auth/SignUp.vue';
import SignUpInvite from '../views/auth/SignUpInvite.vue';
import Cart from '../views/cart/Cart.vue';

Vue.use(VueRouter);

const routes = [
  // web routes
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'web',
      auth: false,
    },
  },
  {
    path: '/how-it-works',
    name: 'how-it-works',
    component: HowItWorks,
    meta: {
      layout: 'web',
      auth: false,
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
    meta: {
      layout: 'web',
      auth: false,
    },
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: TermsConditions,
    meta: {
      layout: 'web',
      auth: false,
    },
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy',
    component: CookiesPolicy,
    meta: {
      layout: 'web',
      auth: false,
    },
  },

  // auth routes
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'auth',
      auth: 'guest',
    },
  },
  {
    path: '/sign-up/invite',
    name: 'sign-up-invite',
    component: SignUpInvite,
    meta: {
      layout: 'auth',
      auth: 'guest',
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUp,
    meta: {
      layout: 'auth',
      auth: 'guest',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'auth',
      auth: 'guest',
    },
  },
  {
    path: '/password/reset',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      layout: 'auth',
      auth: 'guest',
    },
  },

  // account routes
  {
    path: '/account',
    name: 'account',
    component: Account,
    redirect: '/account/details',
    children: [
      {
        path: 'details',
        name: 'my_details',
        component: MyDetails,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'plan',
        name: 'my_plan',
        component: MyPlan,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'services',
        name: 'my_services',
        component: MyServices,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'applications',
        name: 'my_applications',
        component: MyApplications,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'documents',
        name: 'my_documents',
        component: MyDocuments,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'payment-methods',
        name: 'payment_methods',
        component: PaymentMethods,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'payment-methods/add',
        name: 'add_payment_method',
        component: AddPaymentMethod,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'payments-history',
        name: 'payments_history',
        component: PaymentsHistory,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'email-notifications',
        name: 'email_notifications',
        component: EmailNotifications,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
      {
        path: 'account-settings',
        name: 'account_settings',
        component: AccountSettings,
        meta: {
          layout: 'account',
          auth: true,
        },
      },
    ],
  },

  // cart routes
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      layout: 'web',
      auth: true,
    },
  },

  // application routes
  {
    path: '/application/:application_id?',
    name: 'application',
    component: Application,
    meta: {
      layout: 'application',
      auth: true,
    },
  },

  {
    path: '/application/:application_id/view',
    name: 'application-view',
    component: Application,
    meta: {
      layout: 'application',
      auth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);

  return next();
});

router.afterEach((to) => {
  // check auth
  let routeAuth = to.meta && to.meta.auth;
  if (routeAuth === undefined) routeAuth = true;

  if (routeAuth === true) {
    // user must be logged in
    if (!store.getters.isAuthed) return router.push({ name: 'login' });
  } else if (routeAuth === 'guest') {
    // user must be logged out
    if (store.getters.isAuthed) return router.push('/');
  }

  return true;
});

export default router;
