<template>
  <div class="page-container">
    <Header :subtitle="$t('web.how_it_works.subtitle')" :title="$t('web.how_it_works.title')"
            image="/images/how-it-works-illustration.svg"/>
    <section class="how-it-works page-pad">
      <h2 class="section-title">{{ $t('web.how_it_works.process_title') }}</h2>
      <ProcessStep :checks="[
                       $t('web.how_it_works.process_check_create_account'),
                       $t('web.how_it_works.process_check_select_package'),
                       $t('web.how_it_works.process_check_provide_information'),
                     ]" :is-even="false" :title="$t('web.how_it_works.process_step_1')"
                   class="step"
                   image="/images/how-it-works-step-1.svg">
        <Button class="sign-up-button" :to="{name: 'sign-up'}" outline primary>
          <span>{{ $t('nav.sign_up') }}</span>
        </Button>
      </ProcessStep>
      <ProcessStep :checks="[
                       $t('web.how_it_works.process_check_verify_details'),
                       $t('web.how_it_works.process_check_we_review'),
                       $t('web.how_it_works.process_check_once_approved'),
                       $t('web.how_it_works.process_check_your_documents_filled'),
                     ]" :is-even="true" :title="$t('web.how_it_works.process_step_2')"
                   class="step"
                   image="/images/how-it-works-step-2.svg"/>
      <ProcessStep :checks="[
                       $t('web.how_it_works.process_check_your_documents_reviewed'),
                       $t('web.how_it_works.process_check_your_company_incorporated'),
                     ]" :is-even="false" :title="$t('web.how_it_works.process_step_3')"
                   class="step"
                   image="/images/how-it-works-step-3.svg"/>
      <ProcessStep :checks="[
                       $t('web.how_it_works.process_check_standard_filing'),
                       $t('web.how_it_works.process_check_stay_compliant'),
                     ]" :is-even="true" :title="$t('web.how_it_works.process_step_4')"
                   class="step"
                   image="/images/how-it-works-step-4.svg"/>
    </section>
    <section class="faqs page-pad">
      <h2 class="section-title">{{ $t('web.how_it_works.faqs_title') }}</h2>
      <div v-for="faq in faqs" :key="faq.question" class="faq-container">
        <h3> {{ faq.question }}</h3>
        <p>{{ faq.answer }}</p>
      </div>
    </section>
  </div>
</template>

<script>

import Header from '../../components/web/Header.vue';
import ProcessStep from '../../components/web/how-it-works/ProcessStep.vue';
import Button from '../../components/ui/Button.vue';

export default {
  name: 'HowItWorks',
  components: { Button, ProcessStep, Header },
  data() {
    return {
      faqs: [
        {
          question: this.$t('web.how_it_works.what_information_is_required_q'),
          answer: this.$t('web.how_it_works.what_information_is_required_a'),
        },
        {
          question: this.$t('web.how_it_works.what_are_the_benefits_q'),
          answer: this.$t('web.how_it_works.what_are_the_benefits_a'),
        },
        {
          question: this.$t('web.how_it_works.what_different_types_q'),
          answer: this.$t('web.how_it_works.what_different_types_a'),
        },
        {
          question: this.$t('web.how_it_works.do_i_need_a_bank_account_q'),
          answer: this.$t('web.how_it_works.do_i_need_a_bank_account_a'),
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.$t('nav.how_it_works'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  section.how-it-works {
    @apply flex flex-col bg-white py-16;

    @screen lg {
      @apply py-20;
    }

    .step {
      @apply mb-24;

      @screen md {
        @apply mb-36;
      }

      @screen 2xl {
        @apply mb-64;
      }

      &:last-of-type {
        @apply mb-0;
      }
    }
  }

  .sign-up-button {
    @apply mr-auto;
  }

  section.faqs {
    @apply flex flex-col bg-grey-lightest py-16;

    @screen lg {
      @apply py-20;
    }

    .faq-container {
      @apply mb-16;

      @screen 2xl {
        @apply mb-24;
      }

      &:last-of-type {
        @apply mb-0;
      }

      h3 {
        @apply text-black text-lg leading-normal font-bold text-center mb-8;

        @screen md {
          @apply text-xl leading-normal;
        }

        @screen 2xl {
          @apply text-3xl leading-normal;
        }
      }

      p {
        @apply text-black text-xsm leading-normal font-normal text-center;

        @screen md {
          @apply text-sml leading-normal;
        }
      }
    }
  }
}
</style>
