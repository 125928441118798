<template>
  <div class="page-container page-pad">
    <div v-if="reset" class="reset-container">
      <font-awesome-icon :icon="['far','check-circle']" class="arrow"/>
      <h1>{{ $t('auth.your_password_has_been_reset') }}</h1>

      <Button :to="{name: 'login'}" class="button login">
        <span>{{ $t('auth.back_to_login') }}</span>
      </Button>
    </div>
    <div v-else class="reset-password-container">
      <h1>{{ $t('auth.reset_password') }}</h1>
      <Form class="form-container" @submit="submit">
        <FormInputText v-model="credentials.password" :has-error="$v.credentials.password.$error"
                       :label="`${$t('auth.password')}*`"
                       :placeholder="$t('auth.enter_your_password')"
                       identifier="password"
                       type="password">
          <template v-slot:errors>
            <p v-if="!$v.credentials.password.required">
              {{ $t('auth.validation.password_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="credentials.password_confirmation"
                       :has-error="$v.credentials.password_confirmation.$error"
                       :label="`${$t('auth.confirm_password')}*`"
                       :placeholder="$t('auth.re_enter_your_password')"
                       identifier="password_confirmation"
                       type="password">
          <template v-slot:errors>
            <p v-if="!$v.credentials.password_confirmation.required">
              {{ $t('auth.validation.confirm_your_password') }}
            </p>
            <p v-else-if="!$v.credentials.password_confirmation.confirmation">
              {{ $t('auth.validation.passwords_do_not_match') }}
            </p>
          </template>
        </FormInputText>
        <Button class="button submit" secondary type="submit">
          <span>{{ $t('auth.reset_password') }}</span>
        </Button>
      </Form>
    </div>
  </div>
</template>

<script>
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import Button from '../../components/ui/Button.vue';

export default {
  name: 'ResetPassword',
  components: { FormInputText, Form, Button },
  data() {
    return {
      reset: false,
      credentials: {
        password: null,
        password_confirmation: null,
      },
      is_loading: false,
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      password_confirmation: {
        required: requiredIf((u) => u.password),
        confirmation: sameAs('password'),
      },
    },
  },
  methods: {
    validateToken() {
      this.$axios.post('auth/password/reset/validate', { token: this.$route.query.token }).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {
        this.$router.push('/forgot-password');
        this.is_loading = false;
      });
    },
    submit() {
      this.$v.credentials.$touch();
      if (this.$v.credentials.$anyError || this.is_loading) return;

      this.is_loading = true;

      const payload = {
        token: this.$route.query.token,
        password: this.credentials.password,
        password_confirmation: this.credentials.password_confirmation,
      };

      this.$axios.post('auth/password/reset', payload).then(({ data }) => {
        this.reset = true;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.validateToken();
    } else {
      this.$router.push('/forgot-password');
    }
  },
  metaInfo() {
    return {
      title: this.$t('auth.reset_password'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col justify-center;

  .reset-password-container {
    @apply p-6 bg-white w-full max-w-lg mx-auto z-10 rounded-md;

    @screen md {
      @apply w-96 max-w-none;
    }

    @screen 2xl {
      @apply p-8;

      width: 31rem;
    }

    h1 {
      @apply text-black text-lg leading-normal font-bold mb-8 text-center;

      @screen 2xl {
        @apply text-2xl mb-10;
      }
    }

    .form-container {
      @apply flex flex-col items-start;

      .button.submit {
        @apply w-full py-3;
      }
    }
  }

  img.character {
    @apply hidden z-0 absolute w-auto;

    @screen md {
      @apply block bottom-2;

      left: 50%;
      margin-left: -20rem;
      height: 32rem;
    }

    @screen 2xl {
      margin-left: -23.5rem;
    }
  }

  .reset-container {
    @apply px-6 bg-white w-full max-w-sm mx-auto z-10 flex flex-col items-center py-14 rounded-md;

    svg {
      @apply text-green text-5xl mb-5;
    }

    h1 {
      @apply text-black font-bold text-xl leading-normal;
    }

    .button.login {
      @apply mx-auto mt-4;

      @screen 2xl {
        @apply mt-5;
      }

      & > span {
        @apply text-green underline text-xs;

        @screen 2xl {
          @apply text-sm;
        }
      }

      &:focus, &:hover {
        & > span {
          @apply text-black;
        }
      }
    }
  }
}
</style>
