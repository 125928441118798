<template>
  <div id="app">
    <ApplicationLayout v-if="pageLayout === 'application'">
      <router-view/>
    </ApplicationLayout>
    <AccountLayout v-else-if="pageLayout === 'account'">
      <router-view/>
    </AccountLayout>
    <WebLayout v-else-if="pageLayout === 'web'">
      <router-view/>
    </WebLayout>
    <AuthLayout v-else-if="pageLayout === 'auth'">
      <router-view/>
    </AuthLayout>
    <Cookiebar/>
  </div>
</template>

<script>
import Cookiebar from '@/components/Cookiebar.vue';
import ApplicationLayout from './components/application/Layout.vue';
import AccountLayout from './components/account/Layout.vue';
import WebLayout from './components/web/Layout.vue';
import AuthLayout from './components/auth/Layout.vue';

export default {
  components: {
    Cookiebar, WebLayout, ApplicationLayout, AuthLayout, AccountLayout,
  },
  computed: {
    pageLayout() {
      const routeLayout = this.$route && this.$route.meta && this.$route.meta.layout;

      return routeLayout ?? 'web';
    },
  },
  metaInfo: {
    titleTemplate: '%s - LegalWiz',
  },
};
</script>
