<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_7.title') }}</p>
      <Form class="form-container" @submit="submit">
        <FormInputText v-model="$v.name.$model" :has-error="$v.name.$error"
                       :label="$t('application.step_7.full_name')" @blur="saveData"
                       :placeholder="$t('application.step_7.full_name')" class="form-input"
                       identifier="name" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.name.required">
              {{ $t('application.step_7.validation.full_name_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputSelect
          v-model="$v.independent_jointly.$model"
          :allow-empty="false"
          :has-error="$v.independent_jointly.$error"
          :label="$t('application.step_7.independent_jointly')"
          :options="independentOptions"
          :placeholder="$t('application.step_7.independent_jointly')"
          class="input-independent-jointly"
          display-label="name"
          track-by="value"
          @input="saveData" :disabled="$route.path.includes('view')"
        >
          <template v-slot:errors>
            <p v-if="!$v.name.independent_jointly">
              {{ $t('application.step_7.validation.independent_jointly_required') }}
            </p>
          </template>
        </FormInputSelect>
      </Form>
    </div>
    <StepInfo class="step-info-container">
      <p>{{ $t('application.step_7.info_1') }}</p>
      <p>{{ $t('application.step_7.info_2') }}</p>
      <p>{{ $t('application.step_7.info_3') }}</p>
      <p>{{ $t('application.step_7.info_4') }}</p>
    </StepInfo>
  </div>
</template>

<script>
import StepInfo from '@/components/application/StepInfo.vue';
import Form from '@/components/ui/Form.vue';
import { required } from 'vuelidate/lib/validators';
import FormInputText from '@/components/ui/FormInputText.vue';
import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import _ from 'lodash';

export default {
  name: 'ApplicationStep7',
  components: {
    FormInputSelect,
    FormInputText,
    Form,
    StepInfo,
  },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      name: null,
      independent_jointly: null,
      independentOptions: [
        { name: this.$t('application.step_7.independently'), value: 'independently' },
        { name: this.$t('application.step_7.jointly'), value: 'jointly' },
      ],
    };
  },
  validations: {
    name: { required },
    independent_jointly: { required },
  },
  methods: {
    submit() {
      // TODO
    },

    async populate() {
      if (this.currentData && this.currentData.representer) {
        this.name = this.currentData.representer.name;
        this.independent_jointly = _.find(this.independentOptions, { value: this.currentData.representer.independent_jointly.value });
      }
    },

    saveData() {
      const tempData = {
        representer: {
          name: this.name,
          independent_jointly: this.independent_jointly,
        },
      };

      const currentData = tempData;
      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },

    getData() {
      return {
        representer: {
          name: this.name,
          independent_jointly: this.independent_jointly,
        },
      };
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
  },
  async mounted() {
    await this.populate();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply flex flex-col items-start w-full max-w-xl;

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      @apply w-full;

      & > .input-independent-jointly {
        @apply w-1/2;
      }
    }
  }

  .step-info-container {
    @apply w-full mt-9 max-w-lg;

    @screen md {
      @apply mt-16;
    }
  }
}
</style>
