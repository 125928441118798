<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.email_notifications') }}</h2>
    <div class="content-container">
      <section v-for="group in groups" :key="group.key" class="alerts-notifications">
        <header>
          <h2 class="title">{{ $t(`account.email_notifications.${group.key}`) }}</h2>
          <span class="toggle" @click="toggleAll(group.key)">
            {{ $t('account.email_notifications.toggle_all') }}
          </span>
        </header>
        <div class="options-container">
          <span class="send-me">{{ $t('account.email_notifications.send_me') }}:</span>
          <div v-for="option in group.options" :key="option.key" class="option-container">
            <FormInputCheck v-model="option.value"/>
            <p>{{ option.text }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FormInputCheck from '@/components/ui/FormInputCheck.vue';

export default {
  name: 'EmailNotifications',
  components: { FormInputCheck },
  data() {
    return {
      groups: [
        {
          key: 'alerts_notifications',
          options: [
            {
              key: 1,
              text: 'Description of notification',
              value: false,
            },
            {
              key: 2,
              text: 'Description of notification',
              value: true,
            },
            {
              key: 3,
              text: 'Description of notification',
              value: false,
            },
          ],
        },
        {
          key: 'weekly_newsletters',
          options: [
            {
              key: 4,
              text: 'Description of notification',
              value: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleAll(key) {
      const groupIndex = this.groups.findIndex((g) => g.key === key);
      if (groupIndex === -1) return;

      // check if all of them are true
      let allTrue = true;
      for (let i = 0; i < this.groups[groupIndex].options.length; i++) {
        if (!this.groups[groupIndex].options[i].value) {
          allTrue = false;
          break;
        }
      }

      for (let i = 0; i < this.groups[groupIndex].options.length; i++) {
        this.groups[groupIndex].options[i].value = !allTrue;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.email_notifications'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  .content-container {
    @apply flex flex-col mt-14 w-full gap-y-16;

    section {
      @apply flex flex-col;

      & > header {
        @apply flex flex-row items-center justify-between pb-5 border-b border-grey-light mb-7;

        h2.title {
          @apply text-black font-bold text-lg leading-normal;
        }

        span.toggle {
          @apply text-sml leading-normal cursor-pointer text-grey select-none;
        }
      }

      & > .options-container {
        @apply flex flex-col gap-y-3;

        span.send-me {
          @apply text-sml leading-normal text-black;
        }

        .option-container {
          @apply flex flex-row items-center;

          p {
            @apply text-black text-sml leading-normal ml-3;

            margin-bottom: -3px;
          }
        }
      }
    }
  }
}
</style>
