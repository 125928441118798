<template>
  <div class="page-container page-pad">
    <div class="login-container">
      <h1>{{ $t('auth.login_title') }}</h1>
      <Form class="form-container" @submit="submit">
        <FormInputText v-model="credentials.email" :has-error="$v.credentials.email.$error || login_error !== null" :label="`${$t('auth.email_address')}*`"
                       :placeholder="$t('auth.enter_your_email_address')"
                       identifier="email"
                       type="text">
          <template v-slot:errors>
            <p v-if="!$v.credentials.email.required">
              {{ $t('auth.validation.email_required') }}
            </p>
            <p v-else-if="!$v.credentials.email.email">
              {{ $t('auth.validation.email_invalid') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="credentials.password" :has-error="$v.credentials.password.$error || login_error !== null"
                       :label="`${$t('auth.password')}*`" :placeholder="$t('auth.enter_your_password')"
                       identifier="password"
                       type="password">
          <template v-slot:errors>
            <p v-if="!$v.credentials.password.required && !login_error">
              {{ $t('auth.validation.password_required') }}
            </p>
            <p v-else-if="$v.credentials.password.required && login_error">
              {{ login_error }}
            </p>
          </template>
        </FormInputText>
        <Button class="button submit" secondary type="submit" :loading="is_loading">
          <span>{{ $t('nav.login') }}</span>
        </Button>
      </Form>
      <Button :to="{name: 'forgot-password'}" class="button forgot">
        <span>{{ $t('auth.forgot_password_q') }}</span>
      </Button>
    </div>
    <img class="character" src="/images/auth-character.svg">
  </div>
</template>

<script>

import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { email, required } from 'vuelidate/lib/validators';
import Button from '../../components/ui/Button.vue';

export default {
  name: 'Login',
  components: { FormInputText, Form, Button },
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      login_error: null,
      is_loading: false,
    };
  },
  validations: {
    credentials: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    submit() {
      this.$v.credentials.$touch();
      if (this.$v.credentials.$anyError || this.is_loading) return;

      this.is_loading = true;

      this.$axios.post('auth/login', this.credentials).then(async ({ data }) => {
        this.$store.commit('token', data.data.token.bearer);
        await this.$store.dispatch('getUser');

        if (this.$store.getters.user.relationships.carts.data.length) {
          this.$store.commit('cart', this.$store.getters.user.relationships.carts.data[0].id);
        } else {
          this.$store.commit('cart', null);
        }

        this.$router.push('/');
        this.is_loading = false;
      }).catch((e) => {
        this.login_error = e.response.data.message;
        this.is_loading = false;
      });
    },
  },
  metaInfo() {
    return {
      title: this.$t('nav.login'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col justify-center;

  .login-container {
    @apply p-6 bg-white w-full max-w-lg mx-auto z-10 rounded-md;

    @screen md {
      @apply w-96 max-w-none;
    }

    @screen 2xl {
      @apply p-8;

      width: 31rem;
    }

    h1 {
      @apply text-black text-lg leading-normal font-bold mb-8 text-center;

      @screen 2xl {
        @apply text-2xl mb-10;
      }
    }

    .form-container {
      @apply flex flex-col items-start;

      .button.submit {
        @apply w-full py-3;
      }
    }

    .button.forgot {
      @apply mx-auto mt-4;

      @screen 2xl {
        @apply mt-5;
      }

      & > span {
        @apply text-green underline text-xs;

        @screen 2xl {
          @apply text-sm;
        }
      }

      &:focus, &:hover {
        & > span {
          @apply text-black;
        }
      }
    }
  }

  img.character {
    @apply hidden z-0 absolute w-auto;

    @screen md {
      @apply block bottom-2;

      left: 50%;
      margin-left: -20rem;
      height: 32rem;
    }

    @screen 2xl {
      margin-left: -23.5rem;
    }
  }
}
</style>
