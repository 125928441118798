<template>
  <div class="form-errors">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FormErrors',
};
</script>

<style lang="scss" scoped>
.form-errors {
  p {
    @apply text-negative text-xs mt-2;
  }
}
</style>
