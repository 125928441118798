<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.payments_history') }}</h2>
    <div class="content-container">
      <header>
        <div class="col date">
          <p class="title">{{ $t('account.payment_history.date') }}</p>
        </div>
        <div class="col description">
          <p class="title">{{ $t('account.payment_history.description') }}</p>
        </div>
        <div class="col amount">
          <p class="title">{{ $t('account.payment_history.amount') }}</p>
        </div>
        <div class="col status">
          <p class="title">{{ $t('account.payment_history.status') }}</p>
        </div>
        <div class="col action">
        </div>
      </header>
      <div v-for="payment in payments" :key="payment.reference" class="row">
        <div class="col date">
          <p class="title">{{ $t('account.payment_history.date') }}</p>
          <p class="value">{{ payment.date }}</p>
        </div>
        <div class="col description">
          <p class="title">{{ $t('account.payment_history.description') }}</p>
          <p class="value">{{ payment.description }}</p>
        </div>
        <div class="col amount">
          <p class="title">{{ $t('account.payment_history.amount') }}</p>
          <p class="value">€{{ payment.amount }}</p>
        </div>
        <div :class="`col status ${payment.status.toLowerCase()}`">
          <p class="title">{{ $t('account.payment_history.status') }}</p>
          <p class="value">{{ $t(`account.payment_history.${payment.status.toLowerCase()}`) }}</p>
        </div>
        <div class="col action">
          <!-- TODO action -->
          <Button class="download">
            <font-awesome-icon :icon="['far', 'arrow-alt-to-bottom']"/>
          </Button>
        </div>
      </div>
      <div class="pagination-container">
        <div class="status">
          <p>{{
              $t('pagination_showing_entries', {
                from: (per_page * (page - 1)) + 1,
                to: (per_page * page),
                total: total_records
              })
            }}</p>
        </div>
        <div class="pagination">
          <Pagination :current="page" :ipp="per_page" :total="total_records"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue';
import Button from '@/components/ui/Button.vue';

export default {
  name: 'PaymentsHistory',
  components: { Button, Pagination },
  data() {
    return {
      payments: [
        {
          reference: 1,
          date: '2020-02-01',
          description: 'Plus Plan',
          amount: 200,
          status: 'successful',
        },
        {
          reference: 2,
          date: '2021-04-06',
          description: 'Plus Plan',
          amount: 260,
          status: 'successful',
        },
        {
          reference: 3,
          date: '2021-05-02',
          description: 'Plus Plan',
          amount: 100,
          status: 'failed',
        },
      ],
      total_records: 150,
      per_page: 5,
      page: 1,
    };
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.payments_history'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  .content-container {
    @apply pt-7 w-full;

    & > header {
      @apply hidden mb-7 px-6 mt-6;

      @screen md {
        @apply grid grid-cols-5 gap-x-8 gap-y-4;
      }

      @screen lg {
        @apply px-8;
      }

      & > .col {
        p.title {
          @apply text-base leading-normal text-green;

          @screen lg {
            @apply text-lg leading-normal;
          }
        }
      }
    }

    & > .row {
      @apply grid gap-x-8 gap-y-4 items-center shadow-lg mb-7 px-5 py-5 rounded-lg;

      grid-template-areas: "date description action" "amount status action";

      @screen md {
        @apply py-8 px-6 grid-cols-5;

        grid-template-areas: "date description amount status action";
      }

      @screen lg {
        @apply py-10 px-8;
      }

      & > .col {
        p.title {
          @apply text-xs leading-normal text-green mb-1;

          @screen md {
            @apply hidden;
          }
        }

        p.value {
          @apply text-xs leading-normal text-black;

          @screen md {
            @apply text-base leading-normal;
          }

          @screen lg {
            @apply text-lg leading-normal;
          }
        }

        &.date {
          grid-area: date;
        }

        &.description {
          grid-area: description;
        }

        &.amount {
          grid-area: amount;
        }

        &.status {
          grid-area: status;

          &.successful {
            p.value {
              @apply text-green;
            }
          }

          &.failed {
            p.value {
              @apply text-negative;
            }
          }
        }

        &.action {
          grid-area: action;

          & > .download {
            & > svg {
              @apply text-xl;
            }
          }
        }
      }
    }

    & > .pagination-container {
      @apply flex flex-row items-center justify-between mt-10;

      .status {
        p {
          @apply text-xsm text-black;
        }
      }

      .pagination {

      }
    }
  }
}
</style>
