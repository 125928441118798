<template>
  <div class="file-upload-container">
    <div class="file-upload-wrapper">
      <label v-if="label" :for="identifier">{{ label }}</label>

      <div v-if="!value" class="upload-button-container">
        <input
          :id="identifier"
          :accept="accept"
          :multiple="multiple"
          :name="identifier"
          type="file"
          @change="(e) => $emit('change', e)"
        />
        <label :for="identifier" class="upload-button">{{
            $t('form.choose_file')
          }}</label>
      </div>
      <div v-else class="file-current">
        <p>{{ current }}</p>
        <font-awesome-icon
          :icon="['far', 'times']"
          @click="() => $emit('clear')"
        />
      </div>
    </div>
    <FormErrors v-if="hasError" :shifted="false">
      <slot name="errors"></slot>
    </FormErrors>
    <p v-if="supportedTypes" class="info-text">
      {{ $t('form.file_types_supported_x', {x: supportedTypes}) }}
    </p>
  </div>
</template>

<script>

import FormErrors from '@/components/ui/FormErrors.vue';

export default {
  name: 'FormInputFile',
  components: { FormErrors },
  props: {
    identifier: {
      type: String,
      default: null,
    },
    value: {
      type: [File, FileList],
      default: null,
    },
    current: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: '*',
    },
    supportedTypes: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload-container {
  @apply flex flex-col w-full;

  .file-upload-wrapper {
    @apply flex flex-row items-center max-w-full flex-1;

    & > label {
      @apply text-xs text-grey-dark mr-5 flex-none;
    }

    .upload-button-container {
      @apply flex flex-row flex-wrap w-full text-left flex-1;

      input {
        @apply hidden;
      }

      .upload-button {
        @apply text-xs text-black font-bold px-2 py-1 border-grey-light border-2 rounded
        cursor-pointer;

        &:hover {
          @apply border-grey;
        }
      }
    }

    .file-current {
      @apply flex flex-row items-center px-2 py-1 border-grey-light border-2 rounded w-auto
      max-w-full min-w-0;

      & > p {
        @apply text-xs text-black font-bold truncate flex-1;
      }

      & > svg {
        @apply text-black text-xs ml-2 cursor-pointer flex-none;

        &:hover {
          @apply text-grey-dark;
        }
      }
    }
  }

  .info-text {
    @apply text-xxs text-grey-dark italic mt-4;
  }
}
</style>
