<template>
  <div class="input-group-two">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FormGroupTwo'
};
</script>

<style lang="scss" scoped>
.input-group-two {
  @apply flex flex-row items-start flex-wrap w-auto;

  @screen md {
    @apply -mx-2;
  }

  .form-input-container {
    @apply w-full px-0;

    @screen md {
      @apply w-1/2 px-2;
    }
  }
}
</style>
