<template>
  <section class="lets-do-it page-pad">
    <h1>{{ $t('web.lets_do_it') }}</h1>
    <Button :to="{name: 'sign-up-invite'}" class="nav-element button" secondary>
      <span>{{ $t('nav.sign_up') }}</span>
    </Button>
  </section>
</template>

<script>
import Button from '../ui/Button.vue';

export default {
  name: 'LetsDoIt',
  components: { Button },
};
</script>

<style lang="scss" scoped>
section.lets-do-it {
  @apply bg-green flex flex-col items-center py-10;

  h1 {
    @apply text-black font-bold text-4xl mb-6;
  }

  .button {
    & > span {
      @apply text-sm;
    }
  }

  @screen 2xl {
    @apply py-20;

    h1 {
      @apply text-5xl mb-10;
    }

    .button {
      & > span {
        @apply text-base px-8 py-2;
      }
    }
  }
}
</style>
