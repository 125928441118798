import Vue from 'vue';
import moment from 'moment-timezone';
import axios from 'axios';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import VueTheMask from 'vue-the-mask';
import PrettyCheck from 'pretty-checkbox-vue/check';
import VModal from 'vue-js-modal';
import VueTippy, { TippyComponent } from 'vue-tippy';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import {
  faArrowAltToBottom as farArrowAltToBottom,
  faArrowAltToTop as farArrowAltToTop,
  faBars as farBars,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCog as farCog,
  faCoin as farCoin,
  faConciergeBell as farConciergeBell,
  faCreditCard as farCreditCard,
  faEnvelopeOpenText as farEnvelopeOpenText,
  faFile as farFile,
  faFileAlt as farFileAlt,
  faFileCertificate as farFileCertificate,
  faFolders as farFolders,
  faGavel as farGavel,
  faHistory as farHistory,
  faLightbulbOn as farLightbulbOn,
  faMouseAlt as farMouseAlt,
  faPenAlt as farPenAlt,
  faScroll as farScroll,
  faSearch as farSearch,
  faShieldCheck as farShieldCheck,
  faTachometerAltFastest as farTachometerAltFastest,
  faTimes as farTimes,
  faUndo as farUndo,
  faUserCircle as farUserCircle,
  faUserHeadset as farUserHeadset,
  faShoppingCart as farShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck as fasCheck,
  faQuestionCircle as fasQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCcAmex as fabCcAmex,
  faCcDiscover as fabCcDiscover,
  faCcMastercard as fabCcMastercard,
  faCcVisa as fabCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';
import router from './router';
import store from './store';
import './style/tailwind.css';
import './style/app.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from './i18n';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Content-Type'] = 'application/json';

axios.interceptors.request.use((c) => {
  const config = c;
  if (store.getters.isAuthed) config.headers.common.Authorization = `Bearer ${store.getters.token}`;

  return config;
});

Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$monthOptions = [
  {
    key: 'jan',
    value: 1,
  },
  {
    key: 'feb',
    value: 2,
  },
  {
    key: 'mar',
    value: 3,
  },
  {
    key: 'apr',
    value: 4,
  },
  {
    key: 'may',
    value: 5,
  },
  {
    key: 'jun',
    value: 6,
  },
  {
    key: 'jul',
    value: 7,
  },
  {
    key: 'aug',
    value: 8,
  },
  {
    key: 'sep',
    value: 9,
  },
  {
    key: 'oct',
    value: 10,
  },
  {
    key: 'nov',
    value: 11,
  },
  {
    key: 'dec',
    value: 12,
  },
];
Vue.prototype.$yearOptions = ['2021', '2022', '2023', '2024', '2025', '2026']; // TODO

Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.component('multiselect', Multiselect);
Vue.use(VueTheMask);
Vue.component('p-check', PrettyCheck);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

Vue.config.productionTip = false;

library.add(
  farMouseAlt,
  farBars,
  fasCheck,
  farTachometerAltFastest,
  farShieldCheck,
  farCog,
  farGavel,
  farCoin,
  farUserHeadset,
  farShoppingCart,
  farPenAlt,
  farFolders,
  farFileCertificate,
  farSearch,
  fasQuestionCircle,
  farTimes,
  farUndo,
  farCheckCircle,
  farUserCircle,
  farFile,
  farConciergeBell,
  farScroll,
  farFileAlt,
  farCreditCard,
  farHistory,
  farEnvelopeOpenText,
  farChevronLeft,
  farChevronRight,
  farChevronDown,
  farArrowAltToTop,
  farArrowAltToBottom,
  farCheck,
  fabCcVisa,
  fabCcMastercard,
  fabCcAmex,
  fabCcDiscover,
  farLightbulbOn,
  faInfoCircle
);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
