<template>
  <div class="inner-page-container" v-if="!is_loading">
    <h2 class="page-title">{{ $t('account.nav.my_plan') }}</h2>
    <div v-if="!service_packs.length" class="no-plan-container">
      <p class="message">{{ $t('account.plan.no_plan_purchased_yet') }}</p>
      <!-- TODO action -->
      <Button secondary>
        <span>{{ $t('buy_now') }}</span>
      </Button>
    </div>
    <div v-else class="plans-container">
      <div class="plan-container" v-for="pack in service_packs" :key="pack.id">
        <header>
          <div class="info-row">
            <p class="title">{{ pack.attributes.name }}</p>
            <p class="divider">-</p>
            <p class="price">€{{ pack.attributes.price }}</p>
          </div>
          <p class="excluding">{{ $t('account.plan.excluding_gov_incorp_fees') }}</p>
        </header>
        <div class="features-container" v-if="pack && pack.relationships && pack.relationships.services">
          <div v-for="service in pack.relationships.services" :key="service.id" class="feature-container">
            <font-awesome-icon :icon="['fas','check']" class="check"/>
            <p>{{ service.attributes.name }}</p>
            <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="service.attributes.description" :icon="['fas','question-circle']" class="info"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';

export default {
  name: 'MyPlan',
  components: { Button },
  data() {
    return {
      service_packs: [],
      is_loading: false
    };
  },
  methods: {
    async getServicePackServices() {
      await this.service_packs.forEach((pack, index) => {
        this.$axios.get(`servicePacks/${pack.id}`).then(({ data }) => {
          // eslint-disable-next-line no-param-reassign
          this.service_packs[index] = data.data;
          this.is_loading = false;
        }).catch((e) => {
          this.is_loading = false;
        });
      });
    },
  },
  async mounted() {
    this.is_loading = true;

    await this.$store.dispatch('getUser');
    this.service_packs = this.$store.getters.user.relationships.servicePacks.data;
    await this.getServicePackServices();
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.my_plan'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  .no-plan-container {
    @apply flex flex-col items-start mt-9;

    p.message {
      @apply text-lg leading-normal font-bold text-black mb-8;
    }
  }

  .plans-container{
    @apply flex flex-col;

    .plan-container {
      @apply mt-8 p-8 border border-green rounded w-full;

      header {
        @apply flex flex-col items-start;

        .info-row {
          @apply flex flex-row items-center;

          p.title {
            @apply text-black font-bold text-2xl leading-normal;
          }

          p.divider {
            @apply text-green font-bold text-2xl leading-normal mx-4;
          }

          p.price {
            @apply text-black font-bold text-2xl leading-normal;
          }
        }

        p.excluding {
          @apply text-black text-xs leading-normal font-normal italic;

          @screen md {
            @apply ml-4;
          }
        }

        @screen md {
          @apply flex-row items-center;
        }
      }

      .features-container {
        @apply flex flex-col mt-7 w-full gap-y-4;

        @screen md {
          @apply gap-y-5;
        }

        @screen lg {
          @apply flex-wrap content-start gap-x-18;

          max-height: 24rem;
        }

        .feature-container {
          @apply flex flex-row items-center;

          &:last-of-type {
            @apply mb-0;
          }

          svg.check {
            @apply text-green text-sm mr-4;
          }

          p {
            @apply text-black text-xs leading-normal;

            @screen md {
              @apply text-sm leading-normal;
            }
          }

          svg.info {
            @apply text-black text-sm ml-6;
          }

          &.disabled {
            svg.check, svg.info, p {
              @apply text-grey;
            }
          }
        }
      }
    }

  }
}
</style>
