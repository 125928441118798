<template>
  <div class="page-container page-pad">
    <div class="sign-up-container">
      <h1>{{ $t('auth.sign_up_title') }}</h1>
      <Form class="form-container" @submit="submit">
        <FormInputText v-model="credentials.password" :has-error="$v.credentials.password.$error"
                       :label="`${$t('auth.password')}*`"
                       :placeholder="$t('auth.enter_your_password')"
                       identifier="password"
                       type="password">
          <template v-slot:errors>
            <p v-if="!$v.credentials.password.required">
              {{ $t('auth.validation.password_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="credentials.password_confirmation"
                       :has-error="$v.credentials.password_confirmation.$error"
                       :label="`${$t('auth.confirm_password')}*`"
                       :placeholder="$t('auth.re_enter_your_password')"
                       identifier="password_confirmation"
                       type="password">
          <template v-slot:errors>
            <p v-if="!$v.credentials.password_confirmation.required">
              {{ $t('auth.validation.confirm_your_password') }}
            </p>
            <p v-else-if="!$v.credentials.password_confirmation.confirmation">
              {{ $t('auth.validation.passwords_do_not_match') }}
            </p>
          </template>
        </FormInputText>
        <div class="tc-container">
          <p-check class="checkbox p-icon p-curve" color="primary" @change="toggleCheck"><font-awesome-icon slot="extra" :icon="['far', 'check']" class="icon"/></p-check>
          <p>{{ $t('auth.agree_to') }} <router-link to="terms-conditions" target="_blank">Terms & Conditions</router-link></p>
        </div>
        <p v-if="tc_accepted_error" class="error">
          {{ $t('auth.validation.tc_required') }}
        </p>
        <Button class="button submit" secondary type="submit" :loading="is_loading">
          <span>{{ $t('submit') }}</span>
        </Button>
      </Form>
    </div>
  </div>
</template>

<script>
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import Button from '../../components/ui/Button.vue';

export default {
  name: 'SignUp',
  components: {
    FormInputText, Form, Button
  },
  data() {
    return {
      credentials: {
        password: null,
        password_confirmation: null,
      },
      tc_accepted: false,
      tc_accepted_error: false,
      is_loading: false,
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      password_confirmation: {
        required: requiredIf((u) => u.password),
        confirmation: sameAs('password'),
      },
    },
  },
  methods: {
    toggleCheck() {
      this.tc_accepted = !this.tc_accepted;
      this.tc_accepted_error = !this.tc_accepted;
    },
    validateToken() {
      this.$axios.post('auth/invite/validate', { token: this.$route.query.token }).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {
        this.$router.push('/sign-up/invite');
        this.is_loading = false;
      });
    },
    submit() {
      if (!this.tc_accepted) {
        this.tc_accepted_error = true;
      }

      this.$v.credentials.$touch();
      if (this.$v.credentials.$anyError || !this.tc_accepted || this.is_loading) return;

      this.is_loading = true;

      const payload = {
        token: this.$route.query.token,
        password: this.credentials.password,
        password_confirmation: this.credentials.password_confirmation,
      };

      this.$axios.post('auth/register', payload).then(({ data }) => {
        this.is_loading = false;
        this.$router.push('/login');
      }).catch((e) => {
        this.is_loading = false;
      });
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.validateToken();
    } else {
      this.$router.push('/forgot-password');
    }
  },
  metaInfo() {
    return {
      title: this.$t('nav.sign_up'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col justify-center;

  .sign-up-container {
    @apply p-6 bg-white w-full max-w-lg mx-auto z-10 rounded-md;

    @screen md {
      @apply w-96 max-w-none;
    }

    @screen 2xl {
      @apply p-8;

      width: 31rem;
    }

    h1 {
      @apply text-black text-lg leading-normal font-bold mb-8 text-center;

      @screen 2xl {
        @apply text-2xl mb-10;
      }
    }

    .form-container {
      @apply flex flex-col items-start;

      & > .tc-container {
        @apply flex flex-row justify-center items-center mb-8;

        p {
          @apply text-black text-sml leading-normal ml-3;
        }
      }

      .error {
        @apply text-xs text-negative mb-4;
        margin-top: -1rem;
      }

      .button.submit {
        @apply w-full py-3;
      }
    }
  }

  img.character {
    @apply hidden z-0 absolute w-auto;

    @screen md {
      @apply block bottom-2;

      left: 50%;
      margin-left: -20rem;
      height: 32rem;
    }

    @screen 2xl {
      margin-left: -23.5rem;
    }
  }

  .reset-container {
    @apply px-6 bg-white w-full max-w-sm mx-auto z-10 flex flex-col items-center py-14 rounded-md;

    svg {
      @apply text-green text-5xl mb-5;
    }

    h1 {
      @apply text-black font-bold text-xl leading-normal;
    }
  }
}
</style>
