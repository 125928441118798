<template>
  <div :class="{ small }" class="pagination-container">
    <div class="sm-display">
      <button :disabled="current > 1" type="button" @click="go(current - 1)">
        <font-awesome-icon :icon="['far', 'chevron-left']"/>
      </button>
      <button :disabled="current < pages" type="button" @click="go(current + 1)">
        <font-awesome-icon :icon="['far', 'chevron-right']"/>
      </button>
    </div>
    <div class="lg-display">
      <button v-if="current > 1" type="button" @click="go(current - 1)">
        <font-awesome-icon :icon="['far', 'chevron-left']"/>
      </button>
      <template v-if="pageRow.first">
        <button
          :class="{ active: pageRow.first === current }"
          type="button"
          @click="go(pageRow.first)"
        >
          {{ pageRow.first }}
        </button>
        <div class="dot-separator">...</div>
      </template>
      <button
        v-for="page in pageRow.range"
        :key="page"
        :class="{ active: page === current }"
        type="button"
        @click="go(page)"
      >
        {{ page }}
      </button>
      <template v-if="pageRow.last">
        <div class="dot-separator">...</div>
        <button
          :class="{ active: pageRow.last === current }"
          type="button"
          @click="go(pageRow.last)"
        >
          {{ pageRow.last }}
        </button>
      </template>
      <button v-if="current < pages" type="button" @click="go(current + 1)">
        <font-awesome-icon :icon="['far', 'chevron-right']"/>
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Pagination',
  props: {
    ipp: {
      // items per page
      type: Number,
      required: true,
    },
    total: {
      // total records
      type: Number,
      required: true,
    },
    current: {
      // current active page
      type: Number,
      default: 1,
    },
    beside: {
      type: Number, // number of pages beside currently active page
      default: 1,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    go(page) {
      if (page <= 0 || page > this.pages) return;
      if (this.current !== page) this.$emit('go', page);
      this.$parent.onPageChange(page);
    },
  },
  computed: {
    pages() {
      // number of pages
      return Math.ceil(this.total / this.ipp);
    },
    pageRow() {
      // page row
      const start = Math.max(this.current - this.beside, 1);
      const end = Math.min(this.current + this.beside, this.pages);

      const range = _.range(start, end + 1);
      let first = start > 1 ? 1 : null;
      let last = end < this.pages ? this.pages : null;

      if (first && first === range[0] - 1) {
        range.unshift(first);
        first = null;
      }

      if (last && last === range[range.length - 1] + 1) {
        range.push(last);
        last = null;
      }

      return { first, range, last };
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  & > div {
    @apply flex flex-row items-center items-stretch;
  }

  .sm-display {
    @apply flex;

    @screen md {
      @apply hidden;
    }

    button {
      @apply border-green rounded-full px-4 py-2;
    }
  }

  .lg-display {
    @apply hidden;

    @screen md {
      @apply flex;
    }
  }

  button {
    @apply px-2 py-1 mx-1 border-2 border-transparent text-black text-xs rounded-md flex flex-row
    justify-center items-center select-none;

    svg {
      @apply text-black text-xs;
    }

    &.active {
      @apply border-green;
    }

    &:focus {
      @apply outline-none;
    }
  }

  .dot-separator {
    @apply text-black text-xxs self-end mb-1 select-none;
  }

  &.small {
    button {
      @apply text-xs;

      svg {
        @apply text-xs;
      }
    }

    .dot-separator {
      @apply text-xs;
    }
  }
}
</style>
