<template>
  <Modal :title="$t('account.documents.upload_document')">
    <Form class="form-container">
      <FormInputSelect
        v-model="$v.document.application.$model"
        :allow-empty="false"
        :has-error="$v.document.application.$error"
        :label="$t('account.documents.application')"
        :options="applicationOptions"
        display-label="name"
        track-by="id"
      >
        <template v-slot:errors>
          <p v-if="!$v.document.application.required">
            {{ $t('account.documents.validation.application_required') }}
          </p>
        </template>
      </FormInputSelect>
      <FormInputText v-model="document.name" :has-error="$v.document.name.$error" :label="`${$t('account.documents.document_name')}`"
                     :placeholder="$t('account.documents.document_name')"
                     identifier="document_name"
                     type="text">
        <template v-slot:errors>
          <p v-if="!$v.document.name.required">
            {{ $t('account.documents.validation.name_required') }}
          </p>
        </template>
      </FormInputText>
      <FormInputFile
        :current="document.file ? document.file.name : ''"
        :has-error="$v.document.file.$error"
        :multiple="false"
        :value="document.file"
        accept="image/jpeg,application/pdf"
        identifier="document_file"
        supported-types=".jpg / .pdf"
        @change="processFile"
        @clear="document.file = null"
      >
        <template v-slot:errors>
          <p v-if="!$v.document.file.required">
            {{ $t('account.documents.validation.file_required') }}
          </p>
        </template>
      </FormInputFile>
    </Form>
    <section class="actions">
      <Button :onclick="close" class="button" grey>
        <span>{{ $t('cancel') }}</span>
      </Button>
      <Button :onclick="submit" class="button" secondary>
        <span>{{ $t('save') }}</span>
      </Button>
    </section>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { required } from 'vuelidate/lib/validators';
import FormInputFile from '@/components/ui/FormInputFile.vue';
import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import Button from '@/components/ui/Button.vue';

export default {
  name: 'UploadDocumentModal',
  components: {
    Button,
    FormInputSelect,
    FormInputFile,
    FormInputText,
    Form,
    Modal,
  },
  data() {
    return {
      document: {
        application: null,
        name: null,
        file: null,
      },
      applicationOptions: [ // TODO dev data
        {
          name: 'Application A',
          id: 1,
        },
        {
          name: 'Application B',
          id: 2,
        },
        {
          name: 'Application C',
          id: 3,
        },
      ],
    };
  },
  validations: {
    document: {
      application: {
        required,
      },
      name: {
        required,
      },
      file: {
        required,
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError);
    },
    processFile(e) {
      if (!e.target.files || !e.target.files[0]) {
        this.$v.document.file.$model = null;
        return;
      }

      const file = e.target.files[0];
      this.$v.document.file.$model = file;
    },
    close(state = false) {
      this.$modal.hide('upload-document-modal', state);
    },
  },
};
</script>

<style lang="scss" scoped>
section.actions {
  @apply flex flex-row justify-center gap-x-6 mt-8;

  & > .button {
    @apply py-3 px-6;
  }
}
</style>
