<template>
  <header class="header-container page-pad">
    <div class="info-container">
      <div class="left-container">
        <h1 v-if="title" class="title">{{ title }}</h1>
        <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
        <slot/>
      </div>
      <div class="right-container">
        <img v-if="image" :alt="title || subtitle" :src="image" class="image"/>
      </div>
    </div>
    <div class="scroll-alt-spacer"></div>
    <div v-if="scroll" class="scroll-container">
      <font-awesome-icon :icon="['far','mouse-alt']"/>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  @apply flex flex-col bg-green pt-7 pb-5;

  @screen md {
    @apply pt-32 pb-6;
  }

  @screen 2xl {
    @apply pt-28;
  }

  .info-container {
    @apply flex flex-col;

    @screen md {
      @apply flex-row items-center;

      grid-column-gap: theme('spacing.12');
    }

    @screen lg {
      grid-column-gap: theme('spacing.32');
    }

    @screen 2xl {
      grid-column-gap: theme('spacing.52');
    }

    .left-container {
      @apply flex flex-col items-start w-full mb-9;

      @screen md {
        @apply w-1/2 mb-0;
      }

      @screen 2xl {
        @apply w-2/5;
      }

      h1.title {
        @apply text-black text-4xl leading-snug font-bold mb-9;

        @screen md {
          @apply text-5xl leading-snug mb-6;
        }

        @screen 2xl {
          @apply text-6xl leading-snug mb-8;
        }
      }

      p.subtitle {
        @apply text-pink text-sml leading-normal font-normal mb-9;

        @screen md {
          @apply text-base leading-normal mb-14;
        }

        @screen 2xl {
          @apply text-xl leading-normal mb-16;
        }
      }
    }

    .right-container {
      @apply w-full;

      @screen md {
        @apply w-1/2;
      }

      @screen 2xl {
        @apply w-3/5;
      }

      .image {
        @apply max-w-full max-h-72 mx-auto;

        @screen md {
          @apply max-h-80;
        }

        @screen lg {
          @apply max-h-96;
        }

        @screen 2xl {
          max-height: 30rem;
        }
      }
    }
  }

  .scroll-alt-spacer {
    @apply h-14;

    @screen md {
      @apply h-24;
    }

    @screen lg {
      @apply h-16;
    }

    @screen 2xl {
      @apply h-12;
    }
  }

  .scroll-container {
    @apply flex flex-row justify-center;

    @screen lg {
      @apply mt-10;
    }

    @screen 2xl {
      @apply mt-16;
    }

    svg {
      @apply text-pink text-3xl;

      @screen md {
        @apply text-4xl;
      }
    }
  }
}
</style>

<style lang="scss">
.header-container {
  .left-container {
    .button {
      & > span {
        @apply text-sm;
      }
    }

    @screen 2xl {
      .button {
        @apply px-6 py-3;

        & > span {
          @apply text-lg;
        }
      }
    }
  }
}
</style>
