<template>
  <div class="page-container page-pad">
    <div v-if="sent" class="sent-container">
      <font-awesome-icon :icon="['far','check-circle']" class="arrow"/>
      <h1>{{ $t('auth.email_successfully_sent') }}</h1>
    </div>
    <div v-else class="forgot-password-container">
      <font-awesome-icon :icon="['far','undo']" class="arrow"/>
      <h1>{{ $t('auth.forgot_password_title') }}</h1>
      <p class="instructions">{{ $t('auth.forgot_password_instructions') }}</p>
      <Form class="form-container" @submit="submit">
        <FormInputText v-model="email" :has-error="$v.email.$error" :label="`${$t('auth.email_address')}*`"
                       :placeholder="$t('auth.enter_your_email_address')"
                       identifier="email"
                       type="text">
          <template v-slot:errors>
            <p v-if="!$v.email.required">
              {{ $t('auth.validation.email_required') }}
            </p>
            <p v-else-if="!$v.email.email">
              {{ $t('auth.validation.email_invalid') }}
            </p>
          </template>
        </FormInputText>
        <Button class="button submit" secondary type="submit" :loading="is_loading">
          <span>{{ $t('continue') }}</span>
        </Button>
      </Form>
    </div>
  </div>
</template>

<script>

import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { email, required } from 'vuelidate/lib/validators';
import Button from '../../components/ui/Button.vue';

export default {
  name: 'ForgotPassword',
  components: { FormInputText, Form, Button },
  data() {
    return {
      email: null,
      sent: false,
      is_loading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    submit() {
      this.$v.email.$touch();
      if (this.$v.email.$anyError || this.is_loading) return;

      this.is_loading = true;

      this.$axios.post('auth/password/reset/request', { email: this.email }).then(({ data }) => {
        this.is_loading = false;
        this.sent = true;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
  },
  metaInfo() {
    return {
      title: this.$t('nav.forgot_password'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col justify-center;

  .forgot-password-container {
    @apply p-6 bg-white w-full max-w-lg mx-auto z-10 relative rounded-md;

    @screen md {
      @apply w-96 max-w-none;
    }

    @screen 2xl {
      @apply p-8;

      width: 31rem;
    }

    & > svg.arrow {
      @apply absolute text-green text-2xl left-6 top-6;

      @screen 2xl {
        @apply left-8 top-8 text-3xl;
      }
    }

    h1 {
      @apply text-black text-lg leading-normal font-bold mb-8 text-center;

      @screen 2xl {
        @apply text-2xl mb-10;
      }
    }

    p.instructions {
      @apply text-black text-center text-xs leading-normal mb-8;

      @screen 2xl {
        @apply text-sm;
      }
    }

    .form-container {
      @apply flex flex-col items-start;

      .button.submit {
        @apply w-full py-3;
      }
    }

    .button.forgot {
      @apply mx-auto mt-4;

      @screen 2xl {
        @apply mt-5;
      }

      & > span {
        @apply text-green underline text-xs;

        @screen 2xl {
          @apply text-sm;
        }
      }

      &:focus, &:hover {
        & > span {
          @apply text-black;
        }
      }
    }
  }

  .sent-container {
    @apply px-6 bg-white w-full max-w-sm mx-auto z-10 flex flex-col items-center py-14 rounded-md;

    svg {
      @apply text-green text-5xl mb-5;
    }

    h1 {
      @apply text-black font-bold text-xl leading-normal;
    }
  }
}
</style>
