var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('Header',{attrs:{"subtitle":_vm.$t('web.how_it_works.subtitle'),"title":_vm.$t('web.how_it_works.title'),"image":"/images/how-it-works-illustration.svg"}}),_c('section',{staticClass:"how-it-works page-pad"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('web.how_it_works.process_title')))]),_c('ProcessStep',{staticClass:"step",attrs:{"checks":[
                     _vm.$t('web.how_it_works.process_check_create_account'),
                     _vm.$t('web.how_it_works.process_check_select_package'),
                     _vm.$t('web.how_it_works.process_check_provide_information') ],"is-even":false,"title":_vm.$t('web.how_it_works.process_step_1'),"image":"/images/how-it-works-step-1.svg"}},[_c('Button',{staticClass:"sign-up-button",attrs:{"to":{name: 'sign-up'},"outline":"","primary":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('nav.sign_up')))])])],1),_c('ProcessStep',{staticClass:"step",attrs:{"checks":[
                     _vm.$t('web.how_it_works.process_check_verify_details'),
                     _vm.$t('web.how_it_works.process_check_we_review'),
                     _vm.$t('web.how_it_works.process_check_once_approved'),
                     _vm.$t('web.how_it_works.process_check_your_documents_filled') ],"is-even":true,"title":_vm.$t('web.how_it_works.process_step_2'),"image":"/images/how-it-works-step-2.svg"}}),_c('ProcessStep',{staticClass:"step",attrs:{"checks":[
                     _vm.$t('web.how_it_works.process_check_your_documents_reviewed'),
                     _vm.$t('web.how_it_works.process_check_your_company_incorporated') ],"is-even":false,"title":_vm.$t('web.how_it_works.process_step_3'),"image":"/images/how-it-works-step-3.svg"}}),_c('ProcessStep',{staticClass:"step",attrs:{"checks":[
                     _vm.$t('web.how_it_works.process_check_standard_filing'),
                     _vm.$t('web.how_it_works.process_check_stay_compliant') ],"is-even":true,"title":_vm.$t('web.how_it_works.process_step_4'),"image":"/images/how-it-works-step-4.svg"}})],1),_c('section',{staticClass:"faqs page-pad"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('web.how_it_works.faqs_title')))]),_vm._l((_vm.faqs),function(faq){return _c('div',{key:faq.question,staticClass:"faq-container"},[_c('h3',[_vm._v(" "+_vm._s(faq.question))]),_c('p',[_vm._v(_vm._s(faq.answer))])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }