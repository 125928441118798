<template>
  <div class="page-container account page-pad">
    <div class="selected-service-wrapper">
      <div class="header-container">
        <font-awesome-icon :icon="['far','user-circle']" class="arrow"/>
        <h1>{{ $t('web.cart.services_selected') }}</h1>
      </div>

      <div class="content-container" v-if="cart.relationships.servicePacks.data.length !== 0 || cart.relationships.services.data.length !== 0">
        <div class="plan-container">
          <div class="selected-plan-container" v-if="cart.relationships.servicePacks.data.length !== 0">
            <font-awesome-icon class="remove-icon" :icon="['far','times']" @click="removeServicePack(cart.relationships.servicePacks.data.id)"/>

            <div class="selected-plan-title-container">
              <h2>{{ cart.relationships.servicePacks.data.attributes.name }} - €{{ cart.relationships.servicePacks.data.attributes.price }}</h2>
              <p>excluding government incorporation fees</p>
            </div>
            <div class="checks-container">
              <div class="check-container" v-for="service in cart.relationships.servicePacks.data.relationships.services.data" :key="service.id">
                <font-awesome-icon :icon="['fas','check']" class="checkmark"/>
                <p>{{ service.attributes.name }}</p>
              </div>
            </div>
          </div>
          <div class="services-container">
            <div class="service-container" v-for="service in cart.relationships.services.data" :key="service.id">
              <div class="service-name-container">
                <font-awesome-icon class="service-icon" :icon="['far','tachometer-alt-fastest']" />
                <h1 class="service-title">{{ service.attributes.name }}</h1>
              </div>

              <div class="service-price-container">
                <p class="service-price">€{{ service.attributes.price }}</p>
                <p class="service-valid" v-if="service.attributes.is_yearly_use">valid for 1 year</p>
                <p class="service-valid" v-else-if="service.attributes.is_one_time_use">valid for one use</p>

                <Button class="remove-button" secondary outline :onclick="()=>removeService(service)">
                  <span class="mobile">X</span>
                  <span class="desktop">{{ $t('web.cart.remove') }}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-container">
          <div class="name-container">
            <p class="name">{{ $store.getters.user.attributes.name }}</p>
            <div class="email-container">
              <p class="email">{{$store.getters.user.attributes.email }} </p>
              <button class="not-you" @click="logOut">Not you?</button>
            </div>
          </div>
          <Button class="payment-button" secondary :onclick="togglePaymentModal">
            <span>{{ $t('web.cart.pay') }}</span>
          </Button>
<!--          <div class="payment-form-container">-->
<!--            <p class="payment-method-title">Payment method</p>-->
<!--            <form id="payment-form" @submit="submit">-->

<!--            </form>-->
<!--            <Button secondary class="pay-button">-->
<!--              <span>{{ $t('web.cart.pay') }}</span>-->
<!--            </Button>-->
<!--          </div>-->
        </div>
      </div>
      <p v-else>No services have been added to the cart</p>
    </div>

    <div class="additional-services-container">
      <p class="additional-services-title">Other additional services available</p>
      <div class="additional-services-wrapper">
        <div class="additional-service-container" v-for="service in services" :key="service.id">
          <div class="additional-service">
            <font-awesome-icon class="additional-service-icon" :icon="['far','tachometer-alt-fastest']"/>
            <p class="additional-service-title">{{ service.attributes.name }}</p>
            <div class="additional-service-price-container">
              <p class="additional-service-price">€{{ service.attributes.price }}</p>
              <p class="service-valid" v-if="service.attributes.is_yearly_use">valid for 1 year</p>
              <p class="service-valid" v-else-if="service.attributes.is_one_time_use">valid for one use</p>
            </div>
            <Button secondary :onclick="()=>addServiceToCart(service)">
              <span>{{ $t('web.cart.add') }}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Button from '../../components/ui/Button.vue';
import ConfirmModal from '../../components/ConfirmModal.vue';

export default {
  name: 'Cart',
  components: { Button },

  data() {
    return {
      cart: null,
      services: [],
      is_loading: false,
      is_removing_service: false,
      is_placing_order: false
    };
  },
  computed: {
    servicePack() {
      return this.cart.data.relationships.servicePacks.data[0];
    }
  },
  methods: {
    getCart() {
      this.is_loading = true;

      this.$axios.get(`carts/${this.$store.getters.cart}`).then(({ data }) => {
        this.cart = data.data;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
    getServices() {
      this.$axios.get('services/list').then(({ data }) => {
        this.services = data.data;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
    addServiceToCart(service) {
      this.is_adding_to_cart = true;

      this.$axios.post(`carts/${this.$store.getters.cart}/services/${service.id}/attach`, { quantity: 1 }).then(({ data }) => {
        this.getCart();
        this.is_adding_to_cart = false;
      }).catch((e) => {
        this.is_adding_to_cart = false;
      });
    },
    removeService(service) {
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('web.cart.remove_service'),
          message: this.$t('web.cart.prompt_remove_service'),
          confirmText: this.$t('yes'),
          cancelText: this.$t('no'),
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'closable-modal loose',
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              this.is_removing_service = true;

              this.$axios.delete(`carts/${this.$store.getters.cart}/services/${service.id}/detach`).then(({ data }) => {
                this.getCart();
                this.is_removing_service = false;
              }).catch((response) => {
                this.is_removing_service = false;
              });
            }
          },
        },
      );
    },
    removeServicePack(pack) {
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('web.cart.remove_service_pack'),
          message: this.$t('web.cart.prompt_remove_service_pack'),
          confirmText: this.$t('yes'),
          cancelText: this.$t('no'),
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'closable-modal loose',
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              this.is_removing_service_pack = true;

              this.$axios.delete(`carts/${this.$store.getters.cart}/servicePacks/${pack}/detach`).then(({ data }) => {
                this.getCart();
                this.is_removing_service_pack = false;
              }).catch((response) => {
                this.is_removing_service_pack = false;
              });
            }
          },
        },
      );
    },
    logOut() {
      this.$store.commit('token', null);
      this.$router.push('login');
    },
    togglePaymentModal() {
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('web.cart.payment'),
          message: this.$t('web.cart.prompt_pay_revolut'),
          subtext: this.$t('web.cart.prompt_pay_revolut_subtext'),
          confirmText: this.$t('ok'),
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'closable-modal loose',
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              this.is_placing_order = true;

              this.$axios.post(`carts/${this.$store.getters.cart}/place-order`, { status: 'processing' }).then(({ data }) => {
                this.$store.commit('cart', null);
                this.$router.push('/account/plan');
                this.is_placing_order = false;
              }).catch((response) => {
                this.is_placing_order = false;
              });
            }
          },
        },
      );
    },
  },
  mounted() {
    this.getCart();
    this.getServices();
  },
  metaInfo() {
    return {
      title: this.$t('nav.cart')
    };
  },
};

</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col py-14;

  .selected-service-wrapper{
    @apply flex flex-col w-full mb-8 bg-white rounded-lg max-w-full px-12 py-8 border-white border-2 h-full;
    box-shadow: 0px 10px 15px #00000012;

    .header-container {
      @apply flex flex-row items-center mb-8;

      & > svg {
        @apply text-green text-4xl mr-8;
      }

      & > h1 {
        @apply text-black text-4xl font-bold leading-normal;
      }

    }

    .content-container {
      @apply flex flex-col w-full pb-8;

      @screen xl {
        @apply flex-row w-full;
      }

      .plan-container {
        @apply w-full;

        @screen xl{
          width: 65%;
        }

        .selected-plan-container {
          @apply border border-green rounded p-8 pb-0 mb-12 relative;

          .remove-icon {
            @apply absolute text-3xl text-black cursor-pointer;
            top: 8px;
            right: 12px;
          }

          .selected-plan-title-container {
            @apply flex flex-row items-center;

            h2 {
              @apply text-black text-3xl font-bold leading-normal;
            }

            p {
              @apply ml-4 text-sm italic;
            }
          }

          .checks-container {
            @apply flex flex-col items-start mt-8 content-between pb-8;

            @screen xl {
              @apply flex-row flex-wrap;
            }

            @screen 2xl {
              @apply justify-between;
            }

            .check-container {
              @apply flex flex-row items-center mb-8;
              width: 100%;

              @screen sm{
                width: 49%;
              }

              @screen xl{
                @apply mb-10 mr-8;
                width: 40%;
              }

              &:last-of-type {
                @apply mb-0;
              }

              .checkmark {
                @apply text-green text-sm mr-5;
              }

              .info {
                @apply ml-6 text-xs;
              }

              p {
                @apply text-black text-xsm leading-normal;

                @screen md {
                  @apply text-sml leading-normal;
                }
              }
            }
          }

        }

        .services-container {
          @apply flex flex-col;

          .service-container {
            @apply flex flex-col justify-between px-4 border-b-2 border-grey-dark mb-8 pb-6;

            @screen sm {
              @apply flex-row;
            }

            .service-name-container {
              @apply flex flex-row items-center;

              .service-icon {
                @apply text-green text-xl font-bold leading-normal;
              }

              .service-title {
                @apply text-black text-lg font-medium leading-normal ml-4;

                @screen md {
                  @apply text-xl font-bold;
                }
              }
            }

            .service-price-container{
              @apply flex flex-row items-center mt-4;

              @screen sm {
                @apply mt-0;
              }

              .service-price {
                @apply text-black text-base font-bold leading-normal;

                @screen md {
                  @apply text-xl;
                }
              }

              .service-valid {
                @apply ml-1 text-sm italic;

                @screen md {
                  @apply ml-4;
                }
              }
            }

            .remove-button {
              @apply w-12 ml-auto;

              @screen sm {
                @apply ml-12 w-32;
              }

              @screen lg {
                @apply ml-32;
              }

              span {
                @apply text-black text-sm font-bold;

                &.mobile {
                  @screen sm {
                    @apply hidden;
                  }
                }

                &.desktop {
                  @apply hidden;

                  @screen sm {
                    @apply block;
                  }
                }
              }
            }

            &:last-of-type {
              @apply mb-0;
            }

          }
        }

      }

      .payment-container {
        @apply flex flex-col w-full mt-16 max-w-lg mx-auto;

        @screen xl {
          @apply ml-8 flex-col h-auto mt-0;
          width: 35%;
        }

        & > .name-container{
          @apply flex flex-col border-b-2 border-grey-lighter pb-8 mb-8 w-full;

          @screen xl {
            @apply w-full border-b-2 pr-0;
          }

          & > .name{
            @apply text-black text-base font-bold leading-normal;

          }

          & > .email-container{
            @apply flex flex-row;

            & > .email{

            }

            & > .not-you{
              @apply ml-auto text-grey-dark underline cursor-pointer;

              &:hover {
                @apply text-grey;
              }
            }
          }
        }

        & > .payment-button {
          @apply w-full;
        }

        & > .payment-form-container{
          @apply flex flex-col h-full;

          @screen md{
            @apply pl-16;
            width: 50%;
          }

          @screen xl{
            @apply w-full pl-0 mt-8;
          }

          & > .payment-method-title{
            @apply text-black text-lg font-bold leading-normal mb-8;
          }

          & > .pay-button{
            @apply mt-auto w-full h-12;

            span{
              @apply m-auto text-black text-sm font-bold;

            }
          }

        }

      }
    }
  }

  .additional-services-container {
    @apply flex flex-col w-full mb-8 bg-white rounded-lg max-w-full px-12 py-8 border-white border-2 h-full;
    box-shadow: 0px 10px 15px #00000012;

    .additional-services-title{
      @apply text-black text-2xl font-bold leading-normal;

    }

    .additional-services-wrapper{
      @apply flex flex-row flex-wrap;

      @screen sm{
        @apply -ml-6
      }

      .additional-service-container{
        @apply w-full px-6 py-8;

        @screen sm{
          @apply w-1/2 px-6 py-8;
        }

        @screen md{
          @apply w-1/3 px-6 py-8;
        }

        @screen xl{
          @apply w-1/5 px-6 py-8;
        }

        .additional-service{
          @apply p-5 border border-black rounded flex flex-col;

          .additional-service-icon{
            @apply text-green text-5xl mb-2;
          }

          .additional-service-title{
            @apply mx-auto text-lg mb-2;
          }

          .additional-service-price-container{
            @apply flex flex-row items-center mx-auto mb-4;

            .additional-service-price{
              @apply text-black text-xl font-bold leading-normal mr-2;
            }

            .additional-service-valid{
              @apply ml-4 text-sm italic;
            }
          }

          button{
            @apply mx-auto;
            width: 85%;

            span{
              @apply text-black text-sm font-bold;
            }

          }

        }

      }
    }
  }

}
</style>

<style lang="scss">
.page-container.account {
  .inner-page-container {
    @apply bg-white px-6 py-10 flex flex-col items-start rounded-lg shadow-md;

    & > .page-title, & > header .page-title {
      @apply text-black font-bold text-3xl leading-normal;
    }
  }
}
</style>
