<template>
  <div class="modal-container">
    <header>
      <h2 v-if="title" class="title">{{ title }}</h2>
      <Button v-if="closable" :onclick="() => $parent.close(false)" class="close">
        <font-awesome-icon :icon="['far','times']" class="check"/>
      </Button>
    </header>
    <section class="main">
      <slot/>
    </section>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';

export default {
  name: 'Modal',
  components: { Button },
  props: {
    title: {
      type: String,
      required: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  & > header {
    @apply flex flex-row items-center justify-between px-5 py-7 border-b border-grey;

    @screen md {
      @apply px-7;
    }

    h2.title {
      @apply text-black font-bold text-xl leading-normal;
    }

    .close {
      & > svg {
        @apply text-black text-2xl;
      }
    }
  }

  & > section.main {
    @apply px-5 py-7;

    @screen md {
      @apply px-7;
    }

  }
}
</style>
