<template>
  <footer class="footer-container">
    <p v-html="$t('copyright', {date: new Date().getFullYear()})"></p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer-container {
  @apply bg-transparent absolute bottom-7 w-full;

  p {
    @apply text-center text-pink text-xs leading-normal;
  }
}
</style>
