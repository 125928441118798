<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_2.title') }}</p>
      <Form class="form-container">
        <FormInputSelect
          @input="saveData"
          v-model="$v.hasRegisteredAddress.$model" :allow-empty="false"
          :has-error="$v.hasRegisteredAddress.$error"
          :label="$t('application.step_2.yes_no')"
          :options="yesNoOptions"
          class="has-registered-address"
          display-label="text"
          track-by="value" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.hasRegisteredAddress.required">
              {{ $t('application.step_2.validation.has_registered_address_required') }}
            </p>
          </template>
        </FormInputSelect>
      </Form>
      <div v-if="hasRegisteredAddress.value === 'no'" class="addons-container">
        <p>{{ $t('application.step_2.add_on_service') }}</p>
          <div class="service-container" v-if="registered_address_service">
            <font-awesome-icon :icon="['far','tachometer-alt-fastest']" />
            <p class="name">{{ registered_address_service.attributes.name }}</p>
            <div class="info-container">
              <p class="price">€{{ registered_address_service.attributes.price }}</p>
            </div>

            <!--TODO link-->
            <Button secondary :onclick="addServiceToCart" :class="{spinner: is_adding_to_cart}">
              <span>{{ $t('buy_now') }}</span>
            </Button>
          </div>
        <FormErrors>
          <p v-if="!$v.purchased_registered_address.sameAs">
            {{ $t('application.step_2.validation.registered_address_addon_required') }}
          </p>
        </FormErrors>
      </div>
      <Form v-else-if="hasRegisteredAddress.value === 'yes'"
            class="address-container" @submit="submit">
        <FormInputText v-model="$v.address.building.$model" :has-error="$v.address.building.$error"
                       :label="`${$t('application.step_2.building_number_name')}`"
                       :placeholder="$t('application.step_2.enter_building_number_name')"
                       class="building"
                       identifier="building"  @blur="saveData"
                       type="text" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.address.building.required">
              {{ $t('application.step_2.validation.building_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="$v.address.street.$model" :has-error="$v.address.street.$error"
                       :label="`${$t('application.step_2.street_name')}`"
                       :placeholder="$t('application.step_2.enter_street_name')"
                       class="street"
                       identifier="street"  @blur="saveData"
                       type="text" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.address.street.required">
              {{ $t('application.step_2.validation.street_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText
          v-model="$v.address.locality.$model" :allow-empty="false"
          :has-error="$v.address.locality.$error"
          :label="$t('application.step_2.locality_city')"
          class="locality" @blur="saveData" :disabled="$route.path.includes('view')"
        >
          <template v-slot:errors>
            <p v-if="!$v.address.locality.required">
              {{ $t('application.step_2.validation.locality_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="$v.address.post_code.$model" :has-error="$v.address.post_code.$error"
                       :label="`${$t('application.step_2.post_code')}`"
                       :placeholder="$t('application.step_2.enter_post_code')"
                       class="post_code"
                       identifier="post_code" @blur="saveData"
                       type="text" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.address.post_code.required">
              {{ $t('application.step_2.validation.post_code_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputSelect
          v-model="$v.address.country.$model" :allow-empty="false"
          :has-error="$v.address.country.$error"
          :label="$t('application.step_2.country')"
          :options="countryOptions" class="country"
          display-label="text" track-by="value" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.address.country.required">
              {{ $t('application.step_2.validation.country_required') }}
            </p>
          </template>
        </FormInputSelect>
      </Form>
    </div>
    <StepInfo class="step-info-container">
      <p>{{ $t('application.step_2.info_1') }}</p>
      <p>{{ $t('application.step_2.info_2') }}</p>
      <p>{{ $t('application.step_2.info_3') }}</p>
      <p>{{ $t('application.step_2.info_4') }}</p>
    </StepInfo>
  </div>
</template>

<script>
import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import Form from '@/components/ui/Form.vue';
import StepInfo from '@/components/application/StepInfo.vue';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import Button from '@/components/ui/Button.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import FormErrors from '@/components/ui/FormErrors.vue';
import _ from 'lodash';

export default {
  name: 'ApplicationStep2',
  components: {
    FormErrors,
    FormInputText,
    Button,
    FormInputSelect,
    Form,
    StepInfo,
  },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    const yesNoOptions = [
      { text: this.$t('yes'), value: 'yes' },
      { text: this.$t('no'), value: 'no' },
    ];

    return {
      hasRegisteredAddress: yesNoOptions[1],
      registered_address_service: null,
      purchased_registered_address: false,
      is_adding_to_cart: false,
      address: {
        building: null,
        street: null,
        locality: null,
        post_code: null,
        country: null,
      },
      yesNoOptions,
      addons: [],
      addonServiceOptions: [
        {
          name: this.$t('web.pricing.service_registered_address'),
          price: 300,
          icon: { type: 'far', name: 'tachometer-alt-fastest' },
        },
      ],
      countryOptions: [{ text: 'Malta', value: 'malta' }, { text: 'Gozo', value: 'gozo' }],
    };
  },
  validations: {
    hasRegisteredAddress: {
      required,
    },
    purchased_registered_address: {
      sameAs: sameAs(() => true)
    },
    address: {
      building: {
        required: requiredIf(function () {
          return this.hasRegisteredAddress.value === 'yes';
        })
      },
      street: {
        required: requiredIf(function () {
          return this.hasRegisteredAddress.value === 'yes';
        })
      },
      locality: {
        required: requiredIf(function () {
          return this.hasRegisteredAddress.value === 'yes';
        })
      },
      post_code: {
        required: requiredIf(function () {
          return this.hasRegisteredAddress.value === 'yes';
        })
      },
      country: {
        required: requiredIf(function () {
          return this.hasRegisteredAddress.value === 'yes';
        })
      },
    },
  },
  methods: {
    async addServiceToCart() {
      this.is_adding_to_cart = true;

      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }

      this.$axios.post(`carts/${this.$store.getters.cart}/services/${this.registered_address_service.id}/attach`, { quantity: 1 }).then(({ data }) => {
        this.is_adding_to_cart = false;
        this.purchased_registered_address = true;
        this.saveData();
      }).catch((e) => {
        this.is_adding_to_cart = false;
      });
    },
    async populate() {
      console.log(this.currentData);
      if (this.currentData && this.currentData.hasRegisteredAddress) {
        this.hasRegisteredAddress = _.find(this.yesNoOptions, { value: this.currentData.hasRegisteredAddress });
      }

      if (this.currentData && this.currentData.address) {
        this.address.building = this.currentData.address.building;
        this.address.country = this.currentData.address.country;
        this.address.locality = this.currentData.address.locality;
        this.address.street = this.currentData.address.street;
        this.address.post_code = this.currentData.address.post_code;
      }

      if (this.currentData && this.currentData.purchased_registered_address) {
        this.purchased_registered_address = this.currentData.purchased_registered_address;
      }
    },
    saveData() {
      const tempData = { hasRegisteredAddress: this.hasRegisteredAddress.value, address: null, purchased_registered_address: this.purchased_registered_address };

      if (this.hasRegisteredAddress.value === 'no') {
        tempData.addons = this.addons;
      } else if (this.hasRegisteredAddress.value === 'yes') {
        tempData.address = this.address;
      }

      const currentData = tempData;
      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },
    getData() {
      const payload = { address: null, addons: [] };

      if (this.hasRegisteredAddress.value === 'no') {
        payload.addons = this.addons;
      } else if (this.hasRegisteredAddress.value === 'yes') {
        payload.address = this.address;
      }

      return payload;
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
    getServices() {
      this.$axios.get('services/list').then(({ data }) => {
        const services = data.data;

        services.forEach((service) => {
          if (service.attributes.name === 'Registered Address') {
            this.registered_address_service = service;
          }
        });

        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
  },
  async mounted() {
    await this.populate();
    this.getServices();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply flex flex-col items-start w-full max-w-xl;

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      @apply w-full;

      .has-registered-address {
        @apply w-full max-w-xs;
      }
    }

    & > .addons-container {
      @apply mt-16 w-full;

      & > p {
        @apply text-black font-bold text-base leading-normal mb-4;

        @screen md {
          @apply text-lg leading-normal mb-4;
        }
      }

      .service-container {
        @apply flex flex-col items-center justify-center border border-black rounded py-6 w-full max-w-xs h-full px-4;

        @screen md {
          @apply max-w-sm;
        }

        svg {
          @apply text-green text-5xl mb-4;
        }

        p.name {
          @apply text-black font-medium text-sm mb-5 text-center;
        }

        .info-container {
          @apply flex flex-row items-center mb-4;

          p.price {
            @apply text-black font-bold text-sm;
          }
        }
      }
    }

    & > .address-container {
      @apply flex flex-row flex-wrap;

      & > .building, & > .street {
        @apply w-full;
      }

      & > .locality {
        @apply w-full;

        @screen sm {
          @apply w-1/2 pr-4;
        }
      }

      & > .post_code {
        @apply w-full;

        @screen sm {
          @apply w-1/2 pl-4;
        }
      }

      & > .country {
        @apply w-full;

        @screen sm {
          @apply w-1/2 pr-4;
        }
      }
    }
  }

  .step-info-container {
    @apply w-full mt-9 max-w-lg;

    @screen md {
      @apply mt-16;
    }
  }
}
</style>
