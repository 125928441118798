<template>
  <Modal :title="$t('application.application_submission')">
    <div class="application-submitted-modal">
      <font-awesome-icon :icon="['far','check-circle']" class="arrow"/>
      <p>{{ $t('application.your_application_submitted') }}</p>
      <Button :to="{name:'account'}" class="button" secondary @click.native="close">
        <span>{{ $t('account.return_to_my_account') }}</span>
      </Button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Button from '@/components/ui/Button.vue';

export default {
  name: 'ApplicationSubmittedModal',
  components: { Button, Modal },
  methods: {
    close(state = false) {
      this.$modal.hide('application-submitted-modal', state);
    },
  },
};
</script>

<style lang="scss" scoped>
.application-submitted-modal {
  @apply flex flex-col items-center;

  svg {
    @apply text-green h-10 w-auto mb-5;
  }

  p {
    @apply text-black font-bold text-xl leading-loose text-center mb-8;
  }

  .button {
    @apply py-4 px-6;
  }
}
</style>
