<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.payment_methods') }}</h2>
    <header>
      <h2 class="title">{{ $t('account.payment_methods.enter_credit_debit_card_details') }}</h2>
    </header>
    <Form class="form-container" @submit="submit">
      <div class="card-container">
        <FormInputCreditCard v-model="card.number" :has-error="$v.card.number.$error"
                             :label="`${$t('account.payment_methods.card_number')}`"
                             identifier="card_number">
          <template v-slot:errors>
            <p v-if="!$v.card.number.required">
              {{ $t('account.payment_methods.validation.card_number_required') }}
            </p>
          </template>
        </FormInputCreditCard>
      </div>
      <div class="expiry-container">
        <FormInputSelect
          v-model="$v.card.month.$model"
          :allow-empty="true"
          :display-custom-label="(row) => $t(`months.${row.key}`)"
          :has-error="$v.card.month.$error"
          :label="$t('account.payment_methods.expiry_date')"
          :options="$monthOptions"
          :placeholder="$t('account.payment_methods.month')"
          track-by="value"
        >
          <template v-slot:errors>
            <p v-if="!$v.card.month.required">
              {{ $t('account.payment_methods.validation.month_required') }}
            </p>
          </template>
        </FormInputSelect>
        <FormInputSelect
          v-model="$v.card.year.$model"
          :allow-empty="true"
          :has-error="$v.card.year.$error"
          :options="$yearOptions"
          :placeholder="$t('account.payment_methods.year')"
          label=""
        >
          <template v-slot:errors>
            <p v-if="!$v.card.year.required">
              {{ $t('account.payment_methods.validation.year_required') }}
            </p>
          </template>
        </FormInputSelect>
      </div>
      <div class="name-container">
        <FormInputText v-model="card.name" :has-error="$v.card.name.$error" :label="`${$t('account.payment_methods.name_on_card')}`"
                       identifier="card_name"
                       type="text">
          <template v-slot:errors>
            <p v-if="!$v.card.name.required">
              {{ $t('account.payment_methods.validation.name_required') }}
            </p>
          </template>
        </FormInputText>
      </div>
      <div class="default-container">
        <FormInputCheck v-model="card.is_default"/>
        <p>{{ $t('account.payment_methods.set_as_default_payment_method') }}</p>
      </div>
      <div class="actions-container">
        <Button :to="{name:'payment_methods'}" class="button" grey type="button">
          <span>{{ $t('cancel') }}</span>
        </Button>
        <Button class="button" secondary type="submit">
          <span>{{ $t('save') }}</span>
        </Button>
      </div>
      <div class="accept-container">
        <p>{{ $t('account.payment_methods.we_accept') }}:</p>
        <div class="icons-container">
          <font-awesome-icon :icon="['fab', 'cc-visa']"/>
          <font-awesome-icon :icon="['fab', 'cc-mastercard']"/>
          <font-awesome-icon :icon="['fab', 'cc-amex']"/>
          <font-awesome-icon :icon="['fab', 'cc-discover']"/>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import FormInputText from '@/components/ui/FormInputText.vue';
import Form from '@/components/ui/Form.vue';
import Button from '@/components/ui/Button.vue';
import { required } from 'vuelidate/lib/validators';
import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import FormInputCheck from '@/components/ui/FormInputCheck.vue';
import FormInputCreditCard from '@/components/ui/FormInputCreditCard.vue';

export default {
  name: 'PaymentMethods',
  components: {
    FormInputCreditCard,
    FormInputCheck,
    FormInputSelect,
    FormInputText,
    Form,
    Button,
  },
  data() {
    return {
      card: {
        number: null,
        name: null,
        month: null,
        year: null,
        is_default: false,
      },
    };
  },
  validations: {
    card: {
      number: {
        required,
      },
      name: {
        required,
      },
      month: { required },
      year: { required },
      is_default: {},
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.payment_methods'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  & > header {
    @apply flex flex-row items-center justify-between pb-5 border-b border-grey-light mb-7 mt-10
    w-full;

    h2.title {
      @apply text-black font-bold text-lg leading-normal;
    }
  }

  & > .form-container {
    @apply grid grid-cols-1 w-full;

    @screen md {
      @apply grid-cols-2 gap-x-6;
    }

    & > .card-container {
      @apply order-1;
    }

    & > .expiry-container {
      @apply order-2 flex flex-row items-end gap-x-7;
    }

    & > .name-container {
      @apply order-3;
    }

    & > .default-container {
      @apply order-4 flex flex-row justify-center items-center mb-8;

      @screen md {
        @apply items-start mt-8 mb-0;
      }

      p {
        @apply text-black text-sml leading-normal ml-3;

        margin-bottom: -3px;
      }
    }

    & > .actions-container {
      @apply order-6 flex flex-row items-center gap-x-3;

      @screen md {
        @apply order-5 mt-4;
      }

      .button {
        @apply px-10 py-3;
      }
    }

    & > .accept-container {
      @apply order-5 flex flex-row justify-center items-center mb-8;

      @screen md {
        @apply order-6 mb-0 mt-3;
      }

      p {
        @apply text-black text-sml leading-normal mr-6;
      }

      .icons-container {
        @apply flex flex-row items-center gap-x-3;

        svg {
          @apply text-grey-darker text-3xl;
        }
      }
    }
  }
}
</style>
