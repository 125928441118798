<template>
  <div :class="{ error: hasError }" class="form-input-container">
    <label v-if="label" :for="identifier">{{ label }}</label>
    <p v-if="subtitle">{{ subtitle }}</p>
    <textarea
      v-if="useTextarea"
      :id="identifier"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :placeholder="placeholder"
      :readonly="readonly"
      :rows="rows"
      :spellcheck="spellcheck"
      :value="value"
      class="input"
      @blur="(e) => $emit('blur', e.target.value)"
      @change="(e) => $emit('change')"
      @input="(e) => $emit('input', e.target.value)"
    ></textarea>
    <input
      v-else
      :id="identifier"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :disabled="disabled"
      :placeholder="placeholder"
      :readonly="readonly"
      :spellcheck="spellcheck"
      :type="type"
      :value="value"
      class="input"
      @blur="(e) => $emit('blur', e.target.value)"
      @change="(e) => $emit('change')"
      @input="(e) => $emit('input', e.target.value)"
    />
    <FormErrors v-if="hasError">
      <slot name="errors"></slot>
    </FormErrors>
  </div>
</template>

<script>
import FormErrors from './FormErrors.vue';

export default {
  name: 'FormInputText',
  components: { FormErrors },
  props: {
    value: {
      type: String,
    },
    identifier: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    spellcheck: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    useTextarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input-container {
  @apply w-full flex flex-col items-start mb-6;

  @screen 2xl {
    @apply mb-8;
  }

  label {
    @apply text-xs text-black font-bold mb-1;

    @screen 2xl {
      @apply text-sm;
    }
  }

  input,
  textarea {
    @apply border-2 border-grey rounded text-black font-medium text-xs p-3 w-full appearance-none
    bg-white;

    @screen 2xl {
      @apply text-sm p-4;
    }

    &:disabled {
      @apply text-grey;
    }

    &:focus, &:active {
      @apply outline-none;
    }
  }

  &.error {
    input {
      @apply border-negative;
    }
  }
}
</style>
