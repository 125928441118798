<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.my_services') }}</h2>
    <div v-if="!$store.getters.user.relationships.services.data.length" class="no-services-container">
      <p class="message">{{ $t('account.services.no_additional_services_purchased') }}</p>
      <Button secondary>
        <span>{{ $t('buy_now') }}</span>
      </Button>
    </div>
    <div v-else class="services-container">
      <div v-for="service in $store.getters.user.relationships.services.data" :key="service.name" class="service-container">
        <font-awesome-icon :icon="['far', 'tachometer-alt-fastest']"/>
        <p class="name">{{ service.attributes.name }}</p>
<!--        <p class="buy-status">{{ service.buy_status }}</p>-->
<!--        <p class="valid">-->
<!--          {{ $t('account.services.valid_until_date', {date: service.until_date}) }}-->
<!--        </p>-->
      </div>
    </div>

    <div v-if="$store.getters.user.relationships.services.data.length > 0 && additional_services.length > 0"
         class="additional-services-container">
      <p class="title">{{ $t('account.services.other_additional_services_available') }}</p>
      <div class="services-container">
        <div v-for="service in additional_services" :key="service.id" class="service-container">
          <font-awesome-icon :icon="['far', 'tachometer-alt-fastest']"/>
          <p class="name">{{ service.attributes.name }}</p>
          <div class="info-container">
            <p class="price">€{{ service.attributes.price }}</p>
            <p class="valid" v-if="service.attributes.is_yearly_use">valid for 1 year</p>
            <p class="valid" v-else-if="service.attributes.is_one_time_use">valid for one use</p>
          </div>

          <Button secondary :onclick="()=>addServiceToCart(service)">
            <span>{{ $t('buy_now') }}</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';

export default {
  name: 'MyServices',
  components: { Button },
  data() {
    return {
      additional_services: []
    };
  },
  methods: {
    getServices() {
      this.$axios.get('services/list').then(({ data }) => {
        this.additional_services = data.data;
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
    async addServiceToCart(service) {
      this.is_adding_to_cart = true;

      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }

      this.$axios.post(`carts/${this.$store.getters.cart}/services/${service.id}/attach`, { quantity: 1 }).then(({ data }) => {
        this.is_adding_to_cart = false;
      }).catch((e) => {
        this.is_adding_to_cart = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch('getUser');
    this.getServices();
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.my_services'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  .no-services-container {
    @apply flex flex-col items-start mt-9;

    p.message {
      @apply text-lg leading-normal font-bold text-black mb-8;
    }
  }

  & > .services-container {
    @apply grid grid-cols-1 gap-y-9 mt-9 w-full place-items-center;

    @screen md {
      @apply grid-cols-2 gap-x-7;
    }

    @screen lg {
      @apply grid-cols-3;
    }

    @screen 2xl {
      @apply grid-cols-4 gap-x-6;
    }

    .service-container {
      @apply flex flex-col items-center justify-center border border-black rounded py-6 w-full
      max-w-xs h-full px-4;

      @screen md {
        @apply max-w-sm;
      }

      svg {
        @apply text-green text-5xl mb-4;
      }

      p.name {
        @apply text-black font-medium text-sm mb-5 text-center;
      }

      p.buy-status {
        @apply text-black font-bold text-sm mb-6 text-center;
      }

      p.valid {
        @apply text-black font-normal italic text-xs text-center;
      }
    }
  }

  .additional-services-container {
    @apply mt-16 w-full;

    p.title {
      @apply text-xl leading-normal font-bold text-black;
    }

    & > .services-container {
      @apply grid grid-cols-1 gap-y-9 mt-9 w-full place-items-center;

      @screen md {
        @apply grid-cols-2 gap-x-7;
      }

      @screen lg {
        @apply grid-cols-3;
      }

      @screen 2xl {
        @apply grid-cols-4 gap-x-6;
      }

      .service-container {
        @apply flex flex-col items-center justify-center border border-black rounded py-6 w-full
        max-w-xs h-full px-4;

        @screen md {
          @apply max-w-sm;
        }

        svg {
          @apply text-green text-5xl mb-4;
        }

        p.name {
          @apply text-black font-medium text-sm mb-5 text-center;
        }

        .info-container {
          @apply flex flex-row items-center mb-4;

          p.price {
            @apply text-black font-bold text-sm mr-6;
          }

          p.valid {
            @apply text-black font-normal text-xs italic;
          }
        }
      }
    }
  }
}
</style>
