<template>
  <div class="page-container account page-pad">
    <h1>{{ $t('account.my_account') }}</h1>
    <div class="content-container">
      <nav class="nav-container">
        <Button :to="{name: 'my_details'}" class="nav-element" primary>
          <font-awesome-icon :icon="['far','user-circle']" class="arrow"/>
          <span>{{ $t('account.nav.my_details') }}</span>
        </Button>
        <Button :to="{name: 'my_plan'}" class="nav-element" primary>
          <font-awesome-icon :icon="['far','file']" class="arrow"/>
          <span>{{ $t('account.nav.my_plan') }}</span>
        </Button>
        <Button :to="{name: 'my_services'}" class="nav-element" primary>
          <font-awesome-icon :icon="['far','concierge-bell']" class="arrow"/>
          <span>{{ $t('account.nav.my_services') }}</span>
        </Button>
        <Button :to="{name: 'my_applications'}" class="nav-element" primary>
          <font-awesome-icon :icon="['far','scroll']" class="arrow"/>
          <span>{{ $t('account.nav.my_applications') }}</span>
        </Button>
        <Button :to="{name: 'my_documents'}" class="nav-element" primary>
          <font-awesome-icon :icon="['far','file-alt']" class="arrow"/>
          <span>{{ $t('account.nav.my_documents') }}</span>
        </Button>
<!--        <Button :to="{name: 'payment_methods'}" class="nav-element" primary>-->
<!--          <font-awesome-icon :icon="['far','credit-card']" class="arrow"/>-->
<!--          <span>{{ $t('account.nav.payment_methods') }}</span>-->
<!--        </Button>-->
<!--        <Button :to="{name: 'payments_history'}" class="nav-element" primary>-->
<!--          <font-awesome-icon :icon="['far','history']" class="arrow"/>-->
<!--          <span>{{ $t('account.nav.payments_history') }}</span>-->
<!--        </Button>-->
<!--        <Button :to="{name: 'email_notifications'}" class="nav-element" primary>-->
<!--          <font-awesome-icon :icon="['far','envelope-open-text']" class="arrow"/>-->
<!--          <span>{{ $t('account.nav.email_notifications') }}</span>-->
<!--        </Button>-->
        <Button :to="{name: 'account_settings'}" class="nav-element" primary>
          <font-awesome-icon :icon="['far','cog']" class="arrow"/>
          <span>{{ $t('account.nav.account_settings') }}</span>
        </Button>
      </nav>
      <main>
        <router-view/>
      </main>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';

export default {
  name: 'Account',
  components: { Button },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col py-14;

  & > h1 {
    @apply text-black text-4xl font-bold leading-normal mb-10;
  }

  & > .content-container {
    @apply flex flex-col;

    @screen 2xl {
      @apply flex-row items-start;
    }

    & > .nav-container {
      @apply flex flex-row items-center mb-12 overflow-x-auto w-full -mr-4 pb-2;

      @screen md {
        @apply -mr-5;
      }

      @screen lg {
        @apply -mr-12;
      }

      @screen 2xl {
        @apply flex-col items-start mb-0 overflow-x-hidden mr-14 mt-10 w-auto;
      }

      .nav-element {
        @apply flex-none flex flex-row items-center mr-1 bg-transparent border-transparent;

        @screen 2xl {
          @apply mr-0 mb-4 w-full justify-start;
        }

        &:last-of-type {
          @apply mr-0;
        }

        &:hover, &:focus {
          & > span {
            @apply text-green;
          }

          & > svg {
            @apply text-green;
          }
        }

        & > span {
          @apply text-black text-sm font-medium flex-none;

          @screen 2xl {
            @apply text-base;
          }
        }

        & > svg {
          @apply text-black text-xl mr-3 flex-none;

          @screen 2xl {
            @apply text-xl w-5 mr-4;
          }
        }

        &.router-link-active {
          @apply bg-green-dimmed border-green-dimmed;

          & > span {
            @apply text-green;
          }

          & > svg {
            @apply text-green;
          }
        }
      }
    }

    & > main {
      @apply flex-1;
    }
  }
}
</style>

<style lang="scss">
.page-container.account {
  .inner-page-container {
    @apply bg-white px-6 py-10 flex flex-col items-start rounded-lg shadow-md;

    & > .page-title, & > header .page-title {
      @apply text-black font-bold text-3xl leading-normal;
    }
  }
}
</style>
