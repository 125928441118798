<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_4.title') }}</p>
      <Form class="form-container" @submit="submit">
        <div class="input-row-container">
          <div class="text-container">
            <p>{{ $t('application.step_4.authorized_shares_company') }}</p>
            <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('application.step_4.authorized_shares_company_tt')"
                               :icon="['fas','question-circle']"
                               class="info"/>
          </div>
          <FormInputText v-model="authorizedShares" :has-error="$v.authorizedShares.$error" @blur="saveData"
                         :placeholder="$t('value')" class="form-input" identifier="authorized_shares_company"
                         type="number" :disabled="$route.path.includes('view')">
            <template v-slot:errors>
              <p v-if="!$v.authorizedShares.required">
                {{ $t('application.step_4.validation.authorized_shares_company_required') }}
              </p>
              <p v-else-if="!$v.authorizedShares.numeric">
                {{ $t('application.step_4.validation.authorized_shares_company_numeric') }}
              </p>
              <p v-else-if="!$v.authorizedShares.minValue">
                {{ $t('application.step_4.validation.authorized_shares_company_numeric') }}
              </p>
            </template>
          </FormInputText>
        </div>
        <div class="input-row-container">
          <div class="text-container">
            <p>{{ $t('application.step_4.issued_shares_company') }}</p>
            <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('application.step_4.issued_shares_company_tt')"
                               :icon="['fas','question-circle']"
                               class="info"/>
          </div>
          <FormInputText v-model="issuedShares" :has-error="$v.issuedShares.$error" @blur="saveData"
                         :placeholder="$t('value')" class="form-input" identifier="issued_shares_company"
                         type="number" :disabled="$route.path.includes('view')">
            <template v-slot:errors>
              <p v-if="!$v.issuedShares.required">
                {{ $t('application.step_4.validation.issued_shares_company_required') }}
              </p>
              <p v-else-if="!$v.issuedShares.numeric">
                {{ $t('application.step_4.validation.issued_shares_company_numeric') }}
              </p>
              <p v-else-if="!$v.issuedShares.minValue">
                {{ $t('application.step_4.validation.issued_shares_company_numeric') }}
              </p>
            </template>
          </FormInputText>
        </div>
        <div class="input-row-container">
          <div class="text-container">
            <p>{{ $t('application.step_4.percentage_capital_paid') }}</p>
            <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('application.step_4.percentage_capital_paid_tt')"
                               :icon="['fas','question-circle']"
                               class="info"/>
          </div>
          <FormInputText v-model="percentageCapitalPaid" :has-error="$v.percentageCapitalPaid.$error" @blur="saveData"
                         :placeholder="$t('value')" class="form-input" identifier="percentage_capital_paid"
                         type="number" :disabled="$route.path.includes('view')">
            <template v-slot:errors>
              <p v-if="!$v.percentageCapitalPaid.required">
                {{ $t('application.step_4.validation.percentage_capital_paid_required') }}
              </p>
              <p v-else-if="!$v.percentageCapitalPaid.numeric">
                {{ $t('application.step_4.validation.percentage_capital_paid_numeric') }}
              </p>
              <p v-else-if="!$v.percentageCapitalPaid.minValue">
                {{ $t('application.step_4.validation.percentage_capital_paid_numeric') }}
              </p>
            </template>
          </FormInputText>
        </div>
        <div class="input-row-container">
          <div class="text-container">
            <p>{{ $t('application.step_4.currency_shares_issued') }}</p>
            <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('application.step_4.currency_shares_issued_tt')"
                               :icon="['fas','question-circle']"
                               class="info"/>
          </div>

          <FormInputSelect
            v-model="sharesCurrency" :allow-empty="true"
            :has-error="$v.sharesCurrency.$error"
            :multiple="false" :options="sharesCurrencyOptions"
            :placeholder="$t('currency')"
            class="form-input"
            display-label="name" @input="saveData"
            identifier="currency_shares_issued"
            track-by="value" :disabled="$route.path.includes('view')"
          >
            <template v-slot:errors>
              <p v-if="!$v.sharesCurrency.required">
                {{ $t('application.step_4.validation.currency_shares_issued_required') }}
              </p>
            </template>
          </FormInputSelect>
        </div>
        <div class="input-row-container">
          <div class="text-container">
            <p>{{ $t('application.step_4.nominal_value_share') }}</p>
            <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('application.step_4.nominal_value_share_tt')"
                               :icon="['fas','question-circle']"
                               class="info"/>
          </div>
          <FormInputText v-model="valueEachShare" :has-error="$v.valueEachShare.$error" @blur="saveData"
                         :placeholder="$t('value')" class="form-input" identifier="nominal_value_share"
                         type="number" :disabled="$route.path.includes('view')">
            <template v-slot:errors>
              <p v-if="!$v.valueEachShare.required">
                {{ $t('application.step_4.validation.nominal_value_share_required') }}
              </p>
              <p v-else-if="!$v.valueEachShare.numeric">
                {{ $t('application.step_4.validation.nominal_value_share_numeric') }}
              </p>
              <p v-else-if="!$v.valueEachShare.minValue">
                {{ $t('application.step_4.validation.nominal_value_share_numeric') }}
              </p>
            </template>
          </FormInputText>
        </div>
      </Form>
    </div>
    <StepInfo class="step-info-container">
      <p>{{ $t('application.step_4.info_1') }}</p>
      <p>{{ $t('application.step_4.info_2') }}</p>
      <p>{{ $t('application.step_4.info_3') }}</p>
    </StepInfo>
  </div>
</template>

<script>
import StepInfo from '@/components/application/StepInfo.vue';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { minValue, numeric, required } from 'vuelidate/lib/validators';
import FormInputSelect from '@/components/ui/FormInputSelect.vue';

export default {
  name: 'ApplicationStep4',
  components: {
    FormInputSelect, FormInputText, Form, StepInfo,
  },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      authorizedShares: null,
      issuedShares: null,
      percentageCapitalPaid: null,
      sharesCurrency: null,
      valueEachShare: null,
      sharesCurrencyOptions: [
        { name: 'EUR', value: 'eur' },
      ],
    };
  },
  validations: {
    authorizedShares: {
      required, numeric, minValue: minValue(0),
    },
    issuedShares: {
      required, numeric, minValue: minValue(0),
    },
    percentageCapitalPaid: {
      required, numeric, minValue: minValue(0),
    },
    sharesCurrency: {
      required,
    },
    valueEachShare: {
      required, numeric, minValue: minValue(0),
    },
  },
  methods: {
    submit() {
      // TODO
    },

    async populate() {
      if (this.currentData) {
        this.authorizedShares = this.currentData.authorizedShares;
        this.issuedShares = this.currentData.issuedShares;
        this.percentageCapitalPaid = this.currentData.percentageCapitalPaid;
        this.sharesCurrency = this.currentData.sharesCurrency;
        this.valueEachShare = this.currentData.valueEachShare;
      }
    },

    saveData() {
      const tempData = {
        authorizedShares: this.authorizedShares,
        issuedShares: this.issuedShares,
        percentageCapitalPaid: this.percentageCapitalPaid,
        sharesCurrency: this.sharesCurrency,
        valueEachShare: this.valueEachShare,
      };

      const currentData = tempData;
      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },

    getData() {
      return {
        authorizedShares: this.authorizedShares,
        issuedShares: this.issuedShares,
        percentageCapitalPaid: this.percentageCapitalPaid,
        sharesCurrency: this.sharesCurrency,
        valueEachShare: this.valueEachShare,
      };
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
  },
  async mounted() {
    await this.populate();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply flex flex-col items-start w-full max-w-xl;

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      @apply w-full;

      .input-row-container {
        @apply flex flex-col items-start mb-6;

        @screen md {
          @apply flex-row items-center mb-12;
        }

        .text-container {
          @apply flex flex-row items-center mb-4;

          @screen md {
            @apply mb-0 flex-1;
          }

          & > p {
            @apply text-black text-xs leading-normal;

            @screen md {
              @apply text-sm leading-normal;
            }
          }

          & > svg.info {
            @apply text-black text-sm ml-3;
          }
        }

        .form-input {
          @apply w-32 mb-0;
        }
      }
    }
  }

  .step-info-container {
    @apply w-full mt-9 max-w-sm;

    @screen md {
      @apply mt-16;
    }

    @screen 2xl {
      @apply max-w-lg;
    }
  }
}
</style>
