<template>
  <div class="layout-container web">
    <Navbar/>
    <main>
      <slot/>
    </main>
    <div class="bottom-area">
      <LetsDoIt v-if="!$store.getters.isAuthed"/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue';
import LetsDoIt from './LetsDoIt.vue';
import Footer from '../Footer.vue';

export default {
  name: 'Layout',
  components: { Footer, LetsDoIt, Navbar },
};
</script>

<style lang="scss">
.layout-container.web {
  .page-pad {
    @apply px-4;

    @screen md {
      @apply px-5;
    }

    @screen lg {
      @apply px-12;
    }

    @screen 2xl {
      @apply px-64;
    }
  }

  .page-container {
    @apply flex flex-col;

    .section-title {
      @apply text-black text-3xl leading-normal font-bold text-center mb-20;

      @screen md {
        @apply mb-16;
      }

      @screen 2xl {
        @apply text-5xl leading-normal mb-20;
      }
    }
  }
}
</style>
