<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.my_details') }}</h2>
    <h3 class="subtitle">{{ $t('account.details.personal_information') }}</h3>

    <Form class="form-container" @submit="saveDetails">
      <div class="form-inputs-container">
        <FormInputText v-model="details.name" :has-error="$v.details.name.$error" :label="`${$t('account.details.name')}`"
                       :placeholder="$t('account.details.name')"
                       identifier="name"
                       type="text">
          <template v-slot:errors>
            <p v-if="!$v.details.name.required">
              {{ $t('account.details.validation.name_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="details.dob" :has-error="$v.details.dob.$error" :label="`${$t('account.details.birth_date')}`"
                       :placeholder="$t('account.details.birth_date')"
                       identifier="birth_date"
                       type="date">
          <template v-slot:errors>
            <p v-if="!$v.details.dob.required">
              {{ $t('account.details.validation.birth_date_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="details.phone" :has-error="$v.details.phone.$error" :label="`${$t('account.details.phone_number')}`"
                       :placeholder="$t('account.details.phone_number')"
                       identifier="phone_number"
                       type="text">
          <template v-slot:errors>
            <p v-if="!$v.details.phone.required">
              {{ $t('account.details.validation.phone_number_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="details.email" :has-error="$v.details.email.$error" :label="`${$t('account.details.email_address')}`"
                       :placeholder="$t('account.details.email_address')"
                       identifier="email"
                       type="text">
          <template v-slot:errors>
            <p v-if="!$v.details.email.required">
              {{ $t('account.details.validation.email_address_required') }}
            </p>
            <p v-else-if="!$v.details.email.email">
              {{ $t('account.details.validation.email_address_invalid') }}
            </p>
          </template>
        </FormInputText>
      </div>
      <Button class="button submit" secondary type="submit" :loading="is_saving" :disabled="is_saving">
        <span>{{ $t('save') }}</span>
      </Button>
    </Form>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import Button from '@/components/ui/Button.vue';

export default {
  name: 'MyDetails',
  components: {
    Button, FormInputText, Form
  },
  data() {
    return {
      details: {
        name: null,
        dob: null,
        phone: null,
        email: null,
      },
      original: null,
      is_loading: false,
      is_saving: false
    };
  },
  validations: {
    details: {
      name: { required },
      dob: { required },
      phone: { required },
      email: { required, email },
    },
  },
  methods: {
    saveDetails() {
      this.$v.details.$touch();
      if (this.$v.details.$anyError) return;

      const payload = {};

      if (this.details.name !== this.$store.getters.user.attributes.name) {
        payload.name = this.details.name;
      }

      if (this.details.dob !== this.$store.getters.user.attributes.dob) {
        payload.dob = this.details.dob;
      }

      if (this.details.phone !== this.$store.getters.user.attributes.phone) {
        payload.phone = this.details.phone;
      }

      if (this.details.email !== this.$store.getters.user.attributes.email) {
        payload.email = this.details.email;
      }

      if (JSON.stringify(this.details) !== JSON.stringify(this.original)) {
        this.is_saving = true;

        this.$axios.patch('profile', payload).then(async ({ data }) => {
          await this.$store.dispatch('getUser');
          this.populate();
          this.is_saving = false;
        }).catch((e) => {
          this.is_saving = false;
        });
      }
    },
    populate() {
      this.is_loading = true;

      this.details = {
        name: this.$store.getters.user.attributes.name,
        dob: this.$store.getters.user.attributes.dob,
        phone: this.$store.getters.user.attributes.phone,
        email: this.$store.getters.user.attributes.email
      };

      this.original = { ...this.details };

      this.is_loading = false;
    }
  },
  mounted() {
    this.populate();
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.my_details'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  & > .subtitle {
    @apply mt-8 text-black font-bold text-lg leading-normal border-b border-grey-light pb-4
    w-full;
  }

  & > .form-container {
    @apply mt-10 flex flex-col w-full;

    .form-inputs-container {
      @apply flex flex-col;

      @screen md {
        @apply grid grid-cols-2 gap-x-6 gap-y-2;
      }

      @screen lg {
        @apply gap-x-8;
      }
    }

    .button.submit {
      @apply py-3;

      & > span {
        @apply text-sml;
      }

      @screen md {
        @apply self-start mt-3 px-12;
      }
    }
  }
}
</style>
