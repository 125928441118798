import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import axios from 'axios';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'legal-wiz',
  storage: localStorage,
  reducer: (state) => ({
    token: state.token,
    cart: state.cart,
    user: state.user,
    cookies: state.cookies
  }),
});

export default new Vuex.Store({
  state: {
    token: null,
    cart: null,
    user: null,
    cookies: null,
  },
  getters: {
    isAuthed: (state) => state.token !== null,
    token: (state) => state.token,
    cart: (state) => state.cart,
    user: (state) => state.user,
    cookies: (state) => state.cookies
  },
  mutations: {
    token(state, token) {
      state.token = token;
    },
    cart(state, cart) {
      state.cart = cart;
    },
    user(state, user) {
      state.user = user;
    },
    cookies(state, decision) {
      state.cookies = decision;
    }
  },
  actions: {
    async getUser({ commit }) {
      await axios.get('profile').then(({ data }) => {
        commit('user', data.data);
      });
    },
    async createCart({ commit, state }) {
      await axios.post('carts', { customer_id: state.user.id })
        .then(({ data }) => {
          commit('cart', data.data.id);
        });
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
