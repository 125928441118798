<template>
  <div>
    <ApplicationStep5Part1 v-show="active_part === 1" ref="part-1" :currentData="currentData" :application_id="application_id"/>
    <ApplicationStep5Part2 v-show="active_part === 2" ref="part-2" :wizardData="wizardData" :currentData="currentData" :application_id="application_id"/>
  </div>
</template>

<script>
import ApplicationStep5Part1 from '@/components/application/ApplicationStep5Part1.vue';
import ApplicationStep5Part2 from '@/components/application/ApplicationStep5Part2.vue';

export default {
  name: 'ApplicationStep5',
  components: { ApplicationStep5Part1, ApplicationStep5Part2 },
  props: {
    wizardData: {
      type: Object,
    },
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      active_part: 1,
      parts: [1, 2],
    };
  },
  methods: {
    getData() {
      const part = this.$refs[`part-${this.active_part}`];
      return part.getData();
    },
    isValid() {
      const part = this.$refs[`part-${this.active_part}`];
      return part.isValid();
    },
    isMultipart() {
      return true;
    },
    canGoNext() {
      return this.active_part < this.numParts;
    },
    canGoBack() {
      return this.active_part > 1;
    },
    goBack() {
      if (this.active_part > 1) this.active_part -= 1;
    },
    goNext() {
      if (this.active_part < this.numParts) this.active_part += 1;
    },
  },
  computed: {
    numParts() {
      return this.parts.length;
    },
  },
  watch: {
    active_part(part) {
      const component = this.$refs[`part-${part}`];

      if (component.becameActiveStep) {
        this.$nextTick(() => {
          component.becameActiveStep();
        });
      }
    },
  },
};
</script>
