<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <header>
        <p>{{ $t('application.step_5.shareholder_x_of_n', { x: active_index + 1, n: numShareHolders }) }}</p>
        <div class="navigation-container">
          <button :disabled="active_index <= 0" type="button" @click="active_index -= 1">
            <font-awesome-icon :icon="['far','chevron-left']"/>
          </button>
          <button :disabled="active_index >= numShareHolders-1" type="button" @click="active_index += 1">
            <font-awesome-icon :icon="['far','chevron-right']"/>
          </button>
        </div>
      </header>
      <Form v-if="active_index !== null" class="form-container" @submit="submit">
        <section>
          <div class="input name">
            <FormInputText v-model="$v.shareHolders.$model[active_index].name"
                           :has-error="$v.shareHolders.$each[active_index].name.$error"
                           :label="`${$t('application.step_5.full_name')}`"
                           :placeholder="`${$t('application.step_5.full_name')}`"
                           identifier="name" @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].name.required">
                  {{ $t('application.step_5.validation.full_name_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input nationality">
            <FormInputText
              v-model="$v.shareHolders.$model[active_index].nationality"
              :has-error="$v.shareHolders.$each[active_index].nationality.$error"
              :label="$t('application.step_5.nationality')"
              :placeholder="$t('application.step_5.nationality')"
              @blur="saveData" :disabled="$route.path.includes('view')"
            >
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].nationality.required">
                  {{ $t('application.step_5.validation.nationality_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input dob">
            <FormInputText v-model="$v.shareHolders.$model[active_index].dob"
                           :has-error="$v.shareHolders.$each[active_index].dob.$error"
                           :label="`${$t('application.step_5.date_of_birth')}`"
                           :placeholder="`${$t('application.step_5.date_of_birth')}`"
                           identifier="dob" @blur="saveData"
                           type="date" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].dob.required">
                  {{ $t('application.step_5.validation.date_of_birth_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input email">
            <FormInputText v-model="$v.shareHolders.$model[active_index].email"
                           :has-error="$v.shareHolders.$each[active_index].email.$error"
                           :label="`${$t('application.step_5.email')}`"
                           :placeholder="`${$t('application.step_5.email')}`"
                           identifier="email" @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].email.required">
                  {{ $t('application.step_5.validation.email_required') }}
                </p>
                <p v-else-if="!$v.shareHolders.$each[active_index].email.email">
                  {{ $t('application.step_5.validation.email_invalid') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input shareholding">
            <FormInputText v-model="$v.shareHolders.$model[active_index].shareholding"
                           :has-error="$v.shareHolders.$each[active_index].shareholding.$error"
                           :label="`${$t('application.step_5.percentage_shareholding')}`"
                           :placeholder="`${$t('application.step_5.percentage_shareholding')}`"
                           identifier="shareholding" @blur="saveData"
                           type="number" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].shareholding.required">
                  {{ $t('application.step_5.validation.percentage_shareholding_required') }}
                </p>
                <p v-else-if="!$v.shareHolders.$each[active_index].shareholding.numeric">
                  {{ $t('application.step_5.validation.percentage_shareholding_numeric') }}
                </p>
                <p v-else-if="!$v.shareHolders.$each[active_index].shareholding.minValue">
                  {{ $t('application.step_5.validation.percentage_shareholding_numeric') }}
                </p>
                <p v-else-if="!$v.shareHolders.$each[active_index].shareholding.maxValue">
                  {{ $t('application.step_5.validation.percentage_shareholding_numeric') }}
                </p>
              </template>
            </FormInputText>
          </div>
        </section>
        <section>
          <div class="input residence">
            <FormInputText v-model="$v.shareHolders.$model[active_index].address.residence"
                           :has-error="$v.shareHolders.$each[active_index].address.residence.$error"
                           :label="$t('application.step_5.building_number_name')"
                           :placeholder="$t('application.step_5.building_number_name')"
                           identifier="residence" @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].address.residence.required">
                  {{ $t('application.step_5.validation.building_number_name_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input street">
            <FormInputText v-model="$v.shareHolders.$model[active_index].address.street"
                           :has-error="$v.shareHolders.$each[active_index].address.street.$error"
                           :label="`${$t('application.step_5.street_name')}`"
                           :placeholder="`${$t('application.step_5.street_name')}`"
                           identifier="street"  @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].address.street.required">
                  {{ $t('application.step_5.validation.street_name_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input locality">
            <FormInputText
              v-model="$v.shareHolders.$model[active_index].address.locality"
              :has-error="$v.shareHolders.$each[active_index].address.locality.$error"
              :label="$t('application.step_5.locality_city')"
              :placeholder="$t('application.step_5.locality_city')"
              @blur="saveData" :disabled="$route.path.includes('view')"
            >
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].address.locality.required">
                  {{ $t('application.step_5.validation.locality_city_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input post_code">
            <FormInputText v-model="$v.shareHolders.$model[active_index].address.post_code"
                           :has-error="$v.shareHolders.$each[active_index].address.post_code.$error"
                           :label="$t('application.step_5.post_code')"
                           :placeholder="$t('application.step_5.post_code')"
                           identifier="post_code" @blur="saveData"
                           type="text" :disabled="$route.path.includes('view')">
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].address.post_code.required">
                  {{ $t('application.step_5.validation.post_code_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <div class="input country">
            <FormInputText
              v-model="$v.shareHolders.$model[active_index].address.country"
              :has-error="$v.shareHolders.$each[active_index].address.country.$error"
              :label="$t('application.step_5.country')"
              :placeholder="$t('application.step_5.country')"
              @blur="saveData" :disabled="$route.path.includes('view')"
            >
              <template v-slot:errors>
                <p v-if="!$v.shareHolders.$each[active_index].address.country.required">
                  {{ $t('application.step_5.validation.country_required') }}
                </p>
              </template>
            </FormInputText>
          </div>
        </section>
      </Form>
    </div>
  </div>
</template>

<script>
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import {
  email, maxValue, minValue, numeric, required,
} from 'vuelidate/lib/validators';

export default {
  name: 'ApplicationStep5Part2',
  components: { FormInputText, Form },
  props: {
    wizardData: {
      type: Object,
    },
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      active_index: null,
      numShareHolders: 0,
      shareHolders: [],
    };
  },
  validations: {
    shareHolders: {
      $each: {
        name: {
          required,
        },
        nationality: {
          required,
        },
        dob: {
          required,
        },
        email: {
          required,
          email,
        },
        shareholding: {
          required,
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        address: {
          residence: {
            required,
          },
          street: {
            required,
          },
          locality: {
            required,
          },
          post_code: {
            required,
          },
          country: {
            required,
          },
        },
      },
    },
  },
  methods: {
    submit() {
      return false;
    },
    getData() {
      return {
        shareHolders: this.shareHolders,
      };
    },

    populate() {
      if (this.currentData.shareHolders.length) {
        for (let i = 0; i < this.currentData.shareHolders.length; i++) {
          this.shareHolders[i].name = this.currentData.shareHolders[i].name;
          this.shareHolders[i].nationality = this.currentData.shareHolders[i].nationality;
          this.shareHolders[i].email = this.currentData.shareHolders[i].email;
          this.shareHolders[i].shareholding = this.currentData.shareHolders[i].shareholding;
          this.shareHolders[i].dob = this.currentData.shareHolders[i].dob;
          this.shareHolders[i].address.country = this.currentData.shareHolders[i].address.country;
          this.shareHolders[i].address.locality = this.currentData.shareHolders[i].address.locality;
          this.shareHolders[i].address.post_code = this.currentData.shareHolders[i].address.post_code;
          this.shareHolders[i].address.residence = this.currentData.shareHolders[i].address.residence;
          this.shareHolders[i].address.street = this.currentData.shareHolders[i].address.street;
        }
      }
    },
    saveData() {
      const tempData = {
        shareHolders: this.shareHolders,
      };

      const currentData = tempData;
      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },

    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
    becameActiveStep() {
      console.log('here');
      if (+this.wizardData.numShareHolders !== +this.numShareHolders) {
        this.numShareHolders = +this.wizardData.numShareHolders;
        this.shareHolders = [];

        for (let x = 0; x < this.numShareHolders; x++) {
          this.shareHolders.push({
            name: null,
            nationality: null,
            dob: null,
            email: null,
            shareholding: null,
            address: {
              residence: null,
              street: null,
              locality: null,
              post_code: null,
              country: null,
            },
          });
        }

        this.active_index = this.numShareHolders > 0 ? 0 : null;
        this.populate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply flex flex-col items-start w-full max-w-3xl;

    @screen 2xl {
      @apply max-w-5xl;
    }

    & > header {
      @apply w-full flex flex-row items-center justify-between mb-9;

      @screen md {
        @apply mb-10;
      }

      & > p {
        @apply text-black font-bold text-base leading-normal;

        @screen md {
          @apply text-lg leading-normal;
        }
      }

      .navigation-container {
        @apply flex flex-row items-center gap-x-2;

        & > button {
          @apply rounded-full border-2 border-green h-8 w-8 flex items-center justify-center;

          svg {
            @apply text-sm text-black;
          }

          &:active, &:focus {
            @apply outline-none;
          }

          &:disabled {
            @apply opacity-50;
          }
        }
      }
    }

    & > .form-container {
      @apply w-full grid grid-cols-1;

      @screen lg {
        @apply grid-cols-2 gap-x-18;
      }

      & > section {
        @apply w-full grid grid-cols-2 gap-x-8;

        @screen md {
          @apply gap-x-10;
        }

        & > .input {
          &.name, &.email, &.residence, &.street {
            @apply col-span-2;
          }
        }
      }
    }
  }
}
</style>
