<template>
  <div class="page-container page-pad">
    <div v-if="sent" class="sent-container">
      <font-awesome-icon :icon="['far','check-circle']" class="arrow"/>
      <h1>{{ $t('auth.registration_email_sent') }}</h1>
    </div>
    <div v-else class="sign-up-invite-container">
      <h1>{{ $t('auth.sign_up_title') }}</h1>
      <Form class="form-container" @submit="submit">
        <FormGroupTwo>
          <FormInputText v-model="details.name"
                         :has-error="$v.details.name.$error"
                         :label="`${$t('auth.full_name')}*`"
                         :placeholder="$t('auth.enter_your_full_name')" identifier="name"
                         type="text">
            <template v-slot:errors>
              <p v-if="!$v.details.name.required">
                {{ $t('auth.validation.name_required') }}
              </p>
            </template>
          </FormInputText>
          <FormInputText v-model="details.email"
                         :has-error="$v.details.email.$error || register_error !== null"
                         :label="`${$t('auth.email_address')}*`"
                         :placeholder="$t('auth.enter_your_email_address')"
                         identifier="email"
                         type="text">
            <template v-slot:errors>
              <p v-if="!$v.details.email.required">
                {{ $t('auth.validation.email_required') }}
              </p>
              <p v-else-if="!$v.details.email.email">
                {{ $t('auth.validation.email_invalid') }}
              </p>
              <p v-else-if="$v.details.email.required && $v.details.email.email && register_error">
                {{ register_error }}
              </p>
            </template>
          </FormInputText>
          <FormInputText v-model="details.phone"
                         :has-error="$v.details.phone.$error"
                         :label="`${$t('auth.phone_number')}*`"
                         :placeholder="$t('auth.enter_your_phone_number')" identifier="phone-number"
                         type="text">
            <template v-slot:errors>
              <p v-if="!$v.details.phone.required">
                {{ $t('auth.validation.phone_number_required') }}
              </p>
            </template>
          </FormInputText>
          <FormInputText v-model="details.dob" :has-error="$v.details.dob.$error"
                         :label="`${$t('account.details.birth_date')}`"
                         :placeholder="$t('account.details.birth_date')"
                         identifier="birth_date"
                         type="date">
            <template v-slot:errors>
              <p v-if="!$v.details.dob.required">
                {{ $t('account.details.validation.birth_date_required') }}
              </p>
            </template>
          </FormInputText>
          <Button class="button submit" secondary type="submit" :loading="is_loading">
            <span>{{ $t('nav.sign_up') }}</span>
          </Button>
        </FormGroupTwo>
      </Form>
    </div>
    <img class="character" src="/images/auth-character.svg">
  </div>
</template>

<script>
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import {
  email, required, requiredIf, sameAs,
} from 'vuelidate/lib/validators';
import Button from '../../components/ui/Button.vue';
import FormGroupTwo from '../../components/ui/FormGroupTwo.vue';

export default {
  name: 'SignUpInvite',
  components: {
    FormGroupTwo, FormInputText, Form, Button
  },
  data() {
    return {
      details: {
        name: null,
        email: null,
        phone: null,
        dob: null
      },
      sent: false,
      register_error: null,
      is_loading: false,
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required
      },
      dob: {
        required
      }
    },
  },
  methods: {
    submit() {
      this.$v.details.$touch();
      if (this.$v.details.$anyError || this.is_loading) return;

      this.is_loading = true;

      this.$axios.post('auth/invite', this.details).then(({ data }) => {
        this.register_error = null;
        this.sent = true;
        this.is_loading = false;
      }).catch((e) => {
        if (e.response.data.errors && e.response.data.errors.email && e.response.data.errors.email[0]) {
          const error = e.response.data.errors.email[0];
          this.register_error = error;
        }

        this.is_loading = false;
      });
    },
  },
  metaInfo() {
    return {
      title: this.$t('nav.sign_up'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col justify-center;

  .sign-up-invite-container {
    @apply p-6 bg-white w-full max-w-lg mx-auto z-10 rounded-md;

    @screen md {
      @apply max-w-none;
      width: 30rem;
    }

    @screen 2xl {
      @apply p-8;
      width: 36rem;
    }

    h1 {
      @apply text-black text-lg leading-normal font-bold mb-8 text-center;

      @screen 2xl {
        @apply text-2xl mb-10;
      }
    }

    .form-container {
      @apply flex flex-col items-start;

      .button.submit {
        @apply w-full py-3;
      }
    }
  }

  img.character {
    @apply hidden z-0 absolute w-auto;

    @screen md {
      @apply block bottom-2;

      left: 50%;
      margin-left: -23rem;
      height: 32rem;
    }

    @screen 2xl {
      margin-left: -26rem;
    }
  }

  .sent-container {
    @apply px-6 bg-white w-full max-w-sm mx-auto z-10 flex flex-col items-center py-14 rounded-md;

    svg {
      @apply text-green text-5xl mb-5;
    }

    h1 {
      @apply text-black font-bold text-xl leading-normal;
    }
  }
}
</style>
