<template>
  <div
    :class="{ used, error: hasError }"
    class="input-group form-input-select-container"
  >
    <label v-if="label" :for="identifier">{{ label }}</label>
    <multiselect
      :id="identifier"
      ref="multiselect"
      v-bind="labelProps"
      :allow-empty="allowEmpty"
      :close-on-select="closeOnSelect"
      :disabled="disabled"
      :loading="loading"
      :multiple="multiple"
      :options="options"
      :placeholder="placeholder"
      :preselect-first="preselectFirst"
      :showLabels="false"
      :showNoResults="true"
      :tagPlaceholder="$t('form.press_enter_to_add_to_list')"
      :taggable="taggable"
      :track-by="trackBy"
      :value="value"
      class="multiselect"
      openDirection="below"
      @blur="(e) => $emit('blur', e.target.value)"
      @close="() => $emit('close')"
      @input="(v) => $emit('input', v)"
      @open="() => $emit('open')"
      @remove="
        (removedOption, removeId) =>
          $emit('remove', { removedOption, removeId })
      "
      @select="
        (selectedOption, selectId) =>
          $emit('select', { selectedOption, selectId })
      "
      @tag="(newTag) => $emit('tag', newTag)"
      @updated="() => $emit('updated')"
    >
      <div slot="caret" slot-scope="{ toggle }" class="caret-container">
        <font-awesome-icon
          :icon="['far', 'chevron-down']"
          @mousedown.prevent.stop="toggle"
        />
      </div>
      <div slot="noResult" class="no-results">
        <p>{{ $t('form.no_results') }}</p>
      </div>
      <div slot="noOptions" class="no-options">
        <p>{{ $t('form.no_options_select') }}</p>
      </div>
    </multiselect>
    <FormErrors v-if="hasError">
      <slot name="errors"></slot>
    </FormErrors>
  </div>
</template>

<script>

import FormErrors from '@/components/ui/FormErrors.vue';

export default {
  name: 'FormInputSelect',
  components: { FormErrors },
  props: {
    value: {
      type: [String, Object, Array],
    },
    identifier: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    displayLabel: {
      type: String,
    },
    displayCustomLabel: {
      default: null,
    },
    trackBy: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    used() {
      if (this.value && this.value.constructor === Array) return this.value.length;

      return !!this.value;
    },
    labelProps() {
      if (this.displayCustomLabel) return { customLabel: this.displayCustomLabel };
      if (this.displayLabel) return { label: this.displayLabel };
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  @apply w-full flex flex-col items-start mb-6 relative;

  label {
    @apply text-xs text-black font-bold mb-1;

    @screen 2xl {
      @apply text-sm;
    }
  }
}
</style>

<style lang="scss">
.form-input-select-container {
  .multiselect {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
    border: 2px solid theme('colors.grey-light') !important;
    border-radius: theme('borderRadius.DEFAULT') !important;
    padding: 0 !important;
    background-color: theme('colors.white') !important;
    min-height: unset !important;
    z-index: unset !important;

    .caret-container {
      @apply absolute cursor-pointer;

      top: 0.37rem;
      right: 1rem;
      z-index: 2;

      svg {
        @apply cursor-pointer w-auto text-xxs text-black;

        transition: transform 0.1s;
      }
    }

    .no-results {
      p {
        @apply text-black text-xs;
      }
    }

    .no-options {
      p {
        @apply text-black text-xs;
      }
    }

    .multiselect__select {
      margin-top: 0 !important;
      padding: 0 !important;

      -webkit-transition: unset !important;
      -moz-transition: unset !important;
      -ms-transition: unset !important;
      -o-transition: unset !important;
      transition: unset !important;

      &:before {
        right: 0 !important;
        top: 40% !important;
        border-color: theme('colors.grey-darker') transparent transparent !important;
      }
    }

    .multiselect__tags {
      background: transparent !important;
      border: none !important;
      padding: theme('spacing.3') theme('spacing.4') 0.45rem !important;
      min-height: unset !important;

      .multiselect__placeholder {
        font-size: theme('fontSize.xs') !important;
      }

      .multiselect__input {
        color: theme('colors.black') !important;
        font-weight: normal !important;
        font-size: theme('fontSize.xs') !important;
        margin-top: -4px !important;

        &::-webkit-input-placeholder {
          color: theme('colors.grey-darker') !important;
          font-size: theme('fontSize.xs') !important;
        }

        &:-ms-input-placeholder {
          color: theme('colors.grey-darker') !important;
          font-size: theme('fontSize.xs') !important;
        }

        &::placeholder {
          color: theme('colors.grey-darker') !important;
          font-size: theme('fontSize.xs') !important;
        }
      }

      .multiselect__single {
        color: theme('colors.grey-darker') !important;
        font-size: theme('fontSize.xs') !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      .multiselect__tag {
        background-color: theme('colors.grey-light') !important;

        span {
          font-size: theme('fontSize.xxs') !important;
          color: theme('colors.black') !important;
        }

        i {
          color: theme('colors.black') !important;

          &:hover,
          &:focus,
          &:active {
            background-color: theme('colors.grey-light') !important;

            &::after {
              background-color: theme('colors.grey-light') !important;
            }
          }

          &::after {
            color: theme('colors.black') !important;
          }
        }
      }
    }

    .multiselect__content-wrapper {
      margin-bottom: -1px;
      border-radius: theme('borderRadius.DEFAULT') !important;
      padding: 0 !important;
      box-shadow: 0px 2px 10px #00000033 !important;
      margin-top: 0.25rem !important;
      width: 100% !important;
      max-width: 100% !important;
      border: none !important;
      z-index: 4 !important;

      .multiselect__option {
        @apply flex flex-row items-center;
        background-color: theme('colors.white') !important;
        padding: 0 theme('padding.3') theme('padding.0') !important;
        border-width: 0 !important;

        .checkbox {
          margin-right: theme('spacing.2') !important;

          &.on {
            @apply hidden;
          }
        }

        .checkmark {
          @apply ml-auto invisible text-black;
        }

        span {
          color: theme('colors.black') !important;
          font-weight: normal !important;
          font-size: theme('fontSize.xs') !important;
        }

        &::after {
          background-color: theme('colors.white') !important;
          color: theme('colors.black') !important;
        }

        &.multiselect__option--highlight {
          background-color: theme('colors.green-dimmed') !important;

          &::after {
            background-color: theme('colors.green-dimmed') !important;
          }
        }

        &.multiselect__option--selected {
          background-color: theme('colors.white') !important;

          span {
            color: theme('colors.black') !important;
            font-weight: bold !important;
          }

          .checkbox {
            &.off {
              @apply hidden;
            }

            &.on {
              @apply block;
            }
          }

          .checkmark {
            @apply visible;
          }
        }

        &.multiselect__option--disabled {
          span {
            color: theme('colors.grey') !important;
          }
        }

        &.multiselect__option--highlight.multiselect__option--disabled {
          background-color: theme('colors.grey-lightest') !important;
        }
      }
    }

    &.multiselect--active {
      width: 100% !important;
      max-width: 100% !important;

      .caret-container {
        svg {
          transform: rotate(180deg);
        }
      }

      .multiselect__select {
        &:before {
          right: 100% !important;
          left: 0 !important;
          top: 87% !important;
          bottom: 0 !important;
        }
      }
    }
  }
}
</style>
