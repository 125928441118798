<template>
  <div :class="{ error: hasError }" class="form-input-container">
    <label v-if="label" :for="identifier">{{ label }}</label>
    <div class="input-container">
      <input
        :id="identifier"
        v-mask="'#### #### #### ####'"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :value="value"
        class="input"
        spellcheck="false"
        type="text"
        @change="(e) => $emit('change', creditCardType)"
        @input="(e) => $emit('input', e.target.value)"
      />
      <font-awesome-icon
        v-if="creditCardIcon"
        :icon="creditCardIcon"
        class="card"
      />
    </div>
    <FormErrors v-if="hasError">
      <slot name="errors"></slot>
    </FormErrors>
  </div>
</template>

<script>
import FormErrors from './FormErrors.vue';

export default {
  name: 'FormInputCreditCard',
  components: { FormErrors },
  props: {
    value: {
      type: String,
    },
    identifier: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    spellcheck: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    creditCardIcon() {
      if (!this.value) return null;

      const card = this.value.replace(/\s/g, '');

      if (/^3[47][0-9]{13}$/.test(card)) {
        return ['fab', 'cc-amex'];
      }
      if (
        /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(
          card,
        )
      ) {
        return ['fab', 'cc-discover'];
      }
      if (/^5[1-5][0-9]{14}$/.test(card)) {
        return ['fab', 'cc-mastercard'];
      }
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(card)) {
        return ['fab', 'cc-visa'];
      }
      return null;
    },
    creditCardType() {
      if (!this.value) return null;

      const card = this.value.replace(/\s/g, '');

      if (/^3[47][0-9]{13}$/.test(card)) {
        return 'amex';
      }
      if (
        /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(
          card,
        )
      ) {
        return 'discover';
      }
      if (/^5[1-5][0-9]{14}$/.test(card)) {
        return 'mastercard';
      }
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(card)) {
        return 'visa';
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input-container {
  @apply w-full flex flex-col items-start mb-6;

  @screen 2xl {
    @apply mb-8;
  }

  label {
    @apply text-xs text-black font-bold mb-1;

    @screen 2xl {
      @apply text-sm;
    }
  }

  .input-container {
    @apply flex flex-row items-center bg-white border-2 border-grey rounded w-full;

    input {
      @apply text-black font-medium text-xs p-3 w-full appearance-none flex-1 border-0
      bg-transparent;

      @screen 2xl {
        @apply text-sm p-4;
      }

      &:disabled {
        @apply text-grey;
      }

      &:focus, &:active {
        @apply outline-none;
      }
    }

    svg.card {
      @apply text-grey-darker mr-4 text-2xl;
    }

    &:focus-within {
    }
  }

  &.error {
    .input-container {
      @apply border-negative;
    }
  }
}
</style>
