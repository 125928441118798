<template>
  <div :class="{'odd': !isEven, 'even': isEven}" class="process-step-container">
    <div class="image-container">
      <img v-if="image" :alt="title " :src="image" class="image"/>
    </div>
    <div class="info-container">
      <h2 class="title">{{ title }}</h2>
      <div class="checks-container">
        <div v-for="check in checks" :key="check" class="check-container">
          <font-awesome-icon :icon="['fas','check']"/>
          <p>
            {{ check }}
          </p>
        </div>
      </div>
      <div v-if="$slots.default" class="slot-container">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessStep',
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    checks: {
      type: Array,
      default: () => ([]),
    },
    isEven: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.process-step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row items-center;

    grid-column-gap: theme('spacing.20');
  }

  .image-container {
    @apply w-full mb-16;

    @screen lg {
      @apply mb-0 w-1/2;
    }

    .image {
      @apply max-w-full max-h-64 mx-auto;

      @screen md {
        @apply max-h-80;
      }

      @screen 2xl {
        @apply max-h-96;
      }
    }
  }

  .info-container {
    @apply w-auto mx-auto;

    @screen lg {
      @apply w-1/2;
    }

    h2.title {
      @apply text-black font-bold text-lg leading-normal mb-10;

      @screen md {
        @apply text-xl leading-normal;
      }

      @screen 2xl {
        @apply text-4xl leading-normal mb-16;
      }
    }

    .checks-container {
      @apply flex flex-col items-start;

      .check-container {
        @apply flex flex-row items-center mb-9;

        @screen 2xl {
          @apply mb-12;
        }

        &:last-of-type {
          @apply mb-0;
        }

        svg {
          @apply text-green text-sm mr-5;
        }

        p {
          @apply text-black text-xsm leading-normal;

          @screen md {
            @apply text-sml leading-normal;
          }
        }
      }
    }

    .slot-container {
      @apply mt-9 flex flex-col;

      @screen 2xl {
        @apply mt-12;
      }

    }
  }

  &.odd {
    .image-container {
      @apply order-1;
    }

    .info-container {
      @apply order-2;
    }
  }

  &.even {
    .image-container {
      @apply order-2;
    }

    .info-container {
      @apply order-1;
    }
  }
}
</style>
