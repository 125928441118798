<template>
  <div :class="{white: inner}" class="navbar-wrapper">
    <nav class="navbar-container page-pad">
      <router-link :to="{name: 'home'}" class="logo-container">
        <img alt="logo" class="on-green" src="/images/legalwiz_3_white.png"/>
        <img alt="logo" class="on-white" src="/images/legalwiz_1.png"/>
      </router-link>
      <section class="nav">
        <div class="info-container">
          <Button :to="{name: 'how-it-works'}" class="nav-element">
            <span>{{ $t('nav.how_it_works') }}</span>
          </Button>
          <Button :to="{name: 'pricing'}" class="nav-element">
            <span>{{ $t('nav.pricing') }}</span>
          </Button>
        </div>
        <div class="account-container">
          <template v-if="$store.getters.isAuthed">
            <router-link :to="{name:'my_details'}" class="button user">
              <font-awesome-icon :icon="['far','user-circle']" class="arrow"/>
            </router-link>
            <router-link v-if="$store.getters.cart" :to="{name:'cart'}" class="button user">
              <font-awesome-icon :icon="['far','shopping-cart']" class="arrow"/>
            </router-link>
            <Button v-if="$store.getters.user.relationships.servicePacks.data.length" :to="{name: 'application'}" class="nav-element start" secondary>
              <span>{{ $t('nav.start') }}</span>
            </Button>
            <Button :onclick="logOut" class="nav-element logout" secondary outline>
              <span>{{ $t('nav.log_out') }}</span>
            </Button>
          </template>
          <template v-else>
            <Button :to="{name: 'login'}" class="nav-element" outline secondary>
              <span>{{ $t('nav.login') }}</span>
            </Button>
            <Button :to="{name: 'sign-up-invite'}" class="nav-element" secondary>
              <span>{{ $t('nav.sign_up') }}</span>
            </Button>
          </template>
        </div>
        <div class="mobile-buttons-container">
          <router-link v-if="$store.getters.cart" :to="{name:'cart'}" class="button user">
            <font-awesome-icon :icon="['far','shopping-cart']" class="arrow"/>
          </router-link>
          <button class="mobile-menu" type="button" @click="mobileMenuOpened = !mobileMenuOpened">
            <font-awesome-icon :icon="['far','bars']"/>
          </Button>
        </div>
      </section>
    </nav>
    <nav v-if="mobileMenuOpened" class="mobile page-pad">
      <div class="info-container">
        <Button :onclick="mobileNavElementClicked" :to="{name: 'how-it-works'}" class="nav-element">
          <span>{{ $t('nav.how_it_works') }}</span>
        </Button>
        <Button :onclick="mobileNavElementClicked" :to="{name: 'pricing'}" class="nav-element">
          <span>{{ $t('nav.pricing') }}</span>
        </Button>
      </div>
      <div class="account-container">
        <template v-if="$store.getters.isAuthed">
          <router-link :to="{name:'my_details'}" class="button user"
                       @click="mobileNavElementClicked">
            <font-awesome-icon :icon="['far','user-circle']" class="arrow"/>
          </router-link>
          <Button :onclick="mobileNavElementClicked" :to="{name: 'application'}"
                  class="nav-element start" secondary>
            <span>{{ $t('nav.start') }}</span>
          </Button>
          <Button :onclick="logOut" :to="{name: 'login'}"
                  class="nav-element logout" primary outline>
            <span>{{ $t('nav.log_out') }}</span>
          </Button>
        </template>
        <template v-else>
          <Button :onclick="mobileNavElementClicked" :to="{name: 'login'}" class="nav-element login" outline
                  secondary>
            <span>{{ $t('nav.login') }}</span>
          </Button>
          <Button :onclick="mobileNavElementClicked" :to="{name: 'sign-up-invite'}" class="nav-element"
                  secondary>
            <span>{{ $t('nav.sign_up') }}</span>
          </Button>
        </template>
      </div>
    </nav>
  </div>
</template>

<script>
import Button from './ui/Button.vue';

export default {
  name: 'Navbar',
  components: { Button },
  props: {
    inner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobileMenuOpened: false,
    };
  },
  methods: {
    mobileNavElementClicked() {
      this.mobileMenuOpened = false;
    },
    logOut() {
      this.$store.commit('token', null);
      this.$router.push('login');
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  @apply relative;

  .button.user {
    @apply mr-6;

    svg {
      @apply text-black text-2xl;
    }

    &:hover, &:focus {
      svg {
        @apply text-pink;
      }
    }
  }

  .navbar-container {
    @apply w-full flex flex-row items-center bg-green py-4;

    @screen md {
      @apply py-5;
    }

    a.logo-container {
      .on-green{
        @apply block;
      }

      .on-white{
        @apply hidden;
      }

      img {
        @apply h-6 w-auto;

        @screen md {
          @apply h-8;
        }

        @screen 2xl {
          @apply h-10;
        }
      }
    }

    section.nav {
      @apply flex flex-row items-center ml-auto;

      .info-container {
        @apply hidden flex-row items-center;

        @screen md {
          @apply flex;
        }

        .nav-element {
          @apply mr-12;

          &:last-of-type {
            @apply mr-0;
          }
        }
      }

      .account-container {
        @apply hidden flex-row items-center ml-16;

        @screen md {
          @apply flex;
        }

        .nav-element {
          @apply mr-4;

          &:last-of-type {
            @apply mr-0;
          }

          &.logout {
            @apply ml-4;

            &:hover, &:focus {
              & > span {
                @apply text-black;
              }
            }
          }
        }
      }

      .mobile-buttons-container {
        @apply flex flex-row;

        @screen md {
          @apply hidden;
        }

        & > a {
          @apply mr-4;

          svg {
            @apply my-auto text-pink text-lg;
          }
        }

        button.mobile-menu {
          @apply ml-auto;

          svg {
            @apply text-pink text-base;
          }

          &:hover, &:focus {
            svg {
              @apply text-black
            }
          }

          &:focus, &:active {
            @apply outline-none;
          }
        }
      }
    }
  }

  nav.mobile {
    @apply w-full flex flex-col items-center bg-white py-8 shadow-md absolute;

    @screen md {
      @apply hidden;
    }

    .info-container {
      @apply flex flex-col items-center;

      .nav-element {
        @apply mb-12;

        & > span {
          &:hover, &:focus {
            @apply text-green;
          }
        }

      }
    }

    .account-container {
      @apply flex flex-row justify-center items-center;

      .nav-element {
        @apply mr-8;

        &:last-of-type {
          @apply mr-0;
        }

        &.login {
          & > span {
            @apply text-black;
          }
        }
      }

      .button.user {
        svg {
          @apply text-black;
        }

        &:hover, &:focus {
          svg {
            @apply text-green;
          }
        }
      }
    }
  }

  &.white {
    .navbar-container {
      @apply bg-white;

      a.logo-container {
        .on-green{
          @apply hidden;
        }

        .on-white{
          @apply block;
        }
      }

      section.nav {
        .info-container {
          .nav-element {
            & > span {
              @apply text-black;
            }

            &:hover, &:focus {
              & > span {
                @apply text-green;
              }
            }
          }
        }

        .account-container {
          .nav-element {
            & > span {
              @apply text-black;
            }

            &:hover, &:focus {
              & > span {
                @apply text-green;
              }
            }

            &.start {
              &:hover, &:focus {
                & > span {
                  @apply text-black;
                }
              }
            }

            &.logout {
              @apply ml-4;

              &:hover, &:focus {
                & > span {
                  @apply text-black;
                }
              }
            }
          }

          .button.user {
            svg {
              @apply text-black;
            }

            &:hover, &:focus {
              svg {
                @apply text-green;
              }
            }
          }
        }

        .mobile-buttons-container {
          & > a {
            svg {
              @apply text-black;
            }
          }

          button.mobile-menu {
            svg {
              @apply text-black;
            }

            &:hover, &:focus {
              svg {
                @apply text-green
              }
            }
          }
        }
      }
    }
  }
}
</style>
