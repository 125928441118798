<template>
  <div class="page-container">
    <Header :scroll="true" :subtitle="$t('web.home.subtitle')" :title="$t('web.home.title')"
            image="/images/home-illustration.svg">
      <Button class="button" secondary>
        <!-- TODO where to link to?-->
        <span>{{ $t('learn_more') }}</span>
      </Button>
    </Header>
    <section class="how-it-works page-pad">
      <h2 class="section-title">{{ $t('web.how_it_works.title') }}</h2>
      <div class="steps-container">
        <div class="connect-line"></div>
        <div v-for="step in howItWorksSteps" :key="step.title" class="step-container">
          <div class="icon-container">
            <font-awesome-icon :icon="[step.icon.type, step.icon.name]"/>
          </div>
          <div class="dot"></div>
          <div class="info-container">
            <h3>{{ step.title }}</h3>
            <p>{{ step.description }}</p>
          </div>
        </div>
      </div>
      <!--TODO link-->
      <Button class="button" secondary>
        <span>{{ $t('view_more') }}</span>
      </Button>
    </section>
    <section class="plans page-pad">
      <h2 class="section-title">{{ $t('web.home.plans_title') }}</h2>
      <div class="plans-container">
        <div v-for="plan in plans" :key="plan.name" :class="{best: plan.best}"
             class="plan-container">
          <h3>{{ plan.name }}</h3>
          <p class="price">€{{ plan.price }}</p>
          <p class="excluding">{{ $t('web.home.excluding_gov_incorp_fees') }}</p>
          <div class="actions-container">
            <!--TODO link-->
            <Button class="button" outline secondary :to="{name: 'login'}">
              <span>{{ $t('more_info') }}</span>
            </Button>
            <!--TODO link-->
            <Button class="button" secondary>
              <span>{{ $t('buy_now') }}</span>
            </Button>
          </div>
        </div>
      </div>
      <!--TODO link-->
      <Button class="button" secondary :to="{name: 'login'}">
        <span>{{ $t('view_more') }}</span>
      </Button>
    </section>
    <section class="benefits page-pad">
      <h2 class="section-title">{{ $t('web.home.benefits_title') }}</h2>
      <div class="benefits-container">
        <div class="benefit-container">
          <font-awesome-icon :icon="['far','gavel']"/>
          <h3>{{ $t('web.home.benefit_expert_service_title') }}</h3>
          <p>{{ $t('web.home.benefit_expert_service_desc') }}</p>
        </div>
        <div class="benefit-container">
          <font-awesome-icon :icon="['far','tachometer-alt-fastest']"/>
          <h3>{{ $t('web.home.benefit_efficient_title') }}</h3>
          <p>{{ $t('web.home.benefit_efficient_desc') }}</p>
        </div>
        <div class="benefit-container">
          <font-awesome-icon :icon="['far','shield-check']"/>
          <h3>{{ $t('web.home.benefit_secure_title') }}</h3>
          <p>{{ $t('web.home.benefit_secure_desc') }}</p>
        </div>
        <div class="benefit-container">
          <font-awesome-icon :icon="['far','cog']"/>
          <h3>{{ $t('web.home.benefit_easy_title') }}</h3>
          <p>{{ $t('web.home.benefit_easy_desc') }}</p>
        </div>
        <div class="benefit-container">
          <font-awesome-icon :icon="['far','coin']"/>
          <h3>{{ $t('web.home.benefit_low_cost_title') }}</h3>
          <p>{{ $t('web.home.benefit_low_cost_desc') }}</p>
        </div>
        <div class="benefit-container">
          <font-awesome-icon :icon="['far','user-headset']"/>
          <h3>{{ $t('web.home.benefit_post_support_title') }}</h3>
          <p>{{ $t('web.home.benefit_post_support_desc') }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import Header from '../../components/web/Header.vue';
import Button from '../../components/ui/Button.vue';

export default {
  name: 'Home',
  components: { Button, Header },
  data() {
    return {
      howItWorksSteps: [
        {
          icon: { type: 'far', name: 'pen-alt' },
          title: this.$t('web.home.brief_step_sign_up_title'),
          description: this.$t('web.home.brief_step_sign_up_desc'),
        },
        {
          icon: { type: 'far', name: 'folders' },
          title: this.$t('web.home.brief_step_collection_filing_title'),
          description: this.$t('web.home.brief_step_collection_filing_desc'),
        },
        {
          icon: { type: 'far', name: 'file-certificate' },
          title: this.$t('web.home.brief_step_in_business_title'),
          description: this.$t('web.home.brief_step_in_business_desc'),
        },
        {
          icon: { type: 'far', name: 'search' },
          title: this.$t('web.home.brief_step_ongoing_administration_title'),
          description: this.$t('web.home.brief_step_ongoing_administration_desc'),
        },
      ],
      plans: [
        { name: this.$t('web.home.plan_basic_name'), price: 150 },
        { name: this.$t('web.home.plan_plus_name'), price: 200, best: true },
        { name: this.$t('web.home.plan_pro_name'), price: 300 },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.$t('nav.home'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  section.how-it-works {
    @apply bg-white py-16;

    @screen lg {
      @apply py-20;
    }

    .steps-container {
      @apply flex flex-col relative;

      @screen md {
        @apply mx-auto w-max;
      }

      @screen 2xl {
        @apply flex-row mx-0 w-full;
      }

      .connect-line {
        @apply border border-black h-full absolute;

        margin-left: 6.8rem;
        width: 1px;

        @screen md {
          margin-left: 8.85rem;
        }

        @screen 2xl {
          @apply w-full ml-0;

          margin-top: 8.1rem;
          height: 1px;
        }
      }

      .step-container {
        @apply flex flex-row items-start mb-20;

        &:last-of-type {
          @apply mb-0;
        }

        @screen 2xl {
          @apply flex-1 flex-col items-center mb-0 mr-16;

          &:last-of-type {
            @apply mr-0;
          }
        }

        .icon-container {
          @apply flex-none h-18 w-18 rounded-full border-2 border-black flex flex-row
          justify-center items-center;

          @screen md {
            @apply h-24 w-24;
          }

          svg {
            @apply text-green text-2xl;

            @screen md {
              @apply text-3xl;
            }
          }
        }
      }

      .dot {
        @apply flex-none h-5 w-5 rounded-full bg-black mx-7 mt-7;

        @screen md {
          @apply h-7 w-7 mx-8 mt-9;
        }

        @screen 2xl {
          @apply mx-0 my-5;
        }
      }

      .info-container {
        @apply flex-1;

        @screen md {
          @apply max-w-lg;
        }

        @screen lg {
          @apply max-w-xl;
        }

        @screen 2xl {
          @apply max-w-none;
        }

        h3 {
          @apply text-black text-lg leading-normal font-bold text-left mb-4;

          @screen md {
            @apply text-xl leading-normal;
          }

          @screen 2xl {
            @apply text-center;
          }
        }

        p {
          @apply text-black text-xsm leading-normal font-normal text-left;

          @screen 2xl {
            @apply text-center;
          }
        }
      }
    }

    & > .button {
      @apply py-3 px-8 mx-auto mt-20;
    }
  }

  section.plans {
    @apply flex flex-col items-center bg-grey-lightest py-16;

    @screen lg {
      @apply py-20;
    }

    .plans-container {
      @apply flex flex-col mb-16;

      @screen lg {
        @apply flex-row -mx-3;
      }

      .plan-container {
        @apply mb-11 p-8 rounded border-2 border-pink;

        @screen lg {
          @apply w-1/3 mb-0 mx-3;
        }

        @screen 2xl {
          @apply p-11;
        }

        &.best {
          @apply border-green;
        }

        &:last-of-type {
          @apply mb-0;
        }

        h3 {
          @apply text-3xl leading-normal text-black font-bold mb-2;
        }

        p.price {
          @apply text-4xl leading-normal text-black font-bold;
        }

        p.excluding {
          @apply text-xs leading-normal text-black font-normal italic;
        }

        .actions-container {
          @apply flex flex-row mt-8;

          & > a {
            @apply mr-2;

            & > span {
              @apply text-black;
            }
          }

          & > .button {
            @apply ml-2;
          }
        }
      }
    }

    & > .button {
      @apply py-3 px-8;
    }
  }

  section.benefits {
    @apply flex flex-col bg-white py-16;

    @screen lg {
      @apply py-20;
    }

    .benefits-container {
      @apply flex flex-row flex-wrap;

      .benefit-container {
        @apply flex flex-col items-center mb-14 w-full;

        @screen md {
          @apply w-1/2 mb-16 px-12;
        }

        @screen lg {
          @apply w-1/3 mb-20 px-10;
        }

        @screen 2xl {
          @apply px-16;
        }

        &:last-of-type {
          @apply mb-0;
        }

        svg {
          @apply text-4xl text-green mb-6;

          @screen 2xl {
            @apply mb-7;
          }
        }

        h3 {
          @apply text-black text-2xl leading-normal font-bold text-center mb-3;

          @screen 2xl {
            @apply mb-4;
          }
        }

        p {
          @apply text-black text-xsm leading-normal font-normal text-center;

          @screen 2xl {
            @apply text-sml leading-normal;
          }
        }
      }
    }
  }
}
</style>
