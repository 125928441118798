<template>
  <div class="layout-container account">
    <Navbar inner/>
    <main>
      <slot/>
    </main>
    <div class="bottom-area">
      <Footer/>
    </div>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue';
import Footer from '../Footer.vue';

export default {
  name: 'Layout',
  components: { Navbar, Footer },
};
</script>

<style lang="scss">
.layout-container.account {
  .page-pad {
    @apply px-4;

    @screen md {
      @apply px-5;
    }

    @screen lg {
      @apply px-12;
    }

    @screen 2xl {
      @apply px-64;
    }
  }

  & > main {
    @apply bg-grey-lightest;
  }

  .page-container {
    @apply flex flex-col;
  }
}
</style>
