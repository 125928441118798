<template>
  <div class="inner-page-container">
    <h2 class="page-title">{{ $t('account.nav.account_settings') }}</h2>
    <div class="content-container">
      <section class="close-account">
        <header>
          <h2 class="title">{{ $t('account.account_settings.close_account') }}</h2>
        </header>
        <div class="close-container">
          <p>{{ $t('account.account_settings.delete_your_account_and_account_data') }}</p>
          <Button class="button" secondary :onclick="toggleCloseAccountModal">
            <span>{{ $t('account.account_settings.close_account') }}</span>
          </Button>
        </div>
      </section>
      <section class="password">
        <header>
          <h2 class="title">{{ $t('account.account_settings.update_password') }}</h2>
        </header>
        <Form class="form-container" @submit="submitPassword">
          <div class="inputs-container">
            <FormInputText v-model="credentials.password" :has-error="$v.credentials.password.$error" :label="`${$t('account.account_settings.password')}`"
                           identifier="password"
                           type="password">
              <template v-slot:errors>
                <p v-if="!$v.credentials.password.required">
                  {{ $t('auth.validation.password_required') }}
                </p>
              </template>
            </FormInputText>
            <FormInputText v-model="credentials.password_confirmation" :has-error="$v.credentials.password_confirmation.$error" :label="`${$t('account.account_settings.password_confirmation')}`"
                           identifier="password-confirmation"
                           type="password">
              <template v-slot:errors>
                <p v-if="!$v.credentials.password_confirmation.required">
                  {{ $t('auth.validation.password_confirmation_required') }}
                </p>
                <p v-else-if="!$v.credentials.password_confirmation.confirmation">
                  {{ $t('auth.validation.passwords_do_not_match') }}
                </p>
              </template>
            </FormInputText>
          </div>
          <Button class="button" secondary type="submit" :loading="is_updating">
            <span>{{ $t('update') }}</span>
          </Button>
        </Form>
      </section>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  name: 'AccountSettings',
  components: { FormInputText, Form, Button },
  data() {
    return {
      credentials: {
        password: null,
        password_confirmation: null
      },
      is_updating: false
    };
  },
  validations: {
    credentials: {
      password: { required },
      password_confirmation: {
        required: requiredIf((u) => u.password),
        confirmation: sameAs('password'),
      }
    }
  },
  methods: {
    toggleCloseAccountModal() {
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('account.close_account'),
          message: this.$t('account.prompt_close_account'),
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'closable-modal loose',
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              this.$axios.delete(`users/${this.$store.getters.user.id}`).then(({ data }) => {
                this.$router.push('/login');
              }).catch((response) => {
              });
            }
          },
        },
      );
    },
    submitPassword() {
      this.$v.credentials.$touch();
      if (this.$v.credentials.$anyError) return;

      this.is_updating = true;

      this.$axios.patch('profile', { password: this.credentials.password }).then(({ data }) => {
        this.is_updating = false;
      }).catch((e) => {
        this.is_updating = false;
      });
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.account_settings'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  .content-container {
    @apply flex flex-col mt-14 w-full gap-y-16;

    section {
      @apply flex flex-col;

      & > header {
        @apply flex flex-row items-center justify-between pb-5 border-b border-grey-light mb-7;

        h2.title {
          @apply text-black font-bold text-lg leading-normal;
        }
      }

      & > .close-container {
        @apply flex flex-col items-start;

        p {
          @apply text-black text-sml leading-normal font-normal mb-8;
        }

        .button {
          @apply px-8;
        }
      }

      &.password {
        & > .form-container {

          .inputs-container {
            @apply grid grid-cols-1;

            @screen md {
              @apply grid-cols-2 gap-x-6 max-w-4xl;
            }
          }

          .button {
            @apply w-auto py-4 px-10;
          }
        }
      }
    }
  }
}
</style>
