<template>
  <div :class="{opened: !forceClose && $store.getters.cookies === null}"
       class="cookiebar-container">
    <p class="message">
      {{ $t('cookie.message') }}
      <wbr>
      <router-link :to="{name: 'cookies-policy'}">
        {{ $t('cookie.see_cookies_policy') }}
      </router-link>
    </p>
    <div class="actions-container">
      <Button :onclick="() => setCookies(false)" class="button decline" outline secondary>
        <span>{{ $t('cookie.decline') }}</span>
      </Button>
      <Button :onclick="() => setCookies(true)" class="button accept" secondary>
        <span>{{ $t('cookie.accept') }}</span>
      </Button>
      <button class="btn-close" type="button" @click="setCookies(null)">
        <font-awesome-icon :icon="['far','times']"/>
      </button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';

export default {
  name: 'Cookiebar',
  components: { Button },
  data() {
    return {
      forceClose: false,
    };
  },
  methods: {
    setCookies(decision) {
      console.log('setCookies', decision);
      this.forceClose = decision === null;
      this.$store.commit('cookies', decision);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookiebar-container {
  @apply fixed bottom-0 w-full flex flex-col items-center bg-black py-5 px-4 z-40;

  transition: transform 0.3s;
  transform: translateY(100%);

  &.opened {
    transform: translateY(0);
  }

  @screen md {
    @apply flex-row justify-between py-5 px-5;
  }

  @screen 2xl {
    @apply py-6 px-6;
  }

  p.message {
    @apply text-white text-xs leading-normal font-normal text-center;

    @screen md {
      @apply text-left;
    }

    a {
      @apply text-xs leading-loose text-white underline;

      &:hover, &:focus {
        @apply text-pink;
      }
    }
  }

  .actions-container {
    @apply flex flex-row items-center mt-6;

    @screen md {
      @apply mt-0 ml-12;
    }

    .button {
      @apply mr-4;

      & > span {
        @apply text-xs;
      }

      &:last-of-type {
        @apply mr-0;
      }

      &.decline {
        & > span {
          @apply text-white;
        }

        &:hover, &:focus {
          & > span {
            @apply text-black;
          }
        }
      }
    }

    button.btn-close {
      @apply hidden text-white;

      @screen md {
        @apply block ml-2 mr-1 text-lg;
      }

      &:active, &:focus {
        @apply outline-none;
      }
    }
  }
}
</style>
