<template>
  <div class="page-container" v-if="!is_checking">
    <header class="page-pad">
      <h1 class="page-title">{{ $t('application.my_application') }}</h1>
      <div class="horizontal-step-indicator-container">
        <template v-for="step in steps">
          <div :key="`step-${step}`" :class="{
                current: active_step === step,
                done: active_step > step,
                coming: active_step < step
          }"
               class="step">
            {{ step }}
          </div>
          <div v-if="step < numSteps" :key="`divider-${step}`" class="divider"></div>
        </template>
      </div>
      <div class="radial-step-indicator-container">
        <div class="percent">
          <svg>
            <circle cx="35" cy="35" r="35"></circle>
            <circle :style="{
              strokeDashoffset: `calc(440 - (440 * ${(active_step/numSteps)*50}) / 100)`
            }" cx="35" cy="35"
                    r="35">
            </circle>
          </svg>
          <div class="number">
            <span>{{
                $t('application.step_of_steps', {
                  step: active_step, steps: numSteps
                })
              }}</span>
          </div>
        </div>
      </div>
    </header>
    <div class="steps-container page-pad">
      <ApplicationStep1 v-show="active_step === 1" ref="step-1" :currentData="data" :application_id="application_id"/>
      <ApplicationStep2 v-show="active_step === 2" ref="step-2" :currentData="data" :application_id="application_id"/>
      <ApplicationStep3 v-show="active_step === 3" ref="step-3" :currentData="data" :application_id="application_id"/>
      <ApplicationStep4 v-show="active_step === 4" ref="step-4" :currentData="data" :application_id="application_id"/>
      <ApplicationStep5 v-show="active_step === 5" ref="step-5" :wizardData="data" :currentData="data" :application_id="application_id"/>
      <ApplicationStep6 v-show="active_step === 6" ref="step-6" :wizardData="data" :currentData="data" :application_id="application_id"/>
      <ApplicationStep7 v-show="active_step === 7" ref="step-7" :currentData="data" :application_id="application_id"/>
      <ApplicationStep8 v-show="active_step === 8" ref="step-8" :currentData="data" :application_id="application_id"/>
      <footer class="page-pad">
        <Button v-if="active_step > 1" :onclick="goBack" class="button" outline secondary
                type="button">
          <span>{{ $t('previous') }}</span>
        </Button>
        <Button v-if="active_step < numSteps" :onclick="goNext" class="button" secondary
                type="button">
          <span>{{ $t('next') }}</span>
        </Button>
        <Button v-if="active_step === numSteps && !$route.path.includes('view')" :onclick="submit" class="button" secondary
                type="button">
          <span>{{ $t('finish') }}</span>
        </Button>
      </footer>
    </div>
  </div>
</template>

<script>

import Button from '@/components/ui/Button.vue';
import ApplicationStep1 from '@/components/application/ApplicationStep1.vue';
import ApplicationStep2 from '@/components/application/ApplicationStep2.vue';
import ApplicationStep3 from '@/components/application/ApplicationStep3.vue';
import ApplicationStep4 from '@/components/application/ApplicationStep4.vue';
import ApplicationStep5 from '@/components/application/ApplicationStep5.vue';
import ApplicationStep6 from '@/components/application/ApplicationStep6.vue';
import ApplicationStep7 from '@/components/application/ApplicationStep7.vue';
import ApplicationStep8 from '@/components/application/ApplicationStep8.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import ApplicationSubmittedModal from '@/components/application/ApplicationSubmittedModal.vue';

export default {
  name: 'Application',
  components: {
    ApplicationStep8,
    ApplicationStep7,
    ApplicationStep6,
    ApplicationStep5,
    ApplicationStep4,
    ApplicationStep3,
    ApplicationStep2,
    ApplicationStep1,
    Button,
  },
  data() {
    return {
      active_step: 1,
      steps: [1, 2, 3, 4, 5, 6, 7, 8],
      data: {},
      application_id: null,
      isSubmitted: false,
      is_checking: true,
    };
  },
  methods: {
    goBack() {
      const step = this.$refs[`step-${this.active_step}`];

      if (step.isMultipart && step.isMultipart() && step.canGoBack()) step.goBack();
      else if (this.active_step > 1) this.active_step -= 1;
    },
    goNext() {
      const step = this.$refs[`step-${this.active_step}`];

      if (step.isValid()) {
        const stepData = this.$refs[`step-${this.active_step}`].getData();
        this.data = Object.assign(this.data, stepData);

        if (step.isMultipart && step.isMultipart() && step.canGoNext()) step.goNext();
        else if (this.active_step < this.numSteps) this.active_step += 1;

        const payload = {
          status: 'pending',
          value: this.data,
        };

        this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
          this.is_loading = false;
        }).catch((e) => {});
      }
    },
    submit() {
      if (this.isSubmitted || !this.$refs[`step-${this.active_step}`].isValid()) return;

      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('application.application_submission'),
          message: this.$t('application.prompt_submit_application'),
          subtext: this.$t('application.prompt_submit_application_subtext'),
          confirmText: this.$t('yes'),
          cancelText: this.$t('no'),
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'closable-modal loose',
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              this.$axios.get(`applications/${this.application_id}/submit`).then(({ data }) => {
                this.isSubmitted = true;
                this.is_loading = false;
                this.$modal.show(
                  ApplicationSubmittedModal,
                  {},
                  {
                    name: 'application-submitted-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'closable-modal loose',
                  },
                );
              }).catch((err) => {
                this.is_loading = false;
              });
            }
          },
        },
      );
    },
    async createApplication() {
      const payload = { status: 'pending', value: { temp: 'temp' } };
      await this.$axios.post('applications', payload).then(({ data }) => {
        this.application_id = parseInt(data.data.id, 10);
        // this.$store.commit('applicationId', data.data.id);
        this.is_loading = false;
      }).catch((e) => {
        this.is_loading = false;
      });
    },
    async checkForApplication() {
      this.is_checking = true;

      await this.$axios.get(`users/${this.$store.getters.user.id}/applications/pending`).then(async ({ data }) => {
        if (data.data) {
          this.application_id = parseInt(data.data.id, 10);
          // this.$store.commit('applicationId', data.data.id);
          this.data = JSON.parse(data.data.attributes.value);
        } else {
          this.createApplication();
        }

        this.is_checking = false;
      });
    },
    async getApplication() {
      this.is_checking = true;

      await this.$axios.get(`applications/${this.application_id}`).then(async ({ data }) => {
        if (data.data) {
          // this.$store.commit('applicationId', data.data.id);
          this.data = JSON.parse(data.data.attributes.value);
          this.is_checking = false;
        }

        this.is_checking = false;
      });
    }
  },
  computed: {
    numSteps() {
      return this.steps.length;
    },
  },

  watch: {
    active_step(step) {
      const component = this.$refs[`step-${step}`];

      if (component.becameActiveStep) {
        this.$nextTick(() => {
          component.becameActiveStep();
        });
      }
    },
  },
  async mounted() {
    if (this.$route.params.application_id) {
      this.application_id = parseInt(this.$route.params.application_id, 10);

      await this.getApplication();
    } else {
      await this.checkForApplication();
    }
  },
  metaInfo() {
    return {
      title: this.$t('application.my_application'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply mb-8;

  & > header {
    @apply bg-grey-lightest py-10 flex flex-row justify-between items-center;

    h1.page-title {
      @apply text-black font-bold text-3xl leading-normal;

      @screen md {
        @apply text-4xl;
      }

      @screen 2xl {
        @apply text-5xl;
      }
    }

    .horizontal-step-indicator-container {
      @apply hidden;

      @screen lg {
        @apply flex flex-row items-center;
      }

      .step {
        @apply rounded-full h-10 w-10 flex flex-row items-center justify-center
        border-2 text-base select-none cursor-default;

        &.done {
          @apply bg-green border-green text-white;
        }

        &.current {
          @apply bg-white border-green text-black;
        }

        &.coming {
          @apply bg-grey-lighter border-grey text-black;
        }
      }

      .divider {
        @apply border border-b-2 border-grey-light w-10;

        height: 0px;
      }
    }

    .radial-step-indicator-container {
      @apply relative;

      @screen lg {
        @apply hidden;
      }

      .percent, svg, circle {
        width: 80px;
        height: 80px;
      }

      .percent {
        @apply relative;
      }

      circle {
        @apply absolute;
        fill: none;
        stroke-width: 4;
        transform: translate(5px, 5px);
        stroke-dasharray: 440;
        stroke-linecap: round;
      }

      circle:nth-child(1) {
        @apply stroke-current text-grey;
        stroke-dashoffset: 0;
      }

      .percent circle:nth-child(2) {
        @apply stroke-current text-green;
        stroke-dashoffset: calc(440 - (440 * 0) / 100);
      }

      .number {
        @apply text-black absolute;
        bottom: 0;
        right: 0;
        top: 27px;
        left: 20px;

        span {
          @apply text-base text-black font-medium;
        }
      }
    }
  }

  & > .steps-container {
    @apply py-12;

    @screen md {
      @apply py-16;
    }

    @screen xl {
      @apply relative;
    }

    & > footer {
      @apply flex flex-row items-center gap-x-6 my-12 mx-auto;

      @screen xl {
        @apply absolute;
        right: 0px;
        top: 600px;
      }

      & > .button {
        @apply py-3 px-8;

        &:first-of-type {
          @apply ml-auto;
        }

        &:last-of-type {
          @apply mr-auto;
        }

        span {
          @apply text-sml;
        }
      }
    }
  }
}
</style>
