<template>
  <div class="step-container">
    <div class="step-inputs-container">
      <p>{{ $t('application.step_3.title') }}</p>

      <Form class="form-container" @submit="submit">
        <FormInputSelect
          v-model="$v.natureBusiness.$model"
          :allow-empty="true"
          :has-error="$v.natureBusiness.$error"
          :label="$t('application.step_3.nature_of_business_activity')"
          :multiple="false"
          :options="natureBusinessOptions"
          :placeholder="$t('application.step_3.choose_nature_of_business_activity')"
          display-label="name" @input="saveData"
          track-by="value" :disabled="$route.path.includes('view')"
        >
          <template v-slot:errors>
            <p v-if="!$v.natureBusiness.required">
              {{ $t('application.step_3.validation.nature_of_business_activity_required') }}
            </p>
          </template>
        </FormInputSelect>
        <FormInputText v-show="natureBusiness && natureBusiness.value === 'other'"
                       v-model="$v.natureBusinessOther.$model" :has-error="$v.natureBusinessOther.$error"
                       :label="$t('application.step_3.nature_of_business_activity')" @blur="saveData"
                       :placeholder="$t('application.step_3.nature_of_business_activity')" :disabled="$route.path.includes('view')">
          <template v-slot:errors>
            <p v-if="!$v.natureBusinessOther.required">
              {{ $t('application.step_3.validation.nature_of_business_activity_required') }}
            </p>
          </template>
        </FormInputText>
        <FormInputSelect
          v-model="$v.countriesOperation.$model"
          :allow-empty="true"
          :has-error="$v.countriesOperation.$error"
          :label="$t('application.step_3.countries_of_operation')"
          :multiple="true"
          :options="countriesOperationOptions"
          :placeholder="$t('application.step_3.choose_countries_of_operation')"
          display-label="name"
          track-by="value"
          :taggable="true"
          @tag="addCountry"
          @input="saveData" :disabled="$route.path.includes('view')"
        >
          <template v-slot:errors>
            <p v-if="!$v.countriesOperation.required">
              {{ $t('application.step_3.validation.countries_of_operation_required') }}
            </p>
          </template>
        </FormInputSelect>
        <FormInputSelect
          v-model="$v.financingInformation.$model"
          :allow-empty="true"
          :has-error="$v.financingInformation.$error"
          :label="$t('application.step_3.financing_information')"
          :multiple="true"
          :options="financingInformationOptions"
          :placeholder="$t('application.step_3.choose_financing_information')"
          display-label="name"
          track-by="value"
          @input="saveData" :disabled="$route.path.includes('view')"
        >
          <template v-slot:errors>
            <p v-if="!$v.financingInformation.required">
              {{ $t('application.step_3.validation.financing_information_required') }}
            </p>
          </template>
        </FormInputSelect>
      </Form>
    </div>
  </div>
</template>

<script>

import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import { required, requiredIf } from 'vuelidate/lib/validators';
import Form from '@/components/ui/Form.vue';
import FormInputText from '@/components/ui/FormInputText.vue';

export default {
  name: 'ApplicationStep3',
  components: { FormInputText, Form, FormInputSelect },
  props: {
    currentData: {
      type: Object,
      required: false,
      default: null,
    },
    application_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      natureBusiness: null,
      natureBusinessOther: null,
      natureBusinessOptions: [
        {
          name: this.$t('application.step_3.fitness_services'),
          value: 'fitness_services',
        },
        {
          name: this.$t('application.step_3.marketing_services'),
          value: 'marketing_services',
        },
        {
          name: this.$t('application.step_3.beauty_services'),
          value: 'beauty_services',
        },
        {
          name: this.$t('application.step_3.graphic_design'),
          value: 'graphic_design',
        },
        {
          name: this.$t('application.step_3.social_media_management'),
          value: 'social_media_management',
        },
        {
          name: this.$t('application.step_3.other'),
          value: 'other',
        },
      ],
      countriesOperation: null,
      countriesOperationOptions: [
        { name: 'Malta', value: 'malta' },
      ],
      financingInformation: null,
      financingInformationOptions: [
        { name: this.$t('application.step_3.savings'), value: 'savings' },
        { name: this.$t('application.step_3.income_sale_asset'), value: 'income_sale_asset' },
        { name: this.$t('application.step_3.income_inheritance'), value: 'income_inheritance' },
        { name: this.$t('application.step_3.loan'), value: 'loan' },
        { name: this.$t('application.step_3.third_party_financing'), value: 'third_party_financing' },
        { name: this.$t('application.step_3.other'), value: 'other' },
      ],
    };
  },
  validations: {
    natureBusiness: {
      required,
    },
    natureBusinessOther: {
      required: requiredIf((d) => d.natureBusiness && d.natureBusiness.value === 'other'),
    },
    countriesOperation: {
      required,
    },
    financingInformation: {
      required,
    },
  },
  methods: {
    addCountry(newCountry) {
      const country = {
        name: newCountry,
        value: newCountry.toLowerCase(),
      };

      this.countriesOperationOptions.push(country);
      this.countriesOperation.push(country);
    },
    submit() {
      // TODO
    },

    async populate() {
      if (this.currentData) {
        this.natureBusiness = this.currentData.natureBusiness;
        this.natureBusinessOther = this.currentData.natureBusinessOther;
        this.countriesOperation = this.currentData.countriesOperation;
        this.financingInformation = this.currentData.financingInformation;
      }
    },

    saveData() {
      const tempData = {
        natureBusiness: this.natureBusiness ? this.natureBusiness : null,
        natureBusinessOther: this.natureBusiness && this.natureBusiness.value === 'other' ? this.natureBusinessOther : null,
        countriesOperation: this.countriesOperation ? this.countriesOperation : null,
        financingInformation: this.financingInformation ? this.financingInformation : null
      };

      const currentData = tempData;

      const newData = Object.assign(this.currentData, currentData);

      const payload = {
        status: 'pending',
        value: newData,
      };

      this.$axios.patch(`applications/${this.application_id}`, payload).then(({ data }) => {
        this.is_loading = false;
      }).catch((e) => {});
    },

    getData() {
      return {
        natureBusiness: this.natureBusiness,
        natureBusinessOther: this.natureBusiness && this.natureBusiness.value === 'other' ? this.natureBusinessOther : null,
        countriesOperation: this.countriesOperation,
        financingInformation: this.financingInformation,
      };
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
  },
  async mounted() {
    await this.populate();
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  @apply flex flex-col;

  @screen lg {
    @apply flex-row flex-wrap items-start justify-between gap-x-12;
  }

  .step-inputs-container {
    @apply w-full max-w-xl;

    & > p {
      @apply text-black font-bold text-base leading-normal mb-9;

      @screen md {
        @apply text-lg leading-normal mb-10;
      }
    }

    & > .form-container {
      @apply flex flex-col;
    }
  }
}
</style>
