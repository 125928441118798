<template>
  <div class="layout-container auth">
    <AuthNavbar/>
    <main>
      <slot/>
    </main>
    <AuthFooter/>
  </div>
</template>

<script>
import AuthNavbar from './Navbar.vue';
import AuthFooter from './Footer.vue';

export default {
  name: 'Layout',
  components: { AuthNavbar, AuthFooter },
};
</script>

<style lang="scss" scoped>
.layout-container {
  @apply bg-green;

  main {
    @apply flex flex-col justify-center pb-24;
  }
}
</style>

<style lang="scss">
.layout-container.auth {
  .page-pad {
    @apply px-4;

    @screen md {
      @apply px-5;
    }

    @screen lg {
      @apply px-12;
    }

    @screen 2xl {
      @apply px-64;
    }
  }

  .page-container {
    @apply flex flex-col;
  }
}
</style>
