<template>
  <div class="page-container">
    <section class="cookies-policy page-pad">
      <header>
        <h1 class="page-title">{{ $t('nav.cookies_policy') }}</h1>
        <p class="effective">Effective on January 5th 2021</p>
      </header>
      <article>
        <h2>What are cookies?</h2>
        <p>Cookies are simple text files that are stored on your computer or mobile device by a
          website’s server. Each cookie is unique to your web browser. It will contain some
          anonymous information such as a unique identifier, website’s domain name, and some
          digits and numbers.</p>
        <h2>What types of cookies do we use?</h2>
        <h3>Necessary cookies</h3>
        <p>Necessary cookies allow us to offer you the best possible experience when accessing
          and navigating through our website and using its features. For example, these cookies
          let us recognize that you have created an account and have logged into that account.</p>
        <h3>Functionality cookies</h3>
        <p>Functionality cookies let us operate the site in accordance with the choices you make.
          For example, we will recognize your username and remember how you customized the site
          during future visits.</p>
        <h3>Analytical cookies</h3>
        <p>These cookies enable us and third-party services to collect aggregated data for
          statistical purposes on how our visitors use the website. These cookies do not contain
          personal information such as names and email addresses and are used to help us improve
          your user experience of the website.</p>
        <h2>How to delete cookies?</h2>
        <p>If you want to restrict or block the cookies that are set by our website, you can do
          so through your browser setting. Alternatively, you can visit www.internetcookies.org,
          which contains comprehensive information on how to do this on a wide variety of browsers
          and devices. You will find general information about cookies and details on how to
          delete cookies from your device.</p>
        <h2>Contacting Us</h2>
        <p>If you have any questions about this policy or our use of cookies, please contact us.</p>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CookiesPolicy',
  metaInfo() {
    return {
      title: this.$t('nav.cookies_policy'),
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @apply flex flex-col items-start bg-white py-12;

  @screen 2xl {
    @apply py-16;
  }

  section.cookies-policy {
    header {
      @apply mb-12;

      @screen md {
        @apply mb-16;
      }

      h1.page-title {
        @apply text-black text-3xl font-bold;

        @screen lg {
          @apply text-4xl;
        }

        @screen 2xl {
          @apply text-5xl;
        }
      }

      p.effective {
        @apply mt-5 text-grey-darker text-sml font-normal;
      }
    }

    article {
      h2 {
        @apply text-black text-xl font-bold mb-4;
      }

      h3 {
        @apply text-black text-base font-bold mb-4;
      }

      p {
        @apply text-grey-darker text-sml leading-loose font-normal mb-4;
      }

      p + h2, p + h3 {
        @apply mt-10;
      }
    }
  }
}
</style>
