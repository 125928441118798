<template>
  <div class="inner-page-container">
    <header>
      <h2 class="page-title">{{ $t('account.nav.my_documents') }}</h2>
      <Button v-if="documents.length > 0" :onclick="toggleUploadDocumentModal" class="solo-upload-button"
              secondary>
        <font-awesome-icon :icon="['far', 'arrow-alt-to-top']"/>
      </Button>
      <div v-if="documents.length > 0" class="actions-container">
        <label for="documents_application">
          {{ $t('account.documents.application') }}
        </label>
        <FormInputSelect :allow-empty="false"
                         :options="applicationOptions"
                         :placeholder="$t('select')"
                         display-label="name"
                         track-by="id"/>
        <Button :onclick="toggleUploadDocumentModal" class="button" secondary>
          <font-awesome-icon :icon="['far', 'arrow-alt-to-top']"/>
          <span>{{ $t('upload') }}</span>
        </Button>
      </div>
    </header>
    <div v-if="documents.length === 0" class="no-documents-container">
      <p class="message">{{ $t('account.documents.no_documents_uploaded') }}</p>
      <!-- TODO action -->
      <Button :onclick="toggleUploadDocumentModal" secondary>
        <font-awesome-icon :icon="['far', 'arrow-alt-to-top']"/>
        <span>{{ $t('upload') }}</span>
      </Button>
    </div>
    <div v-else class="content-container">
      <header>
        <div class="col reference">
          <p class="title">{{ $t('account.documents.date_uploaded') }}</p>
        </div>
        <div class="col date">
          <p class="title">{{ $t('account.documents.name') }}</p>
        </div>
        <div class="col status">
          <p class="title">{{ $t('account.documents.application') }}</p>
        </div>
        <div class="col action">
        </div>
      </header>

      <div v-for="document in documents" :key="document.name" class="row">
        <div class="col date">
          <p class="title">{{ $t('account.documents.date_uploaded') }}</p>
          <p class="value">{{ document.date }}</p>
        </div>
        <div class="col name">
          <p class="title">{{ $t('account.documents.name') }}</p>
          <p class="value">{{ document.name }}</p>
        </div>
        <div class="col application">
          <p class="title">{{ $t('account.documents.application') }}</p>
          <p class="value">{{ document.application }}</p>
        </div>
        <div class="col action">
          <!-- TODO action -->
          <Button outline secondary><span>{{ $t('delete') }}</span></Button>
          <!-- TODO action -->
          <Button secondary><span>{{ $t('account.documents.view') }}</span></Button>
          <!-- TODO action -->
          <Button class="download">
            <font-awesome-icon :icon="['far', 'arrow-alt-to-bottom']"/>
          </Button>
        </div>
      </div>

      <div class="pagination-container">
        <div class="status">
          <p>{{
              $t('pagination_showing_entries', {
                from: (per_page * (page - 1)) + 1,
                to: (per_page * page),
                total: total_records
              })
            }}</p>
        </div>
        <div class="pagination">
          <Pagination :current="page" :ipp="per_page" :total="total_records"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';
import UploadDocumentModal from '@/components/account/UploadDocumentModal.vue';
import FormInputSelect from '@/components/ui/FormInputSelect.vue';
import Pagination from '@/components/ui/Pagination.vue';

export default {
  name: 'MyDocuments',
  components: { Pagination, FormInputSelect, Button },
  data() {
    return {
      documents: [
        {
          date: '2020-02-01',
          name: 'Document A',
          application: 'Application A',
        },
        {
          date: '2021-01-06',
          name: 'Document B',
          application: 'Application B',
        },
        {
          date: '2021-04-24',
          name: 'Document C',
          application: 'Application C',
        },
      ],
      applicationOptions: [ // TODO dev data
        {
          name: 'Application A',
          id: 1,
        },
        {
          name: 'Application B',
          id: 2,
        },
        {
          name: 'Application C',
          id: 3,
        },
      ],
      total_records: 150,
      per_page: 5,
      page: 1,
    };
  },
  methods: {
    toggleUploadDocumentModal() {
      this.$modal.show(
        UploadDocumentModal,
        {},
        {
          name: 'upload-document-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          width: '90%',
          maxWidth: 440,
          scrollable: true,
        },
        {
          'before-close': (e) => {
            if (e.params === true) {
              // TODO refresh documents
            }
          },
        },
      );
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.nav.my_documents'),
    };
  },
};
</script>

<style lang="scss" scoped>
.inner-page-container {
  & > header {
    @apply flex flex-row flex-wrap items-center w-full;

    h2.page-title {
      @apply flex-none;
    }

    .solo-upload-button {
      @apply flex-none ml-auto rounded-full p-2;

      svg {
        @apply text-xl ;
      }

      @screen md {
        @apply hidden;
      }
    }

    .actions-container {
      @apply flex flex-row items-center w-full mt-8;

      @screen md {
        @apply ml-auto w-auto flex-none mt-0;
      }

      & > label {
        @apply text-black text-sml mr-4;

        @screen lg {
          @apply mr-5;
        }
      }

      .form-input-select-container {
        @apply mb-0;

        @screen md {
          @apply w-48;
        }
      }

      & > .button {
        @apply px-8 hidden;

        @screen md {
          @apply flex ml-4;
        }

        @screen lg {
          @apply ml-5;
        }
      }
    }
  }

  .no-documents-container {
    @apply flex flex-col items-start mt-9;

    p.message {
      @apply text-lg leading-normal font-bold text-black mb-8;
    }
  }

  .content-container {
    @apply pt-7 w-full;

    & > header {
      @apply hidden mb-7 px-6 mt-6;

      @screen md {
        @apply grid grid-cols-4 gap-x-2 gap-y-4;
      }

      @screen lg {
        @apply px-8 gap-x-8;
      }

      & > .col {
        p.title {
          @apply text-base leading-normal text-green;

          @screen lg {
            @apply text-lg leading-normal;
          }
        }
      }
    }

    & > .row {
      @apply grid grid-cols-3 gap-x-4 gap-y-4 items-center shadow-lg mb-7 px-5 py-5 rounded-lg;

      grid-template-areas: "date name application" "action action action";

      @screen md {
        @apply py-8 px-6 gap-x-2;

        grid-template-areas: "date name application action";
      }

      @screen lg {
        @apply py-10 px-8 gap-x-8;
      }

      & > .col {
        p.title {
          @apply text-xs leading-normal text-green mb-1;

          @screen md {
            @apply hidden;
          }
        }

        p.value {
          @apply text-xs leading-normal text-black;

          @screen md {
            @apply text-base leading-normal;
          }

          @screen lg {
            @apply text-lg leading-normal;
          }
        }

        &.date {
          grid-area: date;
        }

        &.name {
          grid-area: name;
        }

        &.application {
          grid-area: application;
        }

        &.action {
          @apply flex flex-row gap-x-6 items-center w-full;

          grid-area: action;

          @screen md {
            @apply gap-x-2;
          }

          @screen lg {
            @apply gap-x-6;
          }

          & > .download {
            & > svg {
              @apply text-xl;
            }
          }
        }
      }
    }

    & > .pagination-container {
      @apply flex flex-row items-center justify-between mt-10;

      .status {
        p {
          @apply text-xsm text-black;
        }
      }

      .pagination {

      }
    }
  }
}
</style>
